import { DataStore, API, SortDirection, graphqlOperation } from 'aws-amplify'
import { 
      HseInspectionTemplate, 
      HseInspectionTemplateSection, 
      HseInspectionTemplateQuestion,
      HseUserInspection,
      HseUserInspectionData,
      HseUserInspectionFiles,
      HseProject
  } from './../models'

import { listHseInspectionTemplateQuestions, listHseInspectionTemplates, listHseInspectionTemplateSections, listHseUserInspectionData, listHseUserInspectionFiles, listHseUserInspections } from '../graphql/queries';

import { listFilter, getLocaleDateTimeString,GetDisplayName } from './HseCommonService';
import {GetHseProjectUserList} from './HseProjectService'

import { getSignedKey } from './S3StorageService'

import { FetchDataWithNextToken } from './HseCommonService'

import * as Constants from './../constants'
import { GetHsePlantWithAdditionalDetailsById } from './HsePlantService';
import { GetHseEquipmentWithDetailsById } from './HseEquipmentService';
import { GetOrganisationById } from './HseOrganisationService';
import { createHseUserInspection, createHseUserInspectionData, createHseUserInspectionFiles } from '../graphql/mutations';

export async function GetHseInspectionTemplates (isDataStoreOnline = false) {
  const filter = {
    isDeleted : {eq: false}
  };
    try {
      let result =(isDataStoreOnline) ?  await DataStore.query(HseInspectionTemplate, c =>
        c.isDeleted('eq', false)
      ): (await FetchDataWithNextToken(listHseInspectionTemplates, filter, 'listHseInspectionTemplates'));

    //  console.log(result)
      return result
    } catch (err) {
      console.log('error Listing GetHseInspectionTemplates:', err)
    }
    return []
}

export async function GetHseInspectionTemplateById (templateId, isDataStoreOnline = false) {
  const filter = {
    id : {eq: templateId},
    isDeleted : {eq: false}
  };
    try {
      let result =(isDataStoreOnline) ?  await DataStore.query(HseInspectionTemplate, c =>
        c.id('eq', templateId) &&  c.isDeleted('eq', false)
      ): (await FetchDataWithNextToken(listHseInspectionTemplates, filter, 'listHseInspectionTemplates'));

    // console.log(result)
      return result
    } catch (err) {
      console.log('error Listing GetHseInspectionTemplateById:', err)
    }
    return []
}

export async function GetHseInspectionSectionsByTemplateId (templateId , isDataStoreOnline = false) {
  const filter = {
    templateId : {eq: templateId},
    isDeleted : {eq: false}
  };
    try {
      let result =(isDataStoreOnline) ?  await DataStore.query(HseInspectionTemplateSection, c =>
        c.templateId('eq', templateId) &&  c.isDeleted('eq', false)
      ): (await FetchDataWithNextToken(listHseInspectionTemplateSections, filter, 'listHseInspectionTemplateSections'));

    // console.log(result)
      return result
    } catch (err) {
      console.log('error Listing GetHseInspectionSectionsByTemplateId:', err)
    }
    return []
}

export async function GetHseInspectionQuestionsByTemplateId (templateId, isDataStoreOnline= false) {
  const filter = {
    sectionId: {
      ne: '',
    },
    templateId : {eq: templateId},
    isDeleted : {eq: false}
  };
    try {    
      let result =(isDataStoreOnline) ?  await DataStore.query(HseInspectionTemplateQuestion, c =>
        c.templateId('eq', templateId) && c.sectionId('ne', '') &&  c.isDeleted('eq', false)
      ): (await FetchDataWithNextToken(listHseInspectionTemplateQuestions, filter, 'listHseInspectionTemplateQuestions'));

    // console.log(result)
      return result
    } catch (err) {
      console.log('error Listing GetHseInspectionQuestionsByTemplateId:', err)
    }
    return []
}

export async function GetHseInspectionTemplateFormattedObject(templateId,isDataStoreOnline = false) {
    let templateDetails = {id:'', name:'', description:'', occurrence: '', sectionList: [], selectedSectionId: '', controlList: []}

    try {
        let tempDetails = await GetHseInspectionTemplateById(templateId , isDataStoreOnline)

        if(tempDetails != null && tempDetails != undefined && tempDetails.length > 0 ) {
            templateDetails = {...templateDetails, ...tempDetails[0]}
            
            //fetch sections list
            let sectionsList = await GetHseInspectionSectionsByTemplateId(templateId, isDataStoreOnline) 
            if(sectionsList != null && sectionsList != undefined && sectionsList.length > 0 ) {
                sectionsList.sort((a, b) => a.sequenceId - b.sequenceId);
                templateDetails.sectionList = [...sectionsList]                
            }

            //fetch Questions List
            let questionsList = await GetHseInspectionQuestionsByTemplateId(templateId, isDataStoreOnline) 
            if(questionsList != null && questionsList != undefined && questionsList.length > 0 ) {
                questionsList.sort((a, b) => a.sequenceId - b.sequenceId);
                templateDetails.controlList = [...questionsList]                
            }
            
        }
        
    } catch (error) {
        console.log('error Listing GetHseInspectionTemplateFormattedObject:', error)
    }
    return templateDetails
}

export async function CreateHseUserInspection (hseUserInspection, isDataStoreOnline=false) {
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.save(
      new HseUserInspection({ ...hseUserInspection })
    ): (await API.graphql(graphqlOperation(createHseUserInspection, {input: hseUserInspection})));
    if(result && result.data && result.data.createHseUserInspection) {
      return result.data.createHseUserInspection;
    }
    return result

  } catch (err) {
    console.log('error creating  Hse User Inspection:', err)
  }
  return false
}

export async function GetHseInspectionByUserIdAndProjectId (userId, projectId) {
  try {
    let result = await DataStore.query(HseUserInspection, c =>
      c.userId('eq',userId) && c.projectId('eq', projectId) && c.isDeleted('eq', false)
    )

  //  console.log(result)
    return await GetFormattedHseUserInspectionMasterList(result)
  } catch (err) {
    console.log('error Listing GetHseInspectionTemplates:', err)
  }
  return []
}

export async function GetHseInspectionByUserId (userId, isDataStoreOnline = false) {
  const filter = {
    userId : {eq: userId},
    isDeleted : {eq: false}
  };

  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseUserInspection, c =>
      c.userId('eq',userId) && c.isDeleted('eq', false),
      {  sort: s => s.createdAt(SortDirection.DESCENDING)}
    )
: (await FetchDataWithNextToken(listHseUserInspections, filter, 'listHseUserInspections'));
  //  console.log(result)
    return await GetFormattedHseUserInspectionMasterList(result, isDataStoreOnline)
  } catch (err) {
    console.log('error Listing GetHseInspectionTemplates:', err)
  }
  return []
}

export async function GetHseInspectionById (id) {
  try {
    let result = await DataStore.query(HseUserInspection, c =>
      c.id('eq', id) && c.isDeleted('eq', false)
    )

  //  console.log(result)
    return (await GetFormattedHseUserInspectionMasterList(result))
  } catch (err) {
    console.log('error Listing GetHseInspectionTemplates:', err)
  }
  return []
}

async function GetFormattedHseUserInspectionMasterList(data, isDataStoreOnline = false) {
  // console.log(data)
  if (data !== undefined) {
    let formattedData = await Promise.all(
      data.map(async item => {
        let templateObj = await GetHseInspectionTemplateById(item.templateId)
        let projectDetails = isDataStoreOnline ? await DataStore.query(HseProject, c =>
          c.id('eq', item.projectId)) : ( (item.project && item.project.items ) ? ( item.project.items) : null )
        if(templateObj != null && templateObj != undefined) {
          return {
            ...item,
            isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
            templateName: templateObj[0].name,
            templateDescription: templateObj[0].description
          }
        }
      })
    ).then(values => {
      // console.log(values)
      return values
    })

    // console.log(formattedData)

    return formattedData
  }
  return []
}

export async function CreateHseUserInspectionFormData (hseInspectionFromData, isDataStoreOnline=false) {
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.save(
      new HseUserInspectionData({ ...hseInspectionFromData })
    ): (await API.graphql(graphqlOperation(createHseUserInspectionData, {input: hseInspectionFromData})));
    if(result && result.data && result.data.createHseUserInspectionData) {
      return result.data.createHseUserInspectionData;
    }
    return result
  } catch (err) {
    console.log('error at CreateHseUserInspectionFormData:', err)
  }
  return false
}

export async function GetHseUserInspectionFormDataByFormId(formId, isDataStoreOnline = false) {
  
const filter = {
  userInspectionId : {eq: formId},
  isDeleted : {eq: false}
};

  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseUserInspectionData, c =>
      c.userInspectionId('eq', formId)
       && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseUserInspectionData, filter, 'listHseUserInspectionData'));


  //  console.log(result)
    return result
  } catch (err) {
    console.log('error Listing GetHseUserInspectionFormDataByFormId:', err)
  }
  return []
}

export async function GetHseUserInspectionFormData(id) {
  try {
    let result = await DataStore.query(HseUserInspectionData, id)

    //  console.log(result)
    return result
  } catch (err) {
    console.log('error Listing GetHseUserInspectionFormData:', err)
  }
  return []
}

export async function CreateHseInspectionAttachedFile (hseUserInspectionAttachedFile, isDataStoreOnline=false) {
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.save(
      new HseUserInspectionFiles({ ...hseUserInspectionAttachedFile })
    ): (await API.graphql(graphqlOperation(createHseUserInspectionFiles, {input: hseUserInspectionAttachedFile})));
    if(result && result.data && result.data.createHseUserInspectionFiles) {
      return result.data.createHseUserInspectionFiles;
    }
    return result
  } catch (err) {
    console.log('error at CreateHseInspectionAttachedFile:', err)
  }
  return false
}

export async function GetHseInspectionAttachedFilesByUserFormDataId(formDataId , networkStatus) {
  try {
    let result = []
    if(networkStatus) { 
      let filter = {
        userInspectionDataId: {
          eq: formDataId
        },
        isDeleted: {
            eq: false
        }
      };

      result = await FetchDataWithNextToken(listHseUserInspectionFiles, filter, "listHseUserInspectionFiles")

    } else {
      result = await DataStore.query(HseUserInspectionFiles, c =>
        c.userInspectionDataId('eq', formDataId)
         && c.isDeleted('eq', false)
      )
    }

  //  console.log(result)
    return result
  } catch (err) {
    console.log('error Listing GetHseInspectionAttachedFilesByUserFormDataId:', err)
  }
  return []
}

export async function GetHseInspectionAttachedFilesByInspectionId(inspectionId , networkStatus , isDataStoreOnline = false) {
  try {
    let result = []

    if(networkStatus) { 
      let filter = {
        userInspectionId: {
          eq: inspectionId
        },
        isDeleted: {
            eq: false
        }
      };

      result = await FetchDataWithNextToken(listHseUserInspectionFiles, filter, "listHseUserInspectionFiles")

    } else {
      let filter = {
        userInspectionId: {
          eq: inspectionId
        },
        isDeleted: {
            eq: false
        }
      };
      result =(isDataStoreOnline) ?  await DataStore.query(HseUserInspectionFiles, c =>
        c.userInspectionId('eq', inspectionId)
         && c.isDeleted('eq', false)
      ): (await FetchDataWithNextToken(listHseUserInspectionFiles, filter, 'listHseUserInspectionFiles'));
  
    }


    // console.log(result)
    return result
  } catch (err) {
    console.log('error Listing GetHseInspectionAttachedFilesByUserFormDataId:', err)
  }
  return []
}

export async function GetFormattedInspectionReportByFormId(id, networkStatus,isDataStoreOnline = false) {
  
const filter = {
  id : {eq: id},
  isDeleted : {eq: false}
};

  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseUserInspection, c =>
      c.id('eq', id) && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseUserInspections, filter, 'listHseUserInspections'));

    // console.log(result)
    let finalResult = await GetFormattedFormDataWithTemplateDetails(result, networkStatus, isDataStoreOnline)
    return finalResult;
  } catch (err) {
    console.log('error Listing GetHseInspectionTemplates:', err)
  }
  return []
}

async function GetFormattedFormDataWithTemplateDetails(userInspectionData, networkStatus,isDataStoreOnline) {
  let controlListWithData = []
  let userInspection = {}
  let projectUserResult = await GetHseProjectUserList(userInspectionData[0].projectId, isDataStoreOnline)
  projectUserResult = projectUserResult.map((item) => {
    return {
        label: item['userName'],
        value: item['userId']
    }
  })

  if(userInspectionData != null && userInspectionData != undefined && userInspectionData.length > 0) {
    userInspection = {...userInspectionData[0]}
    // console.log(userInspection)
    if(userInspection.templateId != undefined) {
      let templateDetails = await GetHseInspectionTemplateFormattedObject(userInspection.templateId,isDataStoreOnline)
      if(templateDetails != null && templateDetails != undefined) {
        // console.log(templateDetails)

        let userFormData = await GetHseUserInspectionFormDataByFormId(userInspection.id,isDataStoreOnline)
        let userFormFiles = await GetHseInspectionAttachedFilesByInspectionId(userInspection.id, networkStatus,isDataStoreOnline)
        // console.log(userFormData)

        if(userFormData && userFormData.length > 0 && userFormFiles) { 
          // console.log(userFormData)
          controlListWithData = await Promise.all( templateDetails.controlList.map(async (item) => {
            let userControlData = userFormData.find( i => i.templateQuestionId == item.id)
            if(userControlData != null && userControlData != undefined) {
              let objToReturn = {
                ...item,
                controlValue: userControlData.controlValue,
                comment: userControlData.comment
              }

              if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.PLANTCONTROL) {
                let plantDetails = await GetHsePlantWithAdditionalDetailsById(userControlData.controlValue, isDataStoreOnline)
                objToReturn = {
                  ...objToReturn,
                  plantDetails: plantDetails
                }

              } else if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.EQUIPMENTCONTROL) {
                let equipmentDetails  = await GetHseEquipmentWithDetailsById(userControlData.controlValue, isDataStoreOnline)
                objToReturn = {
                  ...objToReturn,
                  equipmentDetails: equipmentDetails
                }
              } else if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.ORGANISATIONCONTROL) {
                let organisationDetails  = await GetOrganisationById(userControlData.controlValue)
                objToReturn = {
                  ...objToReturn,
                  organisationDetails: organisationDetails
                }
              } else if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.DATECONTROL) {
                objToReturn.controlValue = getLocaleDateTimeString(objToReturn.controlValue)
                objToReturn = {
                  ...objToReturn
                }
              } else if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.LOCATIONCONTROL) {
                objToReturn.controlValue = JSON.parse(objToReturn.controlValue).description
                objToReturn = {
                  ...objToReturn
                }
              } else if(item.controlType == Constants.FORM_BUILDER_CONTROLS_ENUM.USERCONTROL) {
                let tempArr = objToReturn.controlValue.split(',')
                let userNameArr = []
                tempArr.forEach(element => {
                  userNameArr.push(GetDisplayName(element, projectUserResult))
                });
                objToReturn.controlValue = userNameArr.toString()
              }

              //Check for attached files
              let files = null
              if(userFormFiles && userFormFiles.length > 0) {                
                files = userFormFiles.filter(f => f.userInspectionDataId == userControlData.id)
                // console.log(files)
              }

              // let files = await GetHseInspectionAttachedFilesByUserFormDataId(userControlData.id)

              if(files && files.length > 0) {
                  let tempFiles = await Promise.all(files.map(async (f,i) => {
                    let key =
                    f.imagePath && f.imagePath.length > 0
                      ? f.imagePath.substring(f.imagePath.indexOf('inspection/'))
                      : ''

                      // console.log(key)
                      let signedPath = await getSignedKey(key)
                    return (
                      {
                        ...f,
                        signedPath: signedPath,
                        signedImagePath: signedPath
                      }
                    )
                  })
                  ).then(result => (result))


                 return {...objToReturn, files: tempFiles}
              } else {
                return {...objToReturn, files: []}
              }
            } else {
              return  {
                ...item,
                controlValue: '',
                comment: '',
                files: []
              }
            }

          })
          ).then(result => {
            return result;
          })

          // console.log(controlListWithData)

          userInspection = {...userInspection, name: templateDetails.name, description: templateDetails.description, occurrence: templateDetails.occurrence, sectionList: [...templateDetails.sectionList] , formControlData: [...controlListWithData]}
          return userInspection;
        } // userFormData null check if end
        
        //check if any values in control 
      } // templateDetails null check if end

    }

    
  }
  return null;
}



export async function GetHseInspectionFormIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      let result = await FetchDataWithNextToken(listHseUserInspectionsOnlyIds, filter, "listHseUserInspections")

      if(result ) {      
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE Inspection Form Ids:', err);
    }
    return formattedData;
}

export const listHseUserInspectionsOnlyIds = /* GraphQL */ `
  query ListHseUserInspections(
    $filter: ModelHseUserInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;