import { Storage } from 'aws-amplify';
import config from './../aws-exports';


export async function toBase64(file , cb) {
  let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
}

export async function toBase64await (file) {
  const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve(ev.target.result)
      }
      reader.readAsDataURL(file)
    })
}

function getMimeType (fileName) {
  const extension = fileName.split('.')[1]
  if(extension === "jpeg" || extension === "jpg") {
    return "image/jpeg";
  } else if ( extension === "png" ) {
    return "image/png";
  }
}

export async function base64ToFile (base64Content, filename) {
  return fetch(base64Content)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: getMimeType(filename) })
    })
}

export async function getSignedKey(key){
   let result = await Storage.get(key);
    return result
}

export function downloadBlob(blob, filename) {
    // console.log('download blob');
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  export async function UploadFileInBackground(file, fileName){    
    let resultFlag = false;
    try {
        const { type: mimeType } = file
        // console.log('mimetype: ' + mimeType);

        await Storage.put(fileName, file, {
            contentType: mimeType,
            progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);               
          },
        })
        .then((result) => {
          if(result !== undefined && result.key !== "" ) {
            resultFlag = true; 
          }
          else {resultFlag = false;}
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            resultFlag = false;
          }
        });
        return resultFlag;
    }
    catch(err) {
        console.log('error Uploading File:', err)
    }
    
}

export async function DeleteFileFromS3Storage(filePath) {
  try {
    let result = await Storage.remove(filePath)
    // console.log(result)
  } catch (error) {
    console.log(error)
  }
 
}
  
export async function UploadFile(file, fileName, progressMessage){    

    try {
        const { type: mimeType } = file
        console.log('mimetype: ' + mimeType);

        let result = Storage.put(fileName, file, {
            contentType: mimeType,
            progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                progressMessage(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });

        // console.log(result);
    }
    catch(err) {
        console.log('error Uploading File:', err)
    }
    
}