// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { HseCertification, HseUserCertification, HseUserOtherRecords, HseUserQualification, HseLicense, HseLicenseRestriction, HseUserLicense, HseUserProfileImageList, Induction, HseInductionUser, HseProject, HseUserRole, HseProjectUserDetails, HseProjectSignOnOff, HseOrganisation, HseUserOrganisation, HseConfiguration, HseUserProjectFatigue, HseFormImage, HseTake5EnergySourceAndHazard, HseProjectTake5Assessment, HseTake5AssessmentCrewSignature, HseIncidentForm, HseEventForm, HseStkyEngagementForm, HseUserActionForm, HseUserActionImages, HseInspectionTemplate, HseInspectionTemplateSection, HseInspectionTemplateQuestion, HseUserInspection, HseUserInspectionData, HseUserInspectionFiles, HseJourneyPlannerForm, HseApprovalManagement, HseUserHazardReports, HsePlant, HseProjectItemDetail, HseEquipment, HseSwms, HseSwmsAction, HseSwmsWorkflow, HseReportConfiguration, HseTake5AssessmentEnergySourceHazard, HseIncidentInjuryFirstAid, HseJourneyPlannerBreakPoint, HseJourneyPlannerDriver, HseJourneyPlannerPassenger, HseJourneyPlannerRiskAssessment, HsePlantServiceRecords, HseSwmsActivityAssessed, HseContractorScope } = initSchema(schema);

export {
  HseCertification,
  HseUserCertification,
  HseUserOtherRecords,
  HseUserQualification,
  HseLicense,
  HseLicenseRestriction,
  HseUserLicense,
  HseUserProfileImageList,
  Induction,
  HseInductionUser,
  HseProject,
  HseUserRole,
  HseProjectUserDetails,
  HseProjectSignOnOff,
  HseOrganisation,
  HseUserOrganisation,
  HseConfiguration,
  HseUserProjectFatigue,
  HseFormImage,
  HseTake5EnergySourceAndHazard,
  HseProjectTake5Assessment,
  HseTake5AssessmentCrewSignature,
  HseIncidentForm,
  HseEventForm,
  HseStkyEngagementForm,
  HseUserActionForm,
  HseUserActionImages,
  HseInspectionTemplate,
  HseInspectionTemplateSection,
  HseInspectionTemplateQuestion,
  HseUserInspection,
  HseUserInspectionData,
  HseUserInspectionFiles,
  HseJourneyPlannerForm,
  HseApprovalManagement,
  HseUserHazardReports,
  HsePlant,
  HseProjectItemDetail,
  HseEquipment,
  HseSwms,
  HseSwmsAction,
  HseSwmsWorkflow,
  HseReportConfiguration,
  HseTake5AssessmentEnergySourceHazard,
  HseIncidentInjuryFirstAid,
  HseJourneyPlannerBreakPoint,
  HseJourneyPlannerDriver,
  HseJourneyPlannerPassenger,
  HseJourneyPlannerRiskAssessment,
  HsePlantServiceRecords,
  HseSwmsActivityAssessed,
  HseContractorScope
};