import { API, graphqlOperation } from "aws-amplify";
import { listHseUserOrganisations, listHseOrganisations } from "../graphql/queries";
import {
  createHseOrganisation,
  updateHseOrganisation,
  createHseUserOrganisation,
  updateHseUserOrganisation
} from "../graphql/mutations";

import { 
  FetchDataWithNextToken, 
  getAWSLocalDateInISOStringFormat,
  listFilter
} from "./HseCommonService";

const listCurrentUserOrganisations = /* GraphQL */ `
  query ListHseUserOrganisations(
    $filter: ModelHseUserOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organisationId
        isCurrent
        roleId
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export async function GetMyPendingTransferRequest(userId) {
    let formattedData = null;
    try {
      let filter = {
        userId: {
            eq: userId,
        },
        status: {
          eq: 'PENDING APPROVAL',
        }
      };
      let result = await FetchDataWithNextToken(
        listHseUserOrganisations,
        filter,
        "listHseUserOrganisations"
      );
  
      if (result !== undefined) {
        formattedData = formatHseOrganizationUserList(result);
      } else {
        return null;
      }
    } catch (err) {
      console.log("error Getting HSE Organisation Users:", err);
    }
    return formattedData;
  }

  export async function GetMyCurrentOrganisationDetails(userId) {
    let formattedData = null;
    try {
      let filter = {
        userId: {
          eq: userId,
        },
        isCurrent: {
          eq: true,
        },
        isDeleted: {
          eq: false,
        },
      };
      let result = await FetchDataWithNextToken(
        listCurrentUserOrganisations,
        filter,
        "listHseUserOrganisations"
      );

      if (result !== undefined) {
        formattedData = formatHseOrganizationUserList(result);
      } else {
        return null;
      }
    } catch (err) {
      console.log("error Getting HSE Organisation Users:", err);
    }
    return formattedData;
  }



export async function CreateHseOrganisationUser(userDetails) {
  try {
    let result = await API.graphql(
      graphqlOperation(createHseUserOrganisation, {
        input: userDetails,
      })
    );
    return result;
  } catch (err) {
    console.log("error Create HseOrganisationUser", err);
  }
  return false;
}

export async function UpdateHseOrganisationUser(data) {
  try {
    let result = await API.graphql({
      query: updateHseUserOrganisation,
      variables: { input: data },
    });
    return result;
  } catch (err) {
    console.log("error updating status for HseOrganisationUser:", err);
    return false;
  }
}

const formatHseOrganizationUserList = (data) => {
  if (data && data.length) {
    let formattedData = data.map((item) => {
      item.createdAt = item.createdAt && getAWSLocalDateInISOStringFormat(new Date(item.createdAt))
      item.organisationName = item.organisation && item.organisation.items?.length &&  item.organisation.items[0].name
      return {
        ...item
      };
    });
    return formattedData;
  } else {
    return null;
  }
}


export async function GetOrganisationById(orgId) {
  let formattedData = [];
  try {
    let filter = {
      id: {
        eq: orgId,
      },
      isDeleted: {
        eq: false,
      },
    };

    let result = await FetchDataWithNextToken(
      listHseOrganisations,
      filter,
      "listHseOrganisations"
    );

    if (result !== undefined) {
      formattedData = result;
    } else {
      return [null];
    }
  } catch (err) {
    console.log("error Getting HSE Organisation Users:", err);
  }
  return formattedData;
}