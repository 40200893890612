import {API, DataStore, graphqlOperation } from 'aws-amplify'
import { HseProjectTake5Assessment, HseTake5EnergySourceAndHazard , HseTake5AssessmentEnergySourceHazard, HseTake5AssessmentImages, HseTake5AssessmentCrewSignature, HseProjectUserDetails, HseUserRole} from './../models'
import {
  GetHseUserActionFormByFormId,
  listAttachmentFileByFormId
} from './HseUserFormsService'

import { listHseProjectTake5Assessments, listHseTake5AssessmentCrewSignatures, getHseProjectTake5Assessment} from '../graphql/queries';
import { createHseProjectTake5Assessment, createHseTake5AssessmentCrewSignature, updateHseProjectTake5Assessment, updateHseTake5AssessmentCrewSignature } from './../graphql/mutations'

import { GetHseProjectUserDetailsByUserId } from './HseProjectService'

import { FetchDataWithNextToken, getAWSLocalDateTimeInISOStringFormat } from './HseCommonService'


const listFilter = (list, field) => {
  var objPosition = {}, ttl = field;
  return list.map(i => {
         return { ...objPosition, [ttl]: { 'eq': i}
        }
 })
};

export async function GetTake5AssessmentIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      }
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      
      let result = await FetchDataWithNextToken(listHseProjectTake5AssessmentsCustom, filter, "listHseProjectTake5Assessments")
      if(result) {
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE project Take 5 Ids:', err);
    }
    return formattedData;
}

export const listHseProjectTake5AssessmentsCustom = /* GraphQL */ `
  query ListHseProjectTake5Assessments(
    $filter: ModelHseProjectTake5AssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjectTake5Assessments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id            
    }
  }
  }
`;

export async function CreateHseProjectTake5Assessment (hseProjectTake5Assessment, isDataStoreOnline=false) {
    try {
      let objToCreate = await createTake5AssessmentObject(hseProjectTake5Assessment)

      // console.log('CreateHseProjectTake5Assessment obj',objToCreate)
      let result = isDataStoreOnline
      ? await DataStore.save(
          new HseProjectTake5Assessment({ ...objToCreate })
        )
      : await API.graphql(
          graphqlOperation(createHseProjectTake5Assessment, { input: objToCreate })
        );
    if (result && result.data && result.data.createHseProjectTake5Assessment) {
      console.log("result11",result);
      return result.data.createHseProjectTake5Assessment;
    }
    return result;
  

    } catch (err) {
      console.log('error creating  Hse Project Take5Assessment:', err)
    }
    return false
}

const createTake5AssessmentObject = async (take5Data) => {
    let hazardList = (take5Data.EnergySourceHazard) ? await take5Data.EnergySourceHazard.map(item => {
      return new HseTake5AssessmentEnergySourceHazard({id: item.id, energySource: item.energySource, hazard: item.hazard, isDeleted: item.isDeleted,createdAt: getAWSLocalDateTimeInISOStringFormat(new Date())})
    }) : take5Data.EnergySourceHazard

    let Take5ToCreate = {
      ...take5Data,
      isDeleted: false,
      EnergySourceHazard: hazardList
    }

    return Take5ToCreate;
} 

export async function updateTake5Assessment (take5Id, take5Assessment, isDataStoreOnline = false, take5AssessmentData) {
  try {

    if(isDataStoreOnline) {
     
    const original = await DataStore.query(
      HseProjectTake5Assessment,
      take5Id
    )

        let objToUpdate = await createTake5AssessmentObject(take5Assessment)

        let result = await DataStore.save( HseProjectTake5Assessment.copyOf(original, updated => {
        updated.jobName = take5Assessment.jobName
        updated.Status = take5Assessment.Status
        updated.isOtherHazards = take5Assessment.isOtherHazards
        updated.OtherHazards = take5Assessment.OtherHazards
        updated.isStky = take5Assessment.isStky
        updated.adequateControls = take5Assessment.adequateControls
        updated.additionalControls = take5Assessment.additionalControls
        updated.selectedEnergySources = take5Assessment.selectedEnergySources
        updated.EnergySourceHazard = objToUpdate.EnergySourceHazard
        updated.location =  take5Assessment.location
        updated.locationTag =  take5Assessment.locationTag
        }))
        return result
    } else {
      let result = await API.graphql({ query: updateHseProjectTake5Assessment, variables: { input: {...take5Assessment , id: take5AssessmentData?.id, _version: take5AssessmentData?._version}} })
      if(result && result.data && result.data.updateHseProjectTake5Assessment) {
        return result.data.updateHseProjectTake5Assessment;
      }
      return null;
    }




    

  } catch (error) {
    console.log('error updating status for Take5Assessment:', error)
    return false
  }
}

// export async function updateTake5Assessment (take5AssessmentId, propsToUpdate) {
//     try {
//       const original = await DataStore.query(
//         HseProjectTake5Assessment,
//         take5AssessmentId
//       )

//       // console.log(propsToUpdate)
//       let result = await DataStore.save(
//         HseProjectTake5Assessment.copyOf(original, updated => {
//             if(propsToUpdate.find(p => p.name == 'jobName')) {
//                 updated.jobName = propsToUpdate.find(p => p.name == 'jobName').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'Status')) {
//                 updated.Status =  propsToUpdate.find(p => p.name == 'Status').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'isStky')) {
//                 updated.isStky =  propsToUpdate.find(p => p.name == 'isStky').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'adequateControls')) {
//                 updated.adequateControls =  propsToUpdate.find(p => p.name == 'adequateControls').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'additionalControls')) {
//                 updated.additionalControls =  propsToUpdate.find(p => p.name == 'additionalControls').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'isOtherHazards')) {
//                 updated.isOtherHazards =  propsToUpdate.find(p => p.name == 'isOtherHazards').value;
//             }
//             if(propsToUpdate.find(p => p.name == 'OtherHazards')) {
//                 updated.OtherHazards =  propsToUpdate.find(p => p.name == 'OtherHazards').value;
//             }
//         })
//       )

//       console.log(result)
//       return true
//     } catch (err) {
//       console.log('error updating status for Take5Assessment:', err)
//     }
// }

// export async function softDeleteTake5Assessment(id) {
//   try {
//     const original = await DataStore.query(HseProjectTake5Assessment, id);

//     let result = await DataStore.save(
//       HseProjectTake5Assessment.copyOf(original, updated => {
//         updated.isDeleted = true;
//       })
//     );

//    return true;
//   } catch (err) {
//       console.log('error soft deleting at softDeleteTake5Assessment:', err)
//       return false;
//   }
// }

// export async function GetHseProjectTake5Assessment (take5Id,isDataStoreOnline) {
//   const filter = {
//     id : {eq: take5Id},
//     isDeleted : {eq: false}
//   };
//     try {
//       let result =(isDataStoreOnline) ?  await DataStore.query(HseProjectTake5Assessment, c =>
//         c.id('eq',take5Id) &&  c.isDeleted('eq', false)
//       ): (await FetchDataWithNextToken(listHseProjectTake5Assessments, filter, 'listHseProjectTake5Assessments'));
    
//     //  console.log(result)
//       return result
//     } catch (err) {
//       console.log('error Listing GetHseProjectTake5Assessment:', err)
//     }
//     return []
// }

export async function GetHseProjectTake5Assessment (take5Id,isDataStoreOnline) {
  const filter = {
    id : {eq: take5Id}
  };
    try {
      let result =(isDataStoreOnline) ?  await DataStore.query(HseProjectTake5Assessment, c =>
        c.id('eq',take5Id) &&  c.isDeleted('eq', false)
      ) : 
      await API.graphql(graphqlOperation( getHseProjectTake5Assessment, { id: take5Id} ))
      if(isDataStoreOnline) {
        return result && result.length && result[0]
      } else {
        return result && result.data && result.data.getHseProjectTake5Assessment
      }

    } catch (err) {
      console.log('error Listing GetHseProjectTake5Assessment:', err)
    }
    return []
}

export async function GetHseProjectTake5AssessmentFormattedObject (take5Id, networkStatus, isDataStoreOnline = false) {
  // let take5Assessment = {id:'',jobName:'', selectedEnergySource:[], masterSelectedHazardList: [], selectedHazardList: [], userActions: [], isOtherHazards: false, isStky: false, adequateControls: true, additionalControls: '', attachedPhotosList: [], crewSignatures: []}
  let take5Assessment = {}  
  try {
      let t5assessmentObj = await GetHseProjectTake5Assessment(take5Id,isDataStoreOnline)

      if(t5assessmentObj != null & t5assessmentObj != undefined ) {
        take5Assessment = {...t5assessmentObj}
        //fetch images attachedPhotosList
        let files = await listAttachmentFileByFormId(take5Id,"TAKE5",isDataStoreOnline)

        if(files != null && files != undefined && files.length > 0) {
            files = files.map((item) => ({...item, savedToDb: true}))
            take5Assessment = {...take5Assessment, take5AssessmentImages: files}
        }

        //fetch user actions
        let uActions = await GetHseUserActionFormByFormId(take5Id,isDataStoreOnline)
        if(uActions != null && uActions != undefined && uActions.length > 0) {
          take5Assessment = {...take5Assessment, userActionList: uActions}
        }

        //fetch crew Signatures
        if(networkStatus) {
          let signatureList = await GetAllHseTake5AssessmentCrewSignatureForTake5Id(take5Id)
          if(signatureList != null && signatureList != undefined && signatureList.length > 0) {
            take5Assessment = {...take5Assessment, crewSignatures: signatureList}
          }
        } else {
          let signatureList = await DataStoreGetAllHseTake5AssessmentCrewSignatureForTake5Id(take5Id, isDataStoreOnline)
          if(signatureList != null && signatureList != undefined && signatureList.length > 0) {
            take5Assessment = {...take5Assessment, crewSignatures: signatureList}
          }
        }
       
      }

      //console.log(take5Assessment)
      return take5Assessment
    } catch (err) {
      console.log('error Listing GetHseProjectTake5Assessment:', err)
    }
    return take5Assessment
}

// export async function GetHseProjectTake5AssessmentFormattedObject (take5Id) {
//     let take5Assessment = {id:'',jobName:'', selectedEnergySource:[], masterSelectedHazardList: [], selectedHazardList: [], userActions: [], isOtherHazards: false, isStky: false, adequateControls: true, additionalControls: '', attachedPhotosList: [], crewSignatures: []}
//     try {
//       let t5assessmentObj = await GetHseProjectTake5Assessment(take5Id)

//       if(t5assessmentObj != null & t5assessmentObj != undefined && t5assessmentObj.length > 0) {
//         take5Assessment = {...take5Assessment,...t5assessmentObj[0]}

//         //fetch hazards for this take5
//         let hazards = await GetAllEnergySourceHazardForTake5Assessment(take5Id)

//         if(hazards != null && hazards != undefined) {
//             let hazardList = []

//             hazardList = await Promise.all(
//                 hazards.map(async (hItem) => {
//                     return GetHseProjectTake5EnergySourceAndHazard(hItem.energySourceHazardId).then(async esh => {
//                         if(esh != null && esh != undefined && esh.length > 0) {
//                             hazardList = [...hazardList, ...esh]
//                        }
//                     })

//                 })
//             ).then(result => {
//                 // console.log('reached here')
//                 return hazardList
//               })

//             if(hazardList.length > 0) {
//                 const energySourceList = [...new Set(hazardList.map(item => item.energySource))]
//                 take5Assessment = {...take5Assessment, selectedEnergySource: energySourceList, masterSelectedHazardList: hazardList, selectedHazardList: hazardList}
//             }
//         }

//         //fetch images attachedPhotosList
//         let files = await GetAllHseTake5AssessmentAttachmentFile(take5Id)

//         if(files != null && files != undefined && files.length > 0) {
//             files = files.map((item) => ({...item, savedToDb: true}))
//             take5Assessment = {...take5Assessment, attachedPhotosList: files}
//         }

//         //fetch user actions
//         let uActions = await GetHseUserActionFormByFormId(take5Id)
//         if(uActions != null && uActions != undefined && uActions.length > 0) {
//           take5Assessment = {...take5Assessment, userActions: uActions}
//         }

//         //fetch crew Signatures
//         let signatureList = await GetAllHseTake5AssessmentCrewSignatureForTake5Id(take5Id)
//         if(signatureList != null && signatureList != undefined && signatureList.length > 0) {
//           take5Assessment = {...take5Assessment, crewSignatures: signatureList}
//         }
//       }

//     //  console.log(result)
//       return take5Assessment
//     } catch (err) {
//       console.log('error Listing GetHseProjectTake5Assessment:', err)
//     }
//     return take5Assessment
// }

// export async function GetHseProjectTake5EnergySourceAndHazard (id) {
//     try {
//       let result = await DataStore.query( HseTake5EnergySourceAndHazard, c =>
//         c.id('eq',id) &&  c.isDeleted('eq', false)
//       )

//     //  console.log(result)
//       return result
//     } catch (err) {
//       console.log('error Listing GetHseProjectTake5EnergySourceAndHazard:', err)
//     }
//     return []
// }

// export async function GetAllEnergySourceHazardForTake5Assessment (take5Id) {
//     try {
//       let result = await DataStore.query(HseTake5AssessmentEnergySourceHazard, c =>
//         c.take5id('eq',take5Id) &&  c.isDeleted('eq', false)
//       )
//     console.log(result)
//       return result
//     } catch (err) {
//       console.log('error Listing GetTake5AssessmentEnergySourceHazard:', err)
//     }
//     return []
// }

// export async function CreateTake5AssessmentEnergySourceHazard (hseTake5AssessmentEnergySourceHazard) {
//     try {
//       let result = await DataStore.save(
//         new HseTake5AssessmentEnergySourceHazard({ ...hseTake5AssessmentEnergySourceHazard })
//       )

//       console.log(result)
//       return result
//     } catch (err) {
//       console.log('error creating  CreateTake5AssessmentEnergySourceHazard:', err)
//     }
//     return false
// }

// export async function GetTake5AssessmentEnergySourceHazard (take5Id, energySourceHazardId) {
//     try {
//       let result = await DataStore.query(HseTake5AssessmentEnergySourceHazard, c =>
//         c.take5id('eq',take5Id) && c.energySourceHazardId('eq', energySourceHazardId)  &&  c.isDeleted('eq', false)
//       )
//     //   console.log(result)
//       return result
//     } catch (err) {
//       console.log('error Listing GetTake5AssessmentEnergySourceHazard:', err)
//     }
//     return []
// }

// export async function softDeleteTake5AssessmentEnergySourceHazard(id) {
//     try {
//       const original = await DataStore.query(HseTake5AssessmentEnergySourceHazard, id);

//       let result = await DataStore.save(
//         HseTake5AssessmentEnergySourceHazard.copyOf(original, updated => {
//           updated.isDeleted = true;
//         })
//       );

//      return true;
//     } catch (err) {
//         console.log('error soft deleting at softDeleteTake5AssessmentEnergySourceHazard:', err)
//         return false;
//     }
//   }

// export async function updateTake5AssessmentStatus (take5AssessmentId, status) {
//     try {
//       const original = await DataStore.query(
//         HseProjectTake5Assessment,
//         take5AssessmentId
//       )

//       let result = await DataStore.save(
//         HseProjectTake5Assessment.copyOf(original, updated => {
//           updated.Status = status
//         })
//       )

//       console.log(result)
//       return true
//     } catch (err) {
//       console.log('error updating status for Take5Assessment:', err)
//     }
// }

// export async function CreateTake5AttachmentFile (hseTake5AttachmentFile) {
//     try {
//       let result = await DataStore.save(
//         new HseTake5AssessmentImages({ ...hseTake5AttachmentFile })
//       )

//       console.log(result)
//       return result
//     } catch (err) {
//       console.log('error creating  Hse Project CreateTake5AttachmentFile:', err)
//     }
//     return false
// }

// export async function softDeleteTake5AttachmentFile(id) {
//   try {
//     const original = await DataStore.query(HseTake5AssessmentImages, id);

//     let result = await DataStore.save(
//       HseTake5AssessmentImages.copyOf(original, updated => {
//         updated.isDeleted = true;
//       })
//     );

//    return true;
//   } catch (err) {
//       console.log('error soft deleting at softDeleteTake5AttachmentFile:', err)
//       return false;
//   }
// }

// export async function GetAllHseTake5AssessmentAttachmentFile (take5Id) {
//     try {
//       let result = await DataStore.query(HseTake5AssessmentImages, c =>
//         c.take5id('eq', take5Id)  && c.isDeleted('eq', false)
//       )
//       return result
//     } catch (err) {
//       console.log('error Listing Take5 GetAllHseTake5AssessmentAttachmentFile:', err)
//     }
//     return undefined
//   }

// export async function GetAllHseTake5EnergySource () {
//   try {
//     let result = await DataStore.query(HseTake5EnergySourceAndHazard, c =>
//       c.isDeleted('eq', false)
//     )
//     return result
//   } catch (err) {
//     console.log('error Listing Take5 Assessment:', err)
//   }
//   return undefined
// }

// export async function GetAllHseTake5EnergySourceForDDL () {
//   let result = await GetAllHseTake5EnergySource()

//   let AllEnergySource = []
//   let finalResult = {}
// }

export async function GetHazardsForEnergySource (energySource) {
    try {
      let result = await DataStore.query(HseTake5EnergySourceAndHazard, c =>
        c.energySource('eq',energySource) && c.isDeleted('eq', false)
      )
    //   console.log(result)
      return result
    } catch (err) {
      console.log('error Listing GetHazardsForEnergySource:', err)
    }
    return []
}

export async function CreateHseProjectEnergySourceAndHazard (take5EnergySourceAndHazard) {
  try {
    let result = await DataStore.save(
      new HseTake5EnergySourceAndHazard({ ...take5EnergySourceAndHazard })
    )

    // console.log(result)
    return result
  } catch (err) {
    console.log('error creating CreateHseProjectEnergySourceAndHazard:', err)
  }
  return false
}

export async function CreateHseTake5AssessmentCrewSignature (
  hseTake5AssessmentCrewSignature,  isDataStoreOnline=false
) {
  try {
    let result = isDataStoreOnline
    ? await DataStore.save(
        new HseTake5AssessmentCrewSignature({ ...hseTake5AssessmentCrewSignature })
      )
    : await API.graphql(
        graphqlOperation(createHseTake5AssessmentCrewSignature, { input: hseTake5AssessmentCrewSignature })
      );
  if (result && result.data && result.data.createHseTake5AssessmentCrewSignature) {
    console.log("result2",result);
    return result.data.createHseTake5AssessmentCrewSignature;
  }
  return result;


  } catch (err) {
    console.log(
      'error creating  Hse  Take5Assessment crew signatures item:',
      err
    )
  }
  return false
}

export async function updateTake5AssessmentCrewSignature (id, signatureb64, isDataStoreOnline = false, signatureData) {
  try {
    if(isDataStoreOnline) {
      const original = await DataStore.query(
        HseTake5AssessmentCrewSignature,
        id
      )
  
      let result = await DataStore.save(
        HseTake5AssessmentCrewSignature.copyOf(original, updated => {
          updated.signatureInBase64 = signatureb64
        })
      )
  
      // console.log(result)
      return true
    } else {
      let result = await API.graphql({ query: updateHseTake5AssessmentCrewSignature, variables: { input: {...signatureb64, id:signatureData.id, _version:signatureData.version }} })
      if(result && result.data && result.data.updateHseTake5AssessmentCrewSignature) {
        return result.data.updateHseTake5AssessmentCrewSignature;
      }
      return null;
    }


  } catch (err) {
    console.log('error updating signature for updateTake5AssessmentCrewSignature:', err)
  }
}

export async function softDeleteTake5AssessmentCrewSignature(id, isDataStoreOnline = false , signatureData) {
  try {
    if(isDataStoreOnline) {
      const original = await DataStore.query(HseTake5AssessmentCrewSignature, id);
      let result = await DataStore.save(
        HseTake5AssessmentCrewSignature.copyOf(original, updated => {
          updated.isDeleted = true;
        })
      );
  
     return true;
    } else {
     let result = await API.graphql({ query: updateHseTake5AssessmentCrewSignature, variables: { input: { id: signatureData?.id, _version: signatureData?.version, isDeleted:signatureData.isDeleted}} })
      if(result && result.data && result.data.updateHseTake5AssessmentCrewSignature) {
        return true;
      }
      return null;
    }


  } catch (err) {
      console.log('error soft deleting at HseTake5AssessmentCrewSignature:', err)
      return false;
  }
}

export async function DataStoreGetAllHseTake5AssessmentCrewSignatureForTake5Id (take5Id,isDataStoreOnline) {
  let formattedData = null
  const filter = {
    take5id : {eq: take5Id},
    isDeleted : {eq: false}
  };

  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseTake5AssessmentCrewSignature, c =>
      c.take5id('eq', take5Id)  && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseTake5AssessmentCrewSignatures, filter, 'listHseTake5AssessmentCrewSignatures'));
  
    if (result !== undefined) {
      formattedData = await formatHseTake5AssessmentCrewSignatureData(result)
      return formattedData
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing Take5 GetAllHseTake5AssessmentAttachmentFile:', err)
  }
  return undefined
}

export async function GetAllHseTake5AssessmentCrewSignatureForTake5Id (take5Id) {
  let formattedData = null
  try {
    let filter = {
      take5id: {
        eq: take5Id
      },
      isDeleted: {
          eq: false
      }
    };
    // let result = await API.graphql({ query: listHseProjectUserDetails, variables: { filter: filter}});
    let result = await FetchDataWithNextToken(listHseTake5AssessmentCrewSignatures, filter, "listHseTake5AssessmentCrewSignatures")
    if(result !== undefined) {
      if(result.length) {
        formattedData = await formatHseTake5AssessmentCrewSignatureData(result)
        console.log(formattedData)
      }
    }
    else {
      return [];
    }
  } catch (err) {
    console.log('error Listing Take5 GetAllHseTake5AssessmentAttachmentFile:', err)
  }
  return formattedData
}

async function formatHseTake5AssessmentCrewSignatureData (data) {
  if (data !== undefined) {
    let formattedData = await Promise.all(
      data.map(async item => {
        return GetHseProjectUserDetailsByUserId(item.userId).then(async result => {

          return {
            id: item.id,
            userId: item.userId,
            take5id: item.take5id,
            projectId: item.projectId,
            userName: result[0].userName,
            roleName: result[0].roleName,
            signatureInBase64: item.signatureInBase64,
            isDeleted: item.isDeleted,
            savedToDB: true,
            updateToDB: false,
            version:item._version
          }
        })

        
      })
    ).then(values => {
      // console.log(values)
      return values
    })

    // console.log(formattedData)

    return formattedData
  } else {
    return null
  }
}