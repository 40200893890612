import { Auth, API, graphqlOperation } from 'aws-amplify'

import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
  AdminGetUserCommand,
  AdminUpdateUserAttributesCommand,
  ListUsersCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  UpdateUserAttributesCommand
} from '@aws-sdk/client-cognito-identity-provider'

import { listHseUserRoles } from '../graphql/queries';

import awsConfig from '../aws-exports'

import { FetchDataWithNextToken } from './HseCommonService';

class ServiceResponse {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

export async function GetAllUsers () {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })  

  let UsersJson = []

  try {
    const data = await client
      .send(new ListUsersCommand({ UserPoolId: awsConfig.aws_user_pools_id}))
      .then(result => {
        let Users = result.Users
        const newUsers = Users.map(user => {
          //console.log(user);
          let newdata = user.Attributes.map(item => {
            return [item['Name'], item['Value']]
          })

          let tdata = Object.fromEntries(new Map(newdata))
          return {
            id: tdata['sub'],
            UserCreateDate: user.UserCreateDate,
            Enabled: user.Enabled,
            ...tdata
          }
        })

        UsersJson = JSON.parse(JSON.stringify(newUsers))
        //console.log('finalUsers: ' + UsersJson);
      })
  } catch (err) {
    console.error(err)
  }

  return UsersJson
  // return dummyUserData();
}

export async function GetUsers () {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })  

  let UsersJson = []

  try {
    const data = await client
      .send(new ListUsersCommand({ UserPoolId: awsConfig.aws_user_pools_id , Filter: "status = \"Enabled\""}))
      .then(result => {
        let Users = result.Users
        const newUsers = Users.map(user => {
          //console.log( user.UserCreateDate);
          let newdata = user.Attributes.map(item => {
            return [item['Name'], item['Value']]
          })

          let tdata = Object.fromEntries(new Map(newdata))
          return {
            id: tdata['sub'],
            UserCreateDate: user.UserCreateDate,
            ...tdata
          }
        })

        UsersJson = JSON.parse(JSON.stringify(newUsers))
        //console.log('finalUsers: ' + UsersJson);
      })
  } catch (err) {
    console.error(err)
  }

  return UsersJson
  // return dummyUserData();
}

function dummyUserData () {
  return [
    {
      id: '203199a1-00d1-4a4b-8e7b-21ecb4226297',
      sub: '203199a1-00d1-4a4b-8e7b-21ecb4226297',
      email_verified: 'true',
      'custom:organisationName': 'Quanta Services Company',
      phone_number: '+610416634342',
      given_name: 'Praf',
      family_name: 'personal',
      email: 'prafullatandel@gmail.com'
    },
    {
      id: '9c31ab4a-0a3a-44da-bcb3-5f7ee1f0363a',
      sub: '9c31ab4a-0a3a-44da-bcb3-5f7ee1f0363a',
      email_verified: 'true',
      'custom:organisationName': 'Consolidated Power Projects',
      phone_number: '+610452552533',
      given_name: 'Praf',
      family_name: 'new',
      email: 'testing@gmail.com'
    },
    {
      id: 'e337554c-44e4-4448-9c11-925fb53f6b80',
      sub: 'e337554c-44e4-4448-9c11-925fb53f6b80',
      email_verified: 'true',
      phone_number_verified: 'false',
      phone_number: '+61416634342',
      given_name: 'Prafulla',
      family_name: 'Tandel',
      email: 'prafulla.tandel@digitly.com.au'
    }
  ]
}



export async function GetCurrentUserInfo(isOnline){
  let userData = {}
  
  if(isOnline) {
    await Auth.currentUserInfo().then((user ) => {
      userData = user;
      
      // console.log('online user data ', user);
    })
  } else {
    Auth.currentAuthenticatedUser().then((user ) => {
      userData = user;
      // console.log('offline user data ', user);
    })
  }
  
  return userData;
}

export async function GetUser (userId) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    Username: userId,    
    UserPoolId: awsConfig.aws_user_pools_id
  }

  let userData = {}

  try {
     await client
      .send(new AdminGetUserCommand(params))
      .then(result => {

        if(result !== undefined) {

          let newdata = result.UserAttributes.map(item => {
            return [item['Name'], item['Value']]
          })

          
          userData = {            
            UserCreateDate: result.UserCreateDate,
            UserLastModifiedDate: result.UserLastModifiedDate,
            ...Object.fromEntries(new Map(newdata))
          }
        }
    });
 
    return userData;
  } catch (err) {
    console.error(err)
    return false
  }

}

export async function AdminCreateUser (userDetails) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    DesiredDeliveryMediums: ['EMAIL'],
    UserAttributes: [
      {
        Name: 'given_name',
        Value: userDetails.given_name
      },
      {
        Name: 'family_name',
        Value: userDetails.family_name
      },
      {
        Name: 'email',
        Value: userDetails.email
      },
      {
        Name: 'phone_number',
        Value: '+61' + userDetails.phone_number
      },
      {
        Name: 'email_verified',
        Value: 'true'
      },
      {
        Name: 'custom:organisationName',
        Value: userDetails.organisation_name
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminCreateUserCommand(params))
    // console.log(data);
    return  new ServiceResponse("success", "User has been created successfully.")
  } catch (err) {
    if(err.name === "UsernameExistsException") {
      return new ServiceResponse("error", err.message)
    }
    return new ServiceResponse("error", "error occurred while User creation.")
  }
}

export async function UpdateUser (userDetails) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    UserAttributes: [
      {
        Name: 'given_name',
        Value: userDetails.given_name
      },
      {
        Name: 'family_name',
        Value: userDetails.family_name
      },
      {
        Name: 'phone_number',
        Value: userDetails.phone_number.includes("+61") ? (userDetails.phone_number) : ('+61' + userDetails.phone_number)
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminUpdateUserAttributesCommand(params))
    //  console.log(data);
    return true;
  } catch (err) {
    console.error(err)
    return false
  }
}

export async function UpdateUserPersonalDetails (userDetails) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })
  let cred = (await Auth.currentSession()).getAccessToken();
  // console.log('cred', cred);
  let params = {
    UserAttributes: [
      {
        Name: 'given_name',
        Value: userDetails.given_name
      },
      {
        Name: 'family_name',
        Value: userDetails.family_name
      },
      {
        Name: 'phone_number',
        Value: userDetails.phone_number.includes("+61") ? (userDetails.phone_number) : ('+61' + userDetails.phone_number)
      },
      {
        Name: 'custom:emcontactName',
        Value: userDetails.emergency_contact_name
      },
      {
        Name: 'custom:ememail',
        Value: userDetails.emergency_contact_email
      },
      {
        Name: 'custom:emphoneNumber',
        Value: userDetails.emphoneNumber.includes("+61") ? (userDetails.emphoneNumber) : ('+61' + userDetails.emphoneNumber)
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id,
    AccessToken: cred.jwtToken
  }
  // console.log('params',  params)

  try {
    const data = await client.send(new UpdateUserAttributesCommand(params))
    //  console.log(data);
    return true;
  } catch (err) {
    console.error(err)
    return false
  }
}

export async function DisableUser (username) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {    
    Username: username,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminDisableUserCommand(params))
    if(data !== null && data !== undefined && data.$metadata !== null && data.$metadata !== undefined) {
      if(data.$metadata.httpStatusCode === 200){
        return new ServiceResponse("success", "User has been Disabled successfully.")
      } else {
        return new ServiceResponse("error", "There has been error and User was not Disabled.")
      }
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

export async function EnableUser (username) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {    
    Username: username,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminEnableUserCommand(params))
    if(data !== null && data !== undefined && data.$metadata !== null && data.$metadata !== undefined) {
      if(data.$metadata.httpStatusCode === 200){
        return new ServiceResponse("success", "User has been Enabled successfully.")
      } else {
        return new ServiceResponse("error", "There has been error and User was not enabled.")
      }
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

export async function GetHseUserRolesByRoleId (roleId) {
  let formattedData = []
  try {
    let filter = {
      id: {
          eq:  roleId 
      },
      isDeleted: {
          eq: false
      }
    };
    let result = await FetchDataWithNextToken(listHseUserRoles, filter, "listHseUserRoles")

    if(result && result.length) {
      formattedData = formatHseUserRole(result);
    }
    else {
      return [];
    }
  } catch (err) {
    console.log('error Listing Qms user GetQmsUserRolesByRleId:', err)
  }
  return formattedData
}

export async function GetHseUserRoles () {
  let formattedData = []
  try {
    let filter = {
      isDeleted: {
          eq: false
      }
    };
    
    let result = await FetchDataWithNextToken(listHseUserRoles, filter, "listHseUserRoles")

    if(result && result.length) {
      formattedData = formatHseUserRole(result);
    }
    else {
      return [];
    }
  } catch (err) {
    console.log('error Listing Qms user GetQmsUserRoles:', err)
  }
  return formattedData
}

function formatHseUserRole(data) {  
  if(data && data.length) {
    let formattedData = data.map(item => {      
        return {
          value: item.id,
          label: item.name,
          name: item.name,
          manageProject: typeof item.manageProject === 'boolean' ? item.manageProject : '',
          manageUser: typeof item.manageUser === 'boolean' ? item.manageUser : '',
          manageConfiguration: typeof item.manageConfiguration === 'boolean' ? item.manageConfiguration : '',
          manageDashboard: typeof item.manageDashboard === 'boolean' ? item.manageDashboard : '',
          manageRegister: typeof item.manageRegister === 'boolean' ? item.manageRegister : '',
          manageIncident: typeof item.manageIncident === 'boolean' ? item.manageIncident : '',
          manageEvent: typeof item.manageEvent === 'boolean' ? item.manageEvent : '',
          manageStky: typeof item.manageStky === 'boolean' ? item.manageStky : '',
          manageTake5: typeof item.manageTake5 === 'boolean' ? item.manageTake5 : '',
          manageJourneyPlanner: typeof item.manageJourneyPlanner === 'boolean' ? item.manageJourneyPlanner : '',
          manageHazardObservation: typeof item.manageHazardObservation === 'boolean' ? item.manageHazardObservation : '',
          manageInspection: typeof item.manageInspection === 'boolean' ? item.manageInspection : '',
          manageEnrollInduction: typeof item.manageEnrollInduction === 'boolean' ? item.manageEnrollInduction : '',
          manageAddInduction: typeof item.manageAddInduction === 'boolean' ? item.manageAddInduction : '',
          manageSWMS: typeof item.manageSWMS === 'boolean' ? item.manageSWMS : '',
          isAvailableForSubcontractor: typeof item.isAvailableForSubcontractor === 'boolean' ? item.isAvailableForSubcontractor : '',
          managePlantCheckin: typeof item.managePlantCheckin === 'boolean' ? item.managePlantCheckin : '',
          manageEquipmentCheckin: typeof item.manageEquipmentCheckin === 'boolean' ? item.manageEquipmentCheckin : '',
          version: item._version
        }
    });
    return formattedData;
  }
  else {
    return null;
  }
}

export async function UpdateUserTranferRequest (userDetails) {
  
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    UserAttributes: [
      {
        Name: 'custom:organisationName',
        Value: userDetails.organisation_name
      },
      {
        Name: 'custom:organisationId',
        Value: userDetails.organisation_id
      },
      {
        Name: 'custom:Roles',
        Value: userDetails.user_role
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminUpdateUserAttributesCommand(params))
    //  console.log(data);
    return true;
  } catch (err) {
    console.error(err)
    return false
  }
}