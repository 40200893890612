import { graphqlOperation,API, DataStore, Storage } from 'aws-amplify'
import {    
    listHseInductionUsers,
    listInductions
} from './../graphql/queries'

import {
  createHseInductionUser,
  updateHseInductionUser
} from './../graphql/mutations'

import { HseInductionUser, Induction } from './../models'

import { FetchDataWithNextToken, getAWSLocalDateInISOStringFormat } from './HseCommonService'

export async function GetHseUserInductionIds(userId) { 
    let formattedData = [];
    try {
        let filter = {
          userId: {
              eq:  userId.toString()  
          },
          isDeleted: {
              eq: false
          }
        };
        // let result = await API.graphql({ query: listHseInductionUsers, variables: { filter: filter}});
        let result = await FetchDataWithNextToken(listHseInductionUsers, filter, "listHseInductionUsers")
        if(result !== undefined) {
          formattedData = formatHseUserInductionIdsResult(result);
        }
        else {
          return [];
        }
      } catch (err) {
        console.log('error Listing HSE user Inductions:', err);
      }
      return formattedData;
  }
  
  function formatHseUserInductionIdsResult(data) {
    if(data && data.length) {
      let formattedData = data.map(item => {      
          return (item.inductionId !== undefined ? item.inductionId : "");
      });
      return formattedData;
    }
    else {
      return [];
    }
  }

export async function DSGetHseUserInductions(userId, isDataStoreOnline=false) { 
    let formattedData = null;
    const filter = {
      isDeleted: {
        eq: false,
      },
      userId : {eq: userId}
    };
    try {
        let result = isDataStoreOnline ? 
                     await DataStore.query(HseInductionUser, c => c.userId('eq', userId) && c.isDeleted('eq', false))
                     : (await FetchDataWithNextToken(listHseInductionUsers, filter, 'listHseInductionUsers'));
        if(result !== undefined) {
          formattedData = DSformatHseUserInductionResult(result, isDataStoreOnline);
        }
        else {
          return null;
        }
      } catch (err) {
        console.log('error Listing HSE user Inductions:', err);
      }
      return formattedData;
  }
  
  function DSformatHseUserInductionResult(data, isDataStoreOnline) {
    if(data !== undefined) {
      let formattedData = Promise.all(data.map( async (item) => {      
        let inductionDetails =  isDataStoreOnline ? await DataStore.query(Induction, c => c.id("eq", item.inductionId) && c.isDeleted('eq', false))  : ( (item.Induction && item.Induction.items && item.Induction.items[0]?.isDeleted === false ) ? ( item.Induction.items) : null  )
        if(inductionDetails && inductionDetails?.length){
          let enrollDateTime = item?.enrollDateTime && getAWSLocalDateInISOStringFormat(item.enrollDateTime)
          let completionDateTime = item?.completionDateTime && getAWSLocalDateInISOStringFormat(item.completionDateTime)
          let expiryDate = item?.expiryDate && getAWSLocalDateInISOStringFormat(item.expiryDate)
          return {
            id: item.id,
            inductionId: item.inductionId,
            userId: item.userId,
            completionStatus: item.completionStatus,
            enrollDateTime: enrollDateTime,
            completionDateTime: completionDateTime,
            expiryDate: expiryDate,
            isDeleted: item.isDeleted,
            inductionName : inductionDetails[0].name,
            version: item._version
          }
        }
        
      })).then(values => {   
        let tempArr = []
        tempArr = values?.filter(function(x) {
          return x !== undefined;
        });     
        return tempArr;
      });
      
      return formattedData;
    }
    else {
      return null;
    }
    return [];
  }

  export async function GetHseUserInductions(userId) { 
    let formattedData = [];
    try {
        let filter = {
          userId: {
              eq:  userId.toString()  
          },
          isDeleted: {
              eq: false
          }
        };
        // let result = await API.graphql({ query: listHseInductionUsers, variables: { filter: filter}});
        let result = await FetchDataWithNextToken(listHseInductionUsers, filter, "listHseInductionUsers")

        if(result !== undefined) {
          formattedData = formatHseUserInductionResult(result);
        }
        else {
          return [];
        }
      } catch (err) {
        console.log('error Listing HSE user Inductions:', err);
      }
      return formattedData;
}
  
function formatHseUserInductionResult(data) {
if(data && data.length) {
    let formattedData = data.map(item => {  
      if(item.Induction.items !== undefined && item.Induction.items.length > 0 && !item.Induction.items[0].isDeleted){
        return {
          id: item.id,
          inductionId: item.inductionId,
          userId: item.userId,
          completionStatus: item.completionStatus,
          completedPercentage: item.completedPercentage,
          enrollDateTime: item.enrollDateTime,
          completionDateTime: item.completionDateTime,
          isDeleted: item.isDeleted,
          name : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).name : 'N/A' ),
          Evaluation : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).Evaluation : 'N/A' ),
          description : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).description : 'N/A' ),
          inductionImage : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).inductionImage : 'N/A' ),
          inductionImagePath : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).inductionImagePath : 'N/A' ),
          videoFile : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).videoFile : 'N/A' ),
          videoFilePath : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).videoFilePath : 'N/A' ),
          courseDeleted : ((item.Induction.items !== undefined && item.Induction.items.length > 0) ? item.Induction.items.find(x => x.id === item.inductionId).isDeleted : 'N/A' ),
          version: item._version
          }
      }    
    });
    formattedData = formattedData.filter(function(x) {
      return x !== undefined;
    });
    return formattedData;
}
else {
    return [];
}
}

export async function ListAllInduction(inductionId) { 
    try {
        let filter = {
            id: {
                eq:  inductionId.toString()  
            },
            isDeleted: {
                eq: false
            }
        };
        // let result = await API.graphql({ query: listInductions, variables: { filter: filter}});
        let result = await FetchDataWithNextToken(listInductions, filter, "listInductions")

        return result;
    } catch (err) {
        console.log('error Listing all Inductions:', err)
    }
}

export async function GetInductionDetail(userId, inductionId) {
  let formattedData = null; 
  try {
    let filter = {
      userId: {
          eq:  userId.toString()  
      },
      inductionId: {
        eq:  inductionId.toString()  
      },
      isDeleted: {
          eq: false
      }
    };
    let result = await FetchDataWithNextToken(listHseInductionUsers, filter, "listHseInductionUsers")
    if(result && result.length) {
      formattedData = formatHseInductionUserDetail(result);
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing HSE user Inductions:', err);
  }
  return formattedData;
}

export async function UpdateHseInductionUser (updateObject) {
  try {
    let toUpdate = {
          ...updateObject
    }
    const updatedInductionUserData = await API.graphql({ query: updateHseInductionUser, variables: {input: toUpdate}});
    return updatedInductionUserData;
  } catch (err) {
      console.log('error updating Induction:', err)
  }
}

export async function GenerateAuthenticatedLink (mediaPath) {
    return await new Promise(resolve => {
        let key =
        mediaPath && mediaPath.length > 0
          ? mediaPath.substring(mediaPath.indexOf('inductionfiles/'))
          : ''
        const result = Storage.get(key)
        resolve(result)
    })
}




export const listInductionsForDDL = /* GraphQL */ `
  query ListInductions(
    $filter: ModelInductionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInductions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name        
      }
      nextToken
      startedAt
    }
  }
`;

export async function ListAllInductionForDDL() { 
  try {
      let filter = {
          isDeleted: {
              eq: false
          }
      };
      // let result = await API.graphql({ query: listInductionsForDDL, variables: { filter: filter}});

      let result = await FetchDataWithNextToken(listInductionsForDDL, filter, "listInductions")


      if(result && result.length) {
        let formattedResult = []
        if(result.length > 0) {
          formattedResult = result.map((item) => {
            return {
              label: item['name'],
              value: item['id']
            }
          })
        }
        
        return formattedResult
      }
      return result;
  } catch (err) {
      console.log('error Listing all Inductions:', err)
  }
}

const formatHseInductionUserDetail = (data) => {
  if (data && data.length) {
    let formattedData = data.map((item) => {
      item.completionDeadline = item.completionDeadline && getAWSLocalDateInISOStringFormat(new Date(item.completionDeadline))
      item.courseValidity = item.Induction && item.Induction.items?.length &&  item.Induction.items[0].courseValidity
      return {
        ...item
      };
    });
    return formattedData;
  } else {
    return null;
  }
}



export async function GetInductionByIDForOnlineUse(inductionId) {
  try {
    let filter = {
      id: {
        eq: inductionId.toString(),
      },
      isDeleted: {
        eq: false,
      },
    };
    let result = await FetchDataWithNextToken(
      listInductions,
      filter,
      "listInductions"
    );
    return result;
  } catch (err) {
    console.log("error Getting induction by id:", err);
  }
}

export async function CreateHseInductionUser (HseInduction) {
  try {
      await API.graphql(
      graphqlOperation(createHseInductionUser, {
        input: HseInduction
      })
    )
    return true;
  } catch (err) {    
    console.log('error creating HSE Induction User:', err)
  }
  return false;
}