import { API, graphqlOperation, DataStore} from 'aws-amplify'
import { listHseOrganisations } from './../graphql/queries'
import { createHseOrganisation } from './../graphql/mutations'

import { HseOrganisation } from './../models'
import { FetchDataWithNextToken } from './HseCommonService';


export async function ListAllOrganisations (isDataStoreOnline = false) {
  const filter = {
    isDeleted : {eq: false}
  };
    try {      
      let result =(isDataStoreOnline) ?  await DataStore.query(HseOrganisation, c => c.isDeleted('eq', false)):
       (await FetchDataWithNextToken(listHseOrganisations, filter, 'listHseOrganisations'));
     
      return result
    } catch (err) {
      console.log('error Listing all HseLicenses:', err)
    }
  }
  
  export async function getAllOrganisationsForDropdown (isDataStoreOnline = false) {
    let result = await ListAllOrganisations(isDataStoreOnline)
    let allOrganisations = []

    if (result !== undefined ) {
      allOrganisations = result.map(item => {
        return {
          label: item['name'],
          value: item['id'],           
        }
      })   
    }

    // console.log(allOrganisations)
    return allOrganisations
  }

  export async function createOrganisation (organisation) {
    try {
      let result = await API.graphql(
        graphqlOperation(createHseOrganisation, {
          input: organisation
        })
      )

      // console.log(result)
      return true
    } catch (err) {
      console.log('error creating HSE organisation:', err)
    }
    return false
  }

  export async function GetHseOrganisationById(orgId, isDataStoreOnline = false) {
    let formattedData = null;
    const filter = {
      id : {eq: orgId},
      isDeleted : {eq: false}
    };
  
    try {
      let result = null

      if(orgId) {      
       result =(isDataStoreOnline) ?  await DataStore.query(
          HseOrganisation,
          c => c.id('eq', orgId) && c.isDeleted('eq', false)
        ): (await FetchDataWithNextToken(listHseOrganisations, filter, 'listHseOrganisations'));

      } 
      
      if (result !== undefined) {
        formattedData = result;
      } else {
        return null;
      }
    } catch (err) {
      console.log("error Getting HSE Organisation:", err);
    }
    return formattedData;
  }