import { DATASTORE_READY, OFFLINE_FILE_STORAGE_STATUS } from "../../constants";

const INIT_STATE = {
  isDatastoreReady:  false,
  isOfflineFileStorageReady: false,
  isDataStoredSyncedOnce: localStorage.getItem('isDataStoredSyncedOnce') ? localStorage.getItem('isDataStoredSyncedOnce') : false,
};

const dataStoreReducer = (state = INIT_STATE, action) => {
  switch (action.type) { 
    case DATASTORE_READY:
      localStorage.setItem('isDataStoredSyncedOnce', action.data);           
      return {
        ...state,
        isDatastoreReady: action.data,
      };
    case OFFLINE_FILE_STORAGE_STATUS:
      return {
        ...state,
        isOfflineFileStorageReady: action.data,
      };
    default:
      return state;
  }
};

export default dataStoreReducer;