import * as React from "react";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useEffect } from "react";
import { Button } from "@material-ui/core";



const AlertDialog = (props) => {
  const { openNetworkAlert } = props
  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

    useEffect(()=>{
        if(openNetworkAlert){
            setOpen(true)
        }
    },[openNetworkAlert])
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Network Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Network connection lost!!
          Application could not be prepared for offline usage.
          </DialogContentText>
          <Button variant="outlined" onClick={handleClose}>
                OK
            </Button>
        </DialogContent>
      </Dialog>
    </>
  );

};



export default AlertDialog;