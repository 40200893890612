import { DATASTORE_READY, OFFLINE_FILE_STORAGE_STATUS } from "../../constants";

export const datastoreStatus = (status) => (dispatch) => {
    dispatch({
      type: DATASTORE_READY,
      data: status,
    });
  };

export const offlineFileStorageStatus = (status) => (dispatch) => {
    dispatch({
      type: OFFLINE_FILE_STORAGE_STATUS,
      data: status,
    });
  };