import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/react-prime-datatable.css';

import {
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";
import { THEMES } from "./constants";
import { setTheme } from "./redux/actions/themeActions";
import { Hub } from "@aws-amplify/core";
import { DataStore, Predicates, syncExpression } from "aws-amplify";
import {
  HseUserCertification,
  HseUserLicense,
  HseUserQualification,
  HseInductionUser,
  Induction,
  HseProjectTake5Assessment,
  HseProjectUserDetails,
  HseProjectSignOnOff,
  HseUserProjectFatigue,
  HseIncidentForm,
  HseEventForm,
  HseUserActionForm,
  HseUserActionImages,
  HseUserInspection,
  HseUserInspectionData,
  HseUserInspectionFiles,
  HseJourneyPlannerForm,
  HseStkyEngagementForm,
  HseApprovalManagement,
  HseUserRole,
  HseUserHazardReports,
  HseReportConfiguration,
  HseSwmsAction,
  HseSwmsWorkflow,
  HseSwms,
  HseFormImage,
  HseTake5AssessmentCrewSignature,
} from "./models";
import { GetTake5AssessmentIdsForProjectIds } from "./services/HseTake5AssessmentService";
import { GetHseUserProjectIds } from "./services/HseProjectService";
import { GetHseUserInductionIds } from "./services/HseInductionService";
import {
  GetHseIncidentFormsIdsForProjectIds,
  GetHseEventFormsIdsForProjectIds,
  GetHseUserActionsIdsByUserId,
  GetHseJourneyPlannerFormIdsForProjectIds,
  GetHseStkyEnagagementFormIdsForProjectIds,
  GetHazardReportIdsForProjectIds,
  GetHseSwmsActionsIdsForUserId,
  GetSwmsForOrgId,
} from "./services/HseUserFormsService";
import { GetHseInspectionFormIdsForProjectIds } from "./services/HseInspectionService";
import Snackbar from "./components/Snackbar";
import { emptyHseFormbase64Image } from "./services/HseUserFormsService";
import * as Constants from "./constants/index";
import { datastoreStatus }  from "./redux/actions/dataStoreAction"

import { clear } from 'idb-keyval';

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import AlertDialog from "./components/controls/AlertDialog"

import createTheme from "./theme";
// import { createTheme } from '@material-ui/core/styles'
import Routes from "./routes/Routes";

import {
  setCurrentUserDetails,
  setNetworkOnlineStatus,
  setUserAuthGroups,
} from "./redux/actions/sessionDataActions";

import {
  base64ToFile,
  UploadFileInBackground,
  DeleteFileFromS3Storage,
} from "./services/S3StorageService";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const loaded = useRef(false);
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer);
  const [authState, setAuthState] = useState("");
  const [user, setUser] = useState();
  const networkStatus = useSelector((state) => state.sessionData.networkOnline);

  const userDetails = useSelector((state) => state.sessionData.userDetails);
  const [dataStoreOnline, setDataStoreOnline] = useState(false);
  const [subscriptionsSetup, setSubscriptionsSetup] = useState(false);
  const [syncDataStore, setSyncDatastore] = useState(true)
  const isDatastoreReady = useSelector((state) => state.dataStoreReducer.isDatastoreReady);
  const isDataStoredSyncedOnce = useSelector((state) => state.dataStoreReducer.isDataStoredSyncedOnce);

  const listPredicate = (c, list, field) => {
    return c.or((c) =>
      list?.reduce((_c, operand) => _c[field]("eq", operand), c)
    );
  };


  const loadScript = (src, position, id) => {
    return new Promise(function(resolve, reject) {
      if (!position) {
        return;
      }
      const script = document.createElement("script");
      script.setAttribute("async", "");
      script.setAttribute("id", id);
      script.src = src;
      position.appendChild(script);
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
    });
  };
  const loadMap = () => {
    if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector("#google-maps")) {
        loadScript(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyA-JQOti4LB3mZ0Df2DfheCVoPTlU3WLLs&libraries=places",
          document.querySelector("head"),
          "google-maps"
        );
      }
      loaded.current = true;
    }
  };

  // async function getSyncExpression() {
  //   let userId = user !== undefined ? user.username : "";
  //   let orgId =
  //     user && user.attributes ? user.attributes["custom:organisationId"] : "0";
  //   let projectIds;
  //   let take5AssessmentIds;
  //   let IncidentFormIds;
  //   let EventFormIds;
  //   let UserActionsIds;
  //   let InspectionFormIds;
  //   let JourneyFormIds;
  //   let StkyEngagementIds;
  //   let userHazardsIds;
  //   let swmsIds;

  //   if (networkStatus) {
  //     // projectIds = await GetHseUserProjectIds(userId)
  //     // take5AssessmentIds = await GetTake5AssessmentIdsForProjectIds(projectIds)
  //     // IncidentFormIds = await GetHseIncidentFormsIdsForProjectIds(projectIds)
  //     // EventFormIds = await GetHseEventFormsIdsForProjectIds(projectIds)
  //     // UserActionsIds = await GetHseUserActionsIdsByUserId(userId)
  //     // InspectionFormIds = await GetHseInspectionFormIdsForProjectIds(projectIds)
  //     // JourneyFormIds = await GetHseJourneyPlannerFormIdsForProjectIds(projectIds)
  //     // StkyEngagementIds = await GetHseStkyEnagagementFormIdsForProjectIds(projectIds)

  //     await Promise.all([
  //       GetHseUserProjectIds(userId),
  //       GetTake5AssessmentIdsForProjectIds(projectIds),
  //       GetHseIncidentFormsIdsForProjectIds(projectIds),
  //       GetHseEventFormsIdsForProjectIds(projectIds),
  //       GetHseUserActionsIdsByUserId(userId),
  //       GetHseInspectionFormIdsForProjectIds(projectIds),
  //       GetHseJourneyPlannerFormIdsForProjectIds(projectIds),
  //       GetHseStkyEnagagementFormIdsForProjectIds(projectIds),
  //       GetHazardReportIdsForProjectIds(projectIds),
  //       GetSwmsForOrgId(orgId),
  //     ]).then(async (arr) => {
  //       projectIds = arr[0];
  //       take5AssessmentIds = arr[1];
  //       IncidentFormIds = arr[2];
  //       EventFormIds = arr[3];
  //       UserActionsIds = arr[4];
  //       InspectionFormIds = arr[5];
  //       JourneyFormIds = arr[6];
  //       StkyEngagementIds = arr[7];
  //       userHazardsIds = arr[8];
  //       swmsIds = arr[9];
  //     });
  //   }

  //   const syncExp = [
  //     syncExpression(HseUserCertification, () => {
  //       return (HseUserCertification) =>
  //         HseUserCertification.and((c) =>
  //           c.userId("eq", userId).isDeleted("eq", false)
  //         );
  //       // return HseUserCertification => HseUserCertification.userId('eq', ( user !== undefined ? user.username : ''));
  //     }),
  //     syncExpression(HseUserLicense, () => {
  //       return (HseUserLicense) => HseUserLicense.userId("eq", userId);
  //     }),
  //     syncExpression(HseUserQualification, () => {
  //       return (HseUserQualification) =>
  //         HseUserQualification.userId("eq", userId);
  //     }),
  //     syncExpression(HseInductionUser, () => {
  //       return (HseInductionUser) => HseInductionUser.userId("eq", userId);
  //     }),
  //     syncExpression(Induction, async () => {
  //       if (networkStatus) {
  //         let result = await GetHseUserInductionIds(userId);
  //         return (c) => listPredicate(c, result, "id");
  //       } else {
  //         return (Induction) => Induction.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseProjectUserDetails, async () => {
  //       // return HseProjectUserDetails => HseProjectUserDetails.isDeleted('eq', false)
  //       return (c) => listPredicate(c, projectIds, "projectId");
  //       // if(networkStatus) {
  //       //   // let result = await GetHseUserProjectIds(userId)
  //       //   // return (c) => listPredicate(c, projectIds, 'projectId') || c.userId == userDetails.id;
  //       //   return (c) => c.userId('eq',userId);
  //       // } else {
  //       //   return HseProjectUserDetails => HseProjectUserDetails.userId('eq',userId).isDeleted('eq', false);
  //       // }
  //     }),
  //     syncExpression(HseProjectSignOnOff, async () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, projectIds, "projectId");
  //       } else {
  //         return (HseProjectSignOnOff) =>
  //           HseProjectSignOnOff.signOnByUserId("eq", userId).isDeleted(
  //             "eq",
  //             false
  //           );
  //       }
  //     }),
  //     syncExpression(HseUserProjectFatigue, async () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, projectIds, "projectId");
  //       } else {
  //         return (HseUserProjectFatigue) =>
  //           HseUserProjectFatigue.userId("eq", userId).isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseProjectTake5Assessment, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, projectIds, "projectId");
  //         // return (c) => listPredicate(c, take5AssessmentIds, 'id');
  //       } else {
  //         return (HseProjectTake5Assessment) =>
  //           HseProjectTake5Assessment.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseUserHazardReports, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, projectIds, "projectId");
  //         // return (c) => listPredicate(c, userHazardsIds, 'id');
  //       } else {
  //         return (HseUserHazardReports) =>
  //           HseUserHazardReports.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseIncidentForm, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, IncidentFormIds, "id");
  //       } else {
  //         return (HseIncidentForm) => HseIncidentForm.isDeleted("eq", false);
  //       }
  //     }),

  //     syncExpression(HseEventForm, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, EventFormIds, "id");
  //       } else {
  //         return (HseEventForm) => HseEventForm.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseUserActionForm, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, UserActionsIds, "id");
  //         // return (c) => listPredicate(c, projectIds, 'projectNumber');
  //       } else {
  //         return (HseUserActionForm) =>
  //           HseUserActionForm.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseUserActionImages, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, UserActionsIds, "actionId");
  //         //return c => c.id('eq', '00000000');
  //       } else {
  //         return (HseUserActionImages) =>
  //           HseUserActionImages.isDeleted("eq", false);
  //       }
  //     }),
  //     syncExpression(HseUserInspection, () => {
  //       //  if(networkStatus) {
  //       //  return (c) => listPredicate(c, InspectionFormIds, 'id');
  //       //} else {
  //       return (HseUserInspection) => HseUserInspection.isDeleted("eq", false);
  //       //}
  //     }),
  //     syncExpression(HseUserInspectionData, () => {
  //       // if(networkStatus) {
  //       //   return (c) => listPredicate(c, InspectionFormIds, 'userInspectionId');
  //       // } else {
  //       return (HseUserInspectionData) =>
  //         HseUserInspectionData.isDeleted("eq", false);
  //       // }
  //     }),
  //     syncExpression(HseUserInspectionFiles, () => {
  //       //if(networkStatus) {
  //       //  return (c) => listPredicate(c, InspectionFormIds, 'userInspectionId');
  //       //return c => c.id('eq', '00000000');
  //       // } else {
  //       return (HseUserInspectionFiles) =>
  //         HseUserInspectionFiles.isDeleted("eq", false);
  //       // }
  //     }),
  //     syncExpression(HseJourneyPlannerForm, () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, JourneyFormIds, "id");
  //       } else {
  //         return (HseJourneyPlannerForm) =>
  //           HseJourneyPlannerForm.isDeleted("eq", false);
  //       }
  //     }),

  //     syncExpression(HseStkyEngagementForm, () => {
  //       // if (networkStatus) {
  //       //   // return (c) => listPredicate(c, StkyEngagementIds, 'id');

  //       //   return (c) => listPredicate(c, projectIds, "projectId");
  //       // } else {
  //       return (HseStkyEngagementForm) =>
  //         HseStkyEngagementForm.isDeleted("eq", false);
  //       // }
  //     }),

  //     syncExpression(HseApprovalManagement, async () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, projectIds, "projectId");
  //       } else {
  //         return (HseApprovalManagement) =>
  //           HseApprovalManagement.isDeleted("eq", false);
  //       }
  //     }),

  //     syncExpression(HseReportConfiguration, async () => {
  //       return (HseApprovalManagement) =>
  //         HseApprovalManagement.id("eq", "00000000");
  //     }),

  //     syncExpression(HseSwmsAction, async () => {
  //       return (HseSwmsAction) => HseSwmsAction.id("eq", "00000000");
  //     }),

  //     syncExpression(HseSwmsWorkflow, async () => {
  //       return (HseSwmsWorkflow) => HseSwmsWorkflow.id("eq", "00000000");
  //     }),

  //     syncExpression(HseSwms, async () => {
  //       if (networkStatus) {
  //         return (c) => listPredicate(c, swmsIds, "id");
  //       } else {
  //         return (HseSwms) => HseSwms.isDeleted("eq", false);
  //       }
  //     }),

  //     syncExpression(HseTake5AssessmentCrewSignature, async () => {
  //       if (networkStatus) {
  //         // return (c) => listPredicate(c, take5AssessmentIds, 'take5id');
  //         return (HseTake5AssessmentCrewSignature) =>
  //           HseTake5AssessmentCrewSignature.id("eq", "00000000");
  //       } else {
  //         return (HseTake5AssessmentCrewSignature) =>
  //           HseTake5AssessmentCrewSignature.isDeleted("eq", false);
  //       }
  //     }),
  //   ];

  //   return syncExp;
  // }

  async function newSyncExpression() {
    let NumberOfDaysWorthDataToSync = 715;
    let FromDateToSync = (new Date(Date.now() - NumberOfDaysWorthDataToSync * 24 * 60 * 60 * 1000))
    let userId = user !== undefined ? user.username : "";
    let orgId = user && user.attributes ? user.attributes["custom:organisationId"] : "0";
    let projectIds;
    let IncidentFormIds;
    let EventFormIds;
    let UserActionsIds;
    let JourneyFormIds;
    let swmsIds;

    if (networkStatus) { 
      await Promise.all([
        GetHseUserProjectIds(userId),
        GetHseIncidentFormsIdsForProjectIds(projectIds),
        GetHseEventFormsIdsForProjectIds(projectIds),
        GetHseUserActionsIdsByUserId(userId),
        GetHseJourneyPlannerFormIdsForProjectIds(projectIds),
        GetSwmsForOrgId(orgId),
      ]).then(async (arr) => {
        projectIds = arr[0];
        IncidentFormIds = arr[1];
        EventFormIds = arr[2];
        UserActionsIds = arr[3];
        JourneyFormIds = arr[4];
        swmsIds = arr[5];
      })
    }

    const syncExp = [
      syncExpression(HseUserCertification, () => {
         return (HseUserCertification) => HseUserCertification.userId("eq", userId);
      }),      
      syncExpression(HseUserLicense, () => {
        return (HseUserLicense) => HseUserLicense.userId("eq", userId);
      }),
      syncExpression(HseUserQualification, () => {
        return (HseUserQualification) =>
          HseUserQualification.userId("eq", userId);
      }),
      syncExpression(HseInductionUser, () => {
        return (HseInductionUser) => HseInductionUser.userId("eq", userId);
      }),
      syncExpression(Induction, async () => {
        if (networkStatus) {
          let result = await GetHseUserInductionIds(userId);
          return (c) => listPredicate(c, result, "id");
        } else {
          return (Induction) => Induction.isDeleted("eq", false);
        }
      }),
      syncExpression(HseProjectUserDetails, async () => {
        // return (c) => listPredicate(c, projectIds, "projectId");
        return (c) => c.and(c => [c.archivedProject('eq', false), listPredicate(c, projectIds, "projectId")]);
      }),
      syncExpression(HseProjectSignOnOff, async () => {
        if (networkStatus) {
          return (c) => c.and( c => [c.signOnDateTimeAt('ge', (FromDateToSync).toISOString()), listPredicate(c, projectIds, "projectId")]);
          // return (c) => listPredicate(c, projectIds, "projectId");
        } else {
          return (HseProjectSignOnOff) => HseProjectSignOnOff.signOnByUserId("eq", userId).isDeleted("eq",false);
        }
      }),
      syncExpression(HseUserProjectFatigue, async () => {
        if (networkStatus) {
          return (c) => c.and( c => [c.createdAt('ge', (FromDateToSync).toISOString()), listPredicate(c, projectIds, "projectId")]);
          // return (c) => listPredicate(c, projectIds, "projectId");
        } else {
          return (HseUserProjectFatigue) => HseUserProjectFatigue.userId("eq", userId).isDeleted("eq", false);
        }
      }),
      syncExpression(HseProjectTake5Assessment, () => {
        if (networkStatus) {
          return (c) => c.and( c => [c.createdAt('ge', (FromDateToSync).toISOString()), listPredicate(c, projectIds, "projectId")]);
          // return (c) => listPredicate(c, projectIds, "projectId");
          // return (c) => listPredicate(c, take5AssessmentIds, 'id');
        } else {
          return (HseProjectTake5Assessment) =>
            HseProjectTake5Assessment.isDeleted("eq", false);
        }
      }),
      syncExpression(HseUserHazardReports, () => {
        if (networkStatus) {
          return (c) => c.and( c => [c.reportedDate('ge', (FromDateToSync).toISOString()), listPredicate(c, projectIds, "projectId")]);
          // return (c) => listPredicate(c, projectIds, "projectId");
        } else {
          return (HseUserHazardReports) =>
            HseUserHazardReports.isDeleted("eq", false);
        }
      }),
      syncExpression(HseIncidentForm, () => {
        if (networkStatus) {
          // return (IncidentFormIds && IncidentFormIds.length) ? (c) => listPredicate(c, IncidentFormIds, "id") : ((HseIncidentForm) => HseIncidentForm.id("eq", "0000000"));
        //  return (IncidentFormIds && IncidentFormIds.length) ? 
         // (c) => c.and( c => [c.reportedDate('ge', (FromDateToSync).toISOString()), listPredicate(c, IncidentFormIds, "id")])
        //  : ((HseIncidentForm) => HseIncidentForm.id("eq", "0000000"));
      //  } else {
          return (HseIncidentForm) => HseIncidentForm.isDeleted("eq", false);
        }
      }),
      syncExpression(HseEventForm, () => {
        if (networkStatus) {
          // return (EventFormIds && EventFormIds.length) ? (c) => listPredicate(c, EventFormIds, "id") : ((HseEventForm) => HseEventForm.id("eq", "0000000"));
          return (EventFormIds && EventFormIds.length) ? 
          (c) => c.and( c => [c.reportedDate('ge', (FromDateToSync).toISOString()), listPredicate(c, EventFormIds, "id")])
          : ((HseEventForm) => HseEventForm.id("eq", "0000000"));
        } else {
          return (HseEventForm) => HseEventForm.isDeleted("eq", false);
        }
      }),
      syncExpression(HseUserActionForm, () => {
        if (networkStatus) {
          // return (UserActionsIds && UserActionsIds.length) ? (c) => listPredicate(c, UserActionsIds, "id") : ((HseUserActionForm) => HseUserActionForm.id("eq", "0000000"));
          return (UserActionsIds && UserActionsIds.length) ? 
          (c) => c.and( c => [c.createdAt('ge', (FromDateToSync).toISOString()), listPredicate(c, UserActionsIds, "id")])
          : ((HseUserActionForm) => HseUserActionForm.id("eq", "0000000"));
        } else {
          return (HseUserActionForm) => HseUserActionForm.isDeleted("eq", false);
        }
      }),
      syncExpression(HseUserActionImages, () => {
        if (networkStatus) {
          // return (c) => (UserActionsIds && UserActionsIds.length) ? listPredicate(c, UserActionsIds, "actionId") : ((HseUserActionImages) => HseUserActionImages.id("eq", "000000"));
          return (c) => (UserActionsIds && UserActionsIds.length) ? 
          (c) => c.and( c => [c.createdAt('ge', (FromDateToSync).toISOString()), listPredicate(c, UserActionsIds, "actionId")])
          : ((HseUserActionImages) => HseUserActionImages.id("eq", "000000"));
        } else {
          return (HseUserActionImages) => HseUserActionImages.isDeleted("eq", false);
        }
      }),
      syncExpression(HseUserInspection, () => {
        // return (HseUserInspection) => HseUserInspection.isDeleted("eq", false);
        return (HseUserInspection) => HseUserInspection.and(c => [c.createdAt('ge', (FromDateToSync).toISOString()), c.isDeleted("eq", false)]) ;
      }),
      syncExpression(HseUserInspectionData, () => {
        // return (HseUserInspectionData) => HseUserInspectionData.isDeleted("eq", false);
        return (HseUserInspectionData) => HseUserInspectionData.and(c => [c.createdAt('ge', (FromDateToSync).toISOString()), c.isDeleted("eq", false)]) ;
      }),
      syncExpression(HseUserInspectionFiles, () => {
        // return (HseUserInspectionFiles) => HseUserInspectionFiles.isDeleted("eq", false);
        return (HseUserInspectionFiles) => HseUserInspectionFiles.and(c => [c.createdAt('ge', (FromDateToSync).toISOString()), c.isDeleted("eq", false)]) ;

      }),
      syncExpression(HseJourneyPlannerForm, () => {
        if (networkStatus) {
          // return (JourneyFormIds && JourneyFormIds.length) ? (c) => listPredicate(c, JourneyFormIds, "id") : ((HseJourneyPlannerForm) => HseJourneyPlannerForm.id("eq", "00000"));
          return (JourneyFormIds && JourneyFormIds.length) ? 
          (c) => c.and( c => [c.createdAt('ge', (FromDateToSync).toISOString()), listPredicate(c, JourneyFormIds, "id")])
          : ((HseJourneyPlannerForm) => HseJourneyPlannerForm.id("eq", "00000"));
        } else {
          return (HseJourneyPlannerForm) => HseJourneyPlannerForm.isDeleted("eq", false);
        }
      }),
      syncExpression(HseStkyEngagementForm, () => {
        // return (HseStkyEngagementForm) => HseStkyEngagementForm.isDeleted("eq", false);
        return (HseStkyEngagementForm) => HseStkyEngagementForm.and(c => [c.reportedDate('ge', (FromDateToSync).toISOString()), c.isDeleted("eq", false)]) ;

      }),
      syncExpression(HseApprovalManagement, async () => {
        if (networkStatus) {
          return (projectIds && projectIds.length) ? (c) => listPredicate(c, projectIds, "projectId") : ((HseApprovalManagement) => HseApprovalManagement.id("eq", "00000"));
        } else {
          return (HseApprovalManagement) => HseApprovalManagement.isDeleted("eq", false);
        }
      }),
      syncExpression(HseReportConfiguration, async () => {
        return (HseReportConfiguration) => HseReportConfiguration.id("eq", "00000000");
      }),
      syncExpression(HseSwmsAction, async () => {
        return (HseSwmsAction) => HseSwmsAction.id("eq", "00000000");
      }),

      syncExpression(HseSwmsWorkflow, async () => {
        return (HseSwmsWorkflow) => HseSwmsWorkflow.id("eq", "00000000");
      }),
      syncExpression(HseSwms, async () => {
        if (networkStatus) {
         return (HseSwms) => HseSwms.isDeleted("eq", false);
        } else {
          return (HseSwms) => HseSwms.isDeleted("eq", false);
        }
      }),

      syncExpression(HseTake5AssessmentCrewSignature, async () => {
        if (networkStatus) {
          return (HseTake5AssessmentCrewSignature) => HseTake5AssessmentCrewSignature.id("eq", "00000000");
        } else {
          return (HseTake5AssessmentCrewSignature) =>
            HseTake5AssessmentCrewSignature.isDeleted("eq", false);
        }
      }),
    ]

    return syncExp;
  }

  const listener = async (hubData) => {
    const { event, data } = hubData.payload;
    // console.log(event );

    if (event === "modelSynced") {
      // console.log('Model Synced : ', data.model.name)
    } else if (event === "syncQueriesReady") {
      //  console.log('setup Subscriptions' );
      setSubscriptionsSetup(true);
    } else if (event === "ready") {
       setDataStoreOnline(true);
      console.log("Datastore Ready...");
      setSubscriptionsSetup(true);
      dispatch(datastoreStatus(true));
      // const _data = await DataStore.query(Induction);
      // console.log(_data);
    } else if (event === "networkStatus") {
      // console.log('set user data in redux from hub')
      dispatch(setNetworkOnlineStatus(data.active));
      dispatch(setCurrentUserDetails(data.active));
      // if(data.active ){
      //   changeSync()
      // }
      console.log(`User has a network connection: ${data.active}`);
    } else if (event === "outboxMutationProcessed") {
      //console.log('outboxMutationProcessed reached : ', data)
      // console.log("hubdata : ", hubData);

      try {
        // console.log('data.element : ', data.element)
        if (data.element._deleted == true) {
          //If item is getting deleted them remove from the S3 bucket.
          await DeleteFileFromS3Storage(data.element.imagePath);
        }
        //Upload file to S3 if there is file attached to the model
        else if (
          data.element._version !== undefined &&
          data.element._version === 1 &&
          ((data.element.CertImage && data.element.CertImage !== "") ||
            (data.element.attachedImage &&
              data.element.attachedImage !== "")) &&
          data.element.fileBase64 !== ""
        ) {
          let fileToUpload =
            data.element.CertImage && data.element.CertImage !== ""
              ? data.element.CertImage
              : data.element.attachedImage && data.element.attachedImage !== ""
              ? data.element.attachedImage
              : "tempfile";
          //console.log('trying to upload image to S3 : ' + fileToUpload)

          const key = data.element.imagePath.substring(
            data.element.imagePath.indexOf("public/") + 7
          );
          // console.log('key is : ' + key)

          // let tFile = null
          await base64ToFile(data.element.fileBase64, fileToUpload).then(
            async (tFile) => {
              let res = await UploadFileInBackground(tFile, key);
              //  console.log('result from file upload ', res);
              if (res) {
                // hubData.payload.data.element = {...data.element, fileBase64 : ""};
                let modelUpdated = hubData?.payload?.data?.model?.name;
                console.log("model updated :", hubData.payload.data.model.name);
                await emptyHseFormbase64Image(data.element.id, modelUpdated);

                // console.log('data.element after update, ' , data.element);
              }
            }
          );
        }
      } catch (err) {
        console.log("error running outboxMutationEnqueued: ", err);
        return false;
      }
    }
  };

  const authlistener = (data) => {
    if (data.payload.event === "signOut") {
      DataStore.clear();
      localStorage.removeItem('isDataStoredSyncedOnce');   
      clear();
    }
  };

  async function changeSync() {
    // console.log('Datastore : Restart Process Starting')
    await DataStore.stop();
    await DataStore.start();
  }

  // Imp Note: DO NOT DELETE below useEffect[This is to fetch latest role data as on when HseUserRole tables gets updated from Admin]
  useEffect(() => {
    let subscription;
    if (authState === "signedin") {
      subscription = DataStore.observe(HseUserRole).subscribe(() => {
        dispatch(setCurrentUserDetails(true));
      });
    }
    return () => subscription?.unsubscribe();
  }, [subscriptionsSetup]);

  React.useEffect(async () => {
    loadMap();
    Hub.listen("datastore", listener);
    Hub.listen("auth", authlistener);

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      // console.log(authData);
      //console.log(nextAuthState);

      if (nextAuthState === "signedin") {
        if (
          authData !== undefined &&
          authData.signInUserSession != undefined &&
          authData.signInUserSession.accessToken != undefined &&
          authData.signInUserSession.accessToken.payload != undefined
        ) {
          let authGroups =
            authData.signInUserSession.accessToken.payload["cognito:groups"];
          // console.log(authGroups)
          dispatch(setUserAuthGroups(authGroups));
        }
      }
    });
  }, []);

  useEffect(async () => {
    if (authState !== "signin" && authState === "signedin") {
      // DataStore.configure({ syncExpressions: await getSyncExpression(), syncPageSize: 5, fullSyncInterval: 10 });
      const syncExp = await newSyncExpression()
      if(syncExp) {
        DataStore.configure({
          syncExpressions: syncExp,
          fullSyncInterval: 10,
        });
      }
      changeSync();
    }
  }, [user,networkStatus]);

  return (
    <React.Fragment>
      <Snackbar />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              {authState === AuthState.SignedIn && user ? (
                userDetails?.id ? (
                  <div>
                    {/* {console.log("userDetails", userDetails)} */}
                    <Routes />
                  </div>
                ) : (
                  <div className="loader-body">
                    <div className="loader">
                      <div className="loader__bar"></div>
                      <div className="loader__bar"></div>
                      <div className="loader__bar"></div>
                      <div className="loader__bar"></div>
                      <div className="loader__bar"></div>
                      <div className="loader__ball"></div>
                    </div>
                    <div className="line-1 anim-typewriter">
                      Making your app ready for offline use
                    </div>
                  </div>
                )
              ) : (
                <AmplifyAuthenticator>
                  <AmplifySignIn
                    slot="sign-in"
                    hideSignUp
                    usernameAlias="email"
                  ></AmplifySignIn>
                </AmplifyAuthenticator>
              )}
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
      {!networkStatus && <AlertDialog  openNetworkAlert={(!networkStatus && !isDataStoredSyncedOnce)}/>}
    </React.Fragment>
  );
}

export default App;
