import { API, graphqlOperation, DataStore } from 'aws-amplify'

import { listHseProjectUserDetails, listHseProjects, listHseProjectItemDetails, getHseProject, listHseProjectSignOnOffs, hseProjectSignOnOffByDataId } from '../graphql/queries';

import { HseProjectUserDetails, HseProject, HseUserRole, HseProjectItemDetail, HseProjectSignOnOff } from './../models'

import { createHseProjectUserDetails } from '../graphql/mutations';

import { FetchDataWithNextToken } from './HseCommonService'


let filteredArrayLength = 0;



export async function GetHseUserProjectsCount(userId, isDataStoreOnline = false) {
  let count = 0;
  const filter = {
    isDeleted: {
      eq: false,
    },
    userId : {eq: userId}
  };
  try {
    let countResult =  (isDataStoreOnline) ? await DataStore.query(
      HseProjectUserDetails, c => c.userId('eq', userId) && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));
    
    if(countResult !== undefined) {
      count = countResult.length;
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error Listing HSE Project:', err);
  }
  return count;
}

export async function GetHseUserProjectIds(userId) { 
  let formattedData = [];
  try {
      let filter = {
        userId: {
            eq:  userId.toString()  
        },
        isDeleted: {
            eq: false
        }
      };
      // let result = await API.graphql({ query: listHseProjectUserDetails, variables: { filter: filter}});

      let result = await FetchDataWithNextToken(listHseProjectUserDetails, filter, "listHseProjectUserDetails")

      if(result) {
        // formattedData = formatHseUserInductionIdsResult(result.data);
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.projectId !== undefined ? item.projectId : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE user Project Ids:', err);
    }
    return formattedData;
}

export async function GetHseProjectList() { 
  let formattedData = null;
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      let result = await API.graphql({ query: listHseProjects, variables: { filter: filter}});
      if(result !== undefined) {
        if(result.data.listHseProjects !== undefined && result.data.listHseProjects.items !== undefined) {
          formattedData = result.data.listHseProjects.items
        }
      }
      else {
        return null;
      }
    } catch (err) {
      console.log('error Listing HSE user Project Ids:', err);
    }
    return formattedData;
}


export async function GetHseProjectDetails(projectId, isDataStoreOnline = false , includeDeleted=false) {
  let formattedData = null;
  const filter = {
    id : {eq: projectId},
    isDeleted : {eq: false}
  };

  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(
      HseProject,
      c => c.id('eq', projectId) && c.or( c => [c.isDeleted('eq', false), c.isDeleted('eq', includeDeleted)])
    ):  await API.graphql(graphqlOperation( getHseProject, { id: projectId} ));
  
    if(isDataStoreOnline) {
      formattedData = result && result.length && result[0]
    } else {
      formattedData = result && result.data && result.data.getHseProject
    }
  } catch (err) {
    console.log('error getting Project details:', err);
  }
  return formattedData;
}

export async function GetHseUserProjectListDetails (userId, params, isDataStoreOnline=false) {
  let formattedData = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    userId : {eq: userId},
  };
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(
      HseProjectUserDetails,
      c => c.userId('eq', userId) && c.isDeleted('eq', false), params
    )
 : (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));

    // console.log(result);

    if(result !== undefined) {
      formattedData = await formatHseUserProjectDetailsResult(result,isDataStoreOnline);
  return formattedData;

    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error Listing HSE Project:', err);
  }
  return formattedData;
}

function paginate(array, page_number, page_size, searchText) {
  let filteredArray = array.filter( f => f.projectName.toLowerCase().includes(searchText.toLowerCase()) || f.projectNumber.toLowerCase().includes(searchText.toLowerCase()))
  // console.log(filteredArray);
  filteredArrayLength = filteredArray !== undefined ? filteredArray.length : 0;
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return filteredArray.slice((page_number - 1) * page_size, page_number * page_size);
}

export async function SearchHseUserProjectDetails (userId, searchTerm, params, isDataStoreOnline = false) {
  let formattedData = null;
  let finalSearchedResult = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    userId : {eq: userId}
  };
  try {

let result =  (isDataStoreOnline) ? (await DataStore.query(HseProjectUserDetails, c => c.userId('eq', userId) && c.isDeleted('eq', false))): (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));

    // console.log(result);

    if(result !== undefined) {
      formattedData = await formatHseUserProjectDetailsResult(result, isDataStoreOnline);
      // console.log(formattedData);
      finalSearchedResult = paginate(formattedData, params.page, params.limit, searchTerm)     
      // console.log(finalSearchedResult);
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error Listing HSE Project:', err);
  }
  return {finalResult: finalSearchedResult !== undefined ? finalSearchedResult : [], totalRecords : filteredArrayLength};
}

async function formatHseUserProjectDetailsResult(data, isDataStoreOnline=false ) {
  
  if(data !== undefined) {
    let formattedData = Promise.all(data.map(async (item) => {    
      let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c => c.id('eq', item.projectId)) :  ( (item.project && item.project.items ) ? ( item.project.items) : null  )
      let userRole = (isDataStoreOnline)  ? await DataStore.query(HseUserRole, c => c.id("eq", item.roleId)) :  ( (item.userRole && item.userRole.items ) ? ( item.userRole.items) : null  )
        return {
          id: item.id,
          projectId: item.projectId,
          userId: item.userId,
          roleId: item.roleId,
          projectName: ((projectDetails !== undefined && projectDetails.length > 0) ? projectDetails[0].projectName : 'N/A' ),
          projectNumber: ((projectDetails !== undefined && projectDetails.length > 0) ? projectDetails[0].projectNumber : 'N/A' ) ,
          projectUrl: ((projectDetails !== undefined && projectDetails.length > 0) ? projectDetails[0].projectUrl : 'N/A' ) ,
          State: ((projectDetails !== undefined && projectDetails.length > 0) ? projectDetails[0].State : 'N/A' ),
          userRole: ((userRole !== undefined && userRole.length > 0) ? userRole[0].name : 'N/A' )  
        }
    })).then(values => {             
      return values;
    });
    return formattedData;
  }
  else {
    return null;
  }
}



export async function GetHseProjectUserList(projectId, isDataStoreOnline=false) {
  let formattedData = null
  const filter = {
    isDeleted: {
      eq: false,
    },
    projectId : {eq: projectId}
  };
  try {
    let result = (isDataStoreOnline) ? 
    (await DataStore.query(HseProjectUserDetails, c => c.projectId('eq', projectId) && c.isDeleted('eq', false)))
 : (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));
if (result !== undefined) {
formattedData = await formatHseProjectUserList(result, isDataStoreOnline)
return formattedData
} else {
return null
}
  } catch (err) {
    console.log('error Listing HSE user Project details:', err)
  }
}

export async function GetHseProjectUserDetailsByUserId(userId, isDataStoreOnline = false) {
  let formattedData = null
  const filter =
   { isDeleted: { eq: false},
   userId : {eq: userId}
  };
  try {
    let result = (isDataStoreOnline) ? 
    (await DataStore.query(HseProjectUserDetails, c => c.userId('eq', userId) && c.isDeleted('eq', false)))
 : (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));

    if(result !== undefined) {
      formattedData = formatHseProjectUserList(result,isDataStoreOnline);
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error Listing HSE user Project details at GetHseProjectUserDetailsByUserId:', err)
  }
  return formattedData
}

function formatHseProjectUserList(data, isDataStoreOnline=false) {
  if(data !== undefined ) {
    let formattedData = Promise.all(data?.map(async (item) => {  
      // console.log("item",item);
         let userRole = isDataStoreOnline ? await DataStore.query(HseUserRole, c => c.id("eq", item.roleId))  : ( (item.project && item.project.items ) ? ( item.project.items) : null )
        return {
          id: item.id,
          userId: item.userId,
          roleName:((userRole !== undefined && userRole.length > 0) ? userRole[0].name : 'N/A' ),
          version: item._version,
          userName: item.userName
        }
    })).then(values => {             
      return values;
    });
    return formattedData;
  }
  else {
    return null;
  }
}

export async function GetHseUserProjectDetails (projectId, userId, isDataStoreOnline = false) {
  let formattedData = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    projectId : {eq: projectId},
    userId : {eq: userId}
  };
  
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(
      HseProjectUserDetails,
      c => c.userId('eq', userId) && c.projectId('eq',projectId) && c.isDeleted('eq', false)
    )
 : (await FetchDataWithNextToken(listHseProjectUserDetails, filter, 'listHseProjectUserDetails'));

    // console.log(result);

    if(result !== undefined) {
      formattedData = formatHseUserProjectDetailsResult(result,isDataStoreOnline);
      // console.log(formattedData);
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error Listing HSE Project:', err);
  }
  return formattedData;
}




export async function GetHseProjectByIdForOnlineUser(projectId) {
  let formattedData = []
  try {
    let result = await API.graphql({
      query: getHseProject,
      variables: { id: projectId }
    })

    console.log(result)
    if(result && result.data && result.data.getHseProject ) {
      formattedData = result.data.getHseProject;
    }
    else {
      return [];
    }
  } catch (err) {
    console.log('error Getting HSE Project:', err)
  }
  return formattedData;
}

// function formatHseProjectResult(data) {
//   if(data.getHseProject !== undefined) {
//     let formattedData =  {      
//         "id": data.getHseProject.id,
//         "State": data.getHseProject.State,
//         "projectName": data.getHseProject.projectName,
//         "projectNumber": data.getHseProject.projectNumber,
//         "projectUrl": data.getHseProject.projectUrl,
//         "version" : data.getHseProject._version
//       }
//     return formattedData;
//   }
//   else {
//     return null;
//   }
// }





// export async function GetHseUserRoles () {
//   let formattedData = null
//   try {
//     let result = await API.graphql(graphqlOperation(syncHseUserRoles))
//     if(result !== undefined) {
//       formattedData = formatHseUserRole(result.data);
//     }
//     else {
//       return null;
//     }
//   } catch (err) {
//     console.log('error Listing HSE user Qualifications:', err)
//   }
//   return formattedData
// }

// function formatHseUserRole(data) {
  
//   if(data.syncHseUserRoles !== undefined && data.syncHseUserRoles.items !== undefined) {
//     let formattedData = data.syncHseUserRoles.items.map(item => {      
//         return {
//           value: item.id,
//           label: item.name
//         }
//     });
//     return formattedData;
//   }
//   else {
//     return null;
//   }
// }


export async function AssignUserToHseProject (hseProjectUserDet) {
  try {
    let result = await API.graphql(
      graphqlOperation(createHseProjectUserDetails, {
        input: hseProjectUserDet
      })
    )

    // console.log(result)
    return true
  } catch (err) {
    console.log('error creating HSE ProjectUserDetails record:', err)
  }
  return false
}

export async function GetHseProjectItemDetails(projectId, dataType, dataId) {
  let formattedData = null;

  try {

    let result = await DataStore.query(
      HseProjectItemDetail,
      c => c.projectId('eq', projectId) && c.dataType('eq', dataType) && c.dataId('eq', dataId) &&c.isDeleted('eq', false)
    )
    if(result !== undefined) {
      formattedData = result[0];
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error getting Project Item details:', err);
  }
  return formattedData;
}

export async function AssignItemToHseProject (projectId, dataType, dataId, dataName, assignedByUserId, assignedByUserName, sendAlert) {
  let hseItemDetails = {
    projectId: projectId, 
    dataType: dataType,
    dataId: dataId, 
    dataName: dataName, 
    assignedByUserId: assignedByUserId,
    assignedByUserName: assignedByUserName,
    sendAlert: sendAlert,
    isDeleted: false
  }

  try {
    let result = await DataStore.save(
      new HseProjectItemDetail({ ...hseItemDetails })
    )

    // console.log(result)
    return true
  } catch (err) {
    console.log('error creating Assign Plant Item to Project:', err)
  }
  return false
}

export async function GetSignedInProjectByUserId(userId, isDataStoreOnline= false) {
  let formattedData = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    dataId : {eq: userId},
    dataType : {eq: 'USER'},
    signOffDateTimeAt : {eq: null}
  };

  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(
      HseProjectSignOnOff,
      c => c.dataId('eq', userId) && c.dataType('eq', 'USER') && c.signOffDateTimeAt('eq', null) && c.isDeleted('eq', false)
    )
 : (await FetchDataWithNextToken(listHseProjectSignOnOffs, filter, 'listHseProjectSignOnOffs'));

    if(result !== undefined) {
      formattedData = await formatHseProjectSignedInDetails(result, isDataStoreOnline)
      return formattedData
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error getting Project Item details:', err);
  }
  return formattedData;
}

export async function GetSignedInToProjectByUserId(userId, isDataStoreOnline= false) {
  let formattedData = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    // dataId : {eq: userId},
    dataType : {eq: 'USER'},
    signOffDateTimeAt : {eq: null}
  };

  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(
      HseProjectSignOnOff,
      c => c.dataId('eq', userId) && c.dataType('eq', 'USER') && c.signOffDateTimeAt('eq', null) && c.isDeleted('eq', false)
    )
 : (await FetchDataWithNextToken(hseProjectSignOnOffByDataId, filter, 'hseProjectSignOnOffByDataId', {dataId: userId}));

    if(result !== undefined) {
      formattedData = await formatHseProjectSignedInDetails(result, isDataStoreOnline)
      return formattedData
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error getting Project Item details:', err);
  }
  return formattedData;
}

export async function ListAllSignedInProjectsByUserId(userId,isDataStoreOnline=false ) {
  let formattedData = null;
  const filter = {
    isDeleted: {
      eq: false,
    },
    dataId : {eq: userId},
    dataType : {eq: 'USER'}
  };
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(
      HseProjectSignOnOff,
      c => c.dataId('eq', userId) && c.dataType('eq', 'USER')  && c.isDeleted('eq', false)
    )
 : (await FetchDataWithNextToken(listHseProjectSignOnOffs, filter, 'listHseProjectSignOnOffs'));

    if(result !== undefined) {
      formattedData = await formatHseProjectSignedInDetails(result, isDataStoreOnline)
      return formattedData
    }
    else {
      return null;
    }
  } catch (err) {
    console.log('error getting Project Item details:', err);
  }
  return formattedData;
}

async function formatHseProjectSignedInDetails (data, isDataStoreOnline=false) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
        let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c => c.id('eq', item.projectId)) :  ( (item.project && item.project.items ) ? ( item.project.items) : null  )
        return {
          ...item,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          state:
              projectDetails !== undefined && projectDetails.length > 0
                ? projectDetails[0].State
                : 'N/A',
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}
