import { ItemCollectionMetrics } from '@aws-sdk/client-dynamodb'
import { listHseIncidentForms, listHseEventForms, listHseUserActionForms, listHseJourneyPlannerForms, listHseStkyEngagementForms, listHseUserHazardReports, listHseFormImages, listHseUserProjectFatigues, listHseProjectTake5Assessments, listHseApprovalManagements } from '../graphql/queries';
import { DataStore, API, graphqlOperation } from 'aws-amplify'
// import { listHseIncidentForms, listHseEventForms, listHseUserActionForms, listHseJourneyPlannerForms,listHseApprovalManagements, listHseStkyEngagementForms, listHseUserHazardReports } from '../graphql/queries';
import { listFilter, getFromUtcToLocaleDateTimeString } from './HseCommonService';
import { createHseUserActionForm, createHseFormImage, createHseEventForm, updateHseUserProjectFatigue, updateHseFormImage } from './../graphql/mutations'
import {
  HseUserProjectFatigue,
  HseProjectTake5Assessment,
  HseIncidentForm,
  HseProject,
  HseEventForm,
  HseFormImage,
  HseUserActionForm,
  HseUserActionImages,
  HseProjectUserDetails,
  HseApprovalManagement,
  HseUserInspectionFiles
} from './../models'

import {SortDirection}  from "aws-amplify";

import { FetchDataWithNextToken } from './HseCommonService'
import { updateHseEventForm, updateHseUserActionForm } from '../graphql/mutations';
import { UploadFile } from './S3StorageService';


let filteredArrayLength = 0
export async function CreateHseUserProjectFatigue (hseUserProjectFatigue) {
  try {
    let result = await DataStore.save(
      new HseUserProjectFatigue({ ...hseUserProjectFatigue })
    )
    if (result !== undefined) {
      return await formatCreateHseUserProjectFatigue(result)
    } else {
      return null
    }
    return true
  } catch (err) {
    console.log('error creating  Hse UserProjectFatigue:', err)
  }
  return false
}

async function formatCreateHseUserProjectFatigue (data) {
  if (data !== undefined) {
    return {
      id: data.id,
      projectId: data.projectId,
      fatigueScore: data.FatigueScore,
      userId: data.userId,
      createdAt: data.createdAt,
      sleepIn24Hours: data.sleepIn24Hours,
      sleepInPrevious24Hours: data.sleepInPrevious24Hours,
      awokeTodayAt: data.awokeTodayAt
    }
  } else {
    return null
  }
}

export async function UpdateHseUserProjectFatigue (fatigueId, data, isDataStoreOnline=false) {
  try {
    if(isDataStoreOnline) {
      const original = await DataStore.query(HseUserProjectFatigue, fatigueId)

    let result = await DataStore.save(
      HseUserProjectFatigue.copyOf(original, updated => {
        updated.sleepIn24Hours= data.sleepIn24Hours
        updated.sleepInPrevious24Hours= data.sleepInPrevious24Hours
        updated.awokeTodayAt= data.awokeTodayAt
        updated.FatigueScore= data.FatigueScore
      })
    )
    return result
    } else {
      let result = await API.graphql({ query: updateHseUserProjectFatigue,  })
      if(result && result.data && result.data.updateHseUserProjectFatigue) {
        return result.data.updateHseUserProjectFatigue;
      }
      return null;
    }





    
  } catch (err) {
    console.log('error UpdateHseUserProjectFatigue:', err)
  }
}

export async function GetHseFatigueScoreForUser (userId, isDataStoreOnline = false) {
  let formattedData = null
  const filter = {
    userId : {eq: userId}
  };
  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseUserProjectFatigue, c =>
      c.userId('eq', userId),
      {  sort: s => s.createdAt(SortDirection.DESCENDING)}
    ): (await FetchDataWithNextToken(listHseUserProjectFatigues, filter, 'listHseUserProjectFatigues'));

    if (result !== undefined) {
      formattedData = await formatHseFatigueScoreForUser(result, isDataStoreOnline)
    } else {
      return null
    }
    // console.log(result.data);
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }

  // console.log(formattedData)
  return formattedData
}

async function formatHseFatigueScoreForUser (data, isDataStoreOnline) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
        let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c =>
          c.id('eq', item.projectId)
        ) :  ( (item.project && item.project.items ) ? ( item.project.items) : null  )

      return {
          id: item.id,
          projectId: item.projectId,
          fatigueScore: item.FatigueScore,
          userId: item.userId,
          createdAt: item.createdAt,
          sleepIn24Hours: item.sleepIn24Hours,
          sleepInPrevious24Hours: item.sleepInPrevious24Hours,
          awokeTodayAt: item.awokeTodayAt,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
          createdAt: item?.createdAt
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}

export async function GetHseProjectTake5AssessmentForUser (userId, isDataStoreOnline = false) {
  let formattedData = null
  const filter = {
    userId : {eq: userId},
    isDeleted : {eq: false}
  };
  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(
      HseProjectTake5Assessment,
      c =>
        c.userId('eq', userId) &&
        c.isDeleted('eq', false),
      {  sort: s => s.createdAt(SortDirection.DESCENDING)}
    ): (await FetchDataWithNextToken(listHseProjectTake5Assessments, filter, 'listHseProjectTake5Assessments'));

    // if (result !== undefined) console.log(result)

    if (result !== undefined) {
      formattedData = await formatHseProjectTake5AssessmentForUser(result, isDataStoreOnline)
      // console.log(formattedData)
    } else {
      return null
    }
    // console.log(result.data);
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }

  // console.log(formattedData)
  return formattedData
}

async function formatHseProjectTake5AssessmentForUser (data, isDataStoreOnline) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
      let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c =>
        c.id('eq', item.projectId)
      ) :  ( (item.project && item.project.items ) ? ( item.project.items) : null  )

        return {
          id: item.id,
          documentId: item.documentId,
          projectId: item.projectId,
          jobName: item.jobName,
          userId: item.userId,
          status: item.Status,
          formStatus: item.Status,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
          createdAt: item?.createdAt
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}


export async function UpdateHseIncidentFormInjuryFirstAid (incidentId, data) {
  try {
    const original = await DataStore.query(HseIncidentForm, incidentId)
    let result = await DataStore.save(
      HseIncidentForm.copyOf(original, updated => {
        updated.bodySelector= data.bodySelector
        updated.describeFirstAid= data.describeFirstAid
        updated.describeInjury= data.describeInjury
        updated.dutyStatusAtTimeOfEvent= data.dutyStatusAtTimeOfEvent
        updated.firstAider= data.firstAider
        updated.injuredPerson= data.injuredPerson
        updated.refDoctorContactDetails= data.refDoctorContactDetails
        updated.shiftCompleted= data.shiftCompleted
        updated.taskExperience= data.taskExperience          
      })
    )
    return result
  } catch (err) {
    console.log('error updating HseIncidentFormInjuryFirstAid:', err)
  }
}

export async function CreateHseIncidentFormImages (data) {
  try {
    let result = await DataStore.save(new HseFormImage({ ...data }))

    // console.log(result)
    return result
  } catch (err) {
    console.log('error creating  Hse Incident Form Personnel Involved:', err)
  }
  return false
}



export async function GetHseIncidentForm (userId,isDataStoreOnline = false) {
  let formattedData = null
  const filter = {
    formStatus: {
      ne: null,
    },
    reportedBy : {eq: userId}
  };
  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(
      HseIncidentForm,
      c => c.reportedBy('eq', userId) && c.formStatus('ne', null),
      {  sort: s => s.createdAt(SortDirection.DESCENDING)}
    ): (await FetchDataWithNextToken(listHseIncidentForms, filter, 'listHseIncidentForms'));


    // if (result !== undefined) console.log(result)

    if (result !== undefined) {
      formattedData = await formatHseIncidentForUser(result)
      // console.log(formattedData)
      return formattedData
    } else {
      return null
    }
    // console.log(result.data);
  } catch (err) {
    console.log('error Listing GetHseIncidentForm:', err)
  }
}

async function formatHseIncidentForUser (data) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
        let projectDetails = await DataStore.query(HseProject, c =>
          c.id('eq', item.projectId)
        )
        return {
          id: item.id,
          documentId: item.documentId,
          projectId: item.projectId,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
          reportedBy: item.reportedBy,
          reportedDate: item.reportedDate,
          actualConsequence: item.actualConsequence,
          cppOperator: item.cppOperator,
          cppVehicleRegistrationNumber: item.cppVehicleRegistrationNumber,
          driverLicenceNumber: item.driverLicenceNumber,
          formStatus: item.formStatus,
          immediateAction: item.immediateAction,
          incidentDateTime: item.incidentDateTime,
          displayIncidentDateTime: new Date(item.incidentDateTime).toLocaleDateString(),
          incidentDescription: item.incidentDescription,
          incidentSource: item.incidentSource,
          isStky: item.isStky,
          incidentLocation: item.incidentLocation,
          locationTag: item.locationTag,
          mobilePlant: item.mobilePlant,
          otherPropertyDetails: item.otherPropertyDetails,
          plantOperator: item.plantOperator,
          policeReportDateTime: item.policeReportDateTime,
          policeReportReferenceNo: item.policeReportReferenceNo,
          potentialConsequence: item.potentialConsequence,
          rootCause: item.rootCause,
          stkyCategory: item.stkyCategory,
          taskDetails: item.taskDetails,
          thirdPartyOperatorName: item.thirdPartyOperatorName,
          thirdPartyRegistrationNumber: item.thirdPartyRegistrationNumber,
          vehicleOtherDetails: item.vehicleOtherDetails,
          incidentTitle: item.incidentTitle,
          reportingItems: item.reportingItems,
          createdAt: item?.createdAt,
          createdAtObj: item?.createdAt ? getFromUtcToLocaleDateTimeString(item?.createdAt) :  ''
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}

export async function CreateHseEventNotificationForm (hseProjectEvent, isDataStoreOnline=false, myEventNotificationData) {
  try {
    let result = isDataStoreOnline
    ? await DataStore.save(new HseEventForm({ ...hseProjectEvent }))
    : await API.graphql(
        graphqlOperation(createHseEventForm, { input: hseProjectEvent })
      );
  if (result && result.data && result.data.createHseEventForm) {
    return result.data.createHseEventForm;
  }
  return result;
  } catch (err) {
    console.log('error creating  Hse Event Form:', err)
  }
  return false
}

export async function UpdateHseEventNotificationForm (eventId, data , isDataStoreOnline = false, myEventNotificationList ) {
  try {

    if(isDataStoreOnline) {
      const original = await DataStore.query(HseEventForm, eventId)

      let result = await DataStore.save(
        HseEventForm.copyOf(original, updated => {
          updated.reportedDate = data.reportedDate
          updated.reportingItems = data.reportingItems
          updated.eventDateTime = data.eventDateTime
          updated.taskDetails = data.taskDetails
          updated.eventTitle = data.eventTitle
          updated.eventLocation = data.eventLocation
          updated.locationTag =  data.locationTag
          updated.eventDescription = data.eventDescription
          updated.immediateAction = data.immediateAction
          updated.formStatus = data.formStatus
        })
      )
      return result;
    } else {
      // let result = await API.graphql({ query: updateHseUserLicense, variables: { input: {...data , id: userLicenseDetails?.id, _version: userLicenseDetails?.version}} })
      let obj = { input: {...data , id: myEventNotificationList?.id, _version: myEventNotificationList?.version}}
      let result = await API.graphql({ query: updateHseEventForm,variables: { ...obj}})
      if(result && result.data && result.data.updateHseEventForm) {
        return result.data.updateHseEventForm;
      }
      return null;
    }
  } catch (err) {
    console.log('error updating status for UpdateHseEventNotificationForm:', err)
  }
}

export async function deleteHseFormImage (id) {
  try {
    const original = await DataStore.query(HseFormImage, id)
    let result = await DataStore.save(
      HseFormImage.copyOf(original, updated => {
        updated.isDeleted = true
      })
    )
    return result
  } catch (err) {
    console.log('error updating status for HSEEvent:', err)
  }
}

export async function CreateHseUserActionForm (hseUserAction,  isDataStoreOnline=false) {
  try {
    let result = isDataStoreOnline
    ? await DataStore.save(
        new HseUserActionForm({ ...hseUserAction })
      )
    : await API.graphql(
        graphqlOperation(createHseUserActionForm, { input: hseUserAction })
      );
  if (result && result.data && result.data.createHseUserActionForm) {
    return result.data.createHseUserActionForm;
  }
  return result;

  } catch (err) {
    console.log('error creating  Hse User Action:', err)
  }
  return false
}

export async function GetHseEventForm (userId,isDataStoreOnline=false) {
  let formattedData = null
  const filter = {
    formStatus: {
      ne: null,
    },
    reportedBy : {eq: userId}
  };
  try {
    let result = (isDataStoreOnline) ? 
    await DataStore.query(HseEventForm, c => (c.reportedBy("eq", userId) && c.formStatus("ne", null)))
 : (await FetchDataWithNextToken(listHseEventForms, filter, 'listHseEventForms'));
    if (result !== undefined) {
      formattedData = await formatHseEventForUser(result,isDataStoreOnline)
      return formattedData;
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing GetHseEventForm:', err)
  }
}

export async function GetHseEventFormByEventId (eventId,isDataStoreOnline) {
  let formattedData = null
  const filter = {
    formStatus: {
      ne: null,
    },
    id : {eq: eventId}
  };
  try {
    let result =  (isDataStoreOnline) ? await DataStore.query(
      HseEventForm,
      c => c.id('eq', eventId) && c.formStatus('ne', null)
    ) : (await FetchDataWithNextToken(listHseEventForms, filter, 'listHseEventForms'));
    if (result !== undefined) {
      formattedData = await formatHseEventForUser(result,isDataStoreOnline)
      return formattedData
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }
}


async function formatHseEventForUser (data,isDataStoreOnline) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
        let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c => c.id('eq', item.projectId)) :  ( (item.project && item.project.items ) ? ( item.project.items) : null  )
        return {
          id: item.id,
          projectId: item.projectId,
          documentId: item.documentId,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
          reportedBy: item.reportedBy,
          reportedDate: item.reportedDate,
          reportingItems: item.reportingItems,
          eventTitle: item.eventTitle,
          formStatus: item.formStatus,
          eventDescription: item.eventDescription,
          immediateAction: item.immediateAction,
          eventDateTime: item.eventDateTime,
          displayEventDateTime: new Date(item.eventDateTime).toLocaleDateString(),
          eventLocation: item.eventLocation,
          locationTag: item.locationTag,
          taskDetails: item.taskDetails,
          createdAt: item?.createdAt,
          version: item?._version
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}

export async function GetHseUserActionForm (userId, isDataStoreOnline = false) {
  let formattedData = null
  const filter = {
    responsiblePerson : {contains: userId},
    taskStatus : {ne: "DRAFT"}
  };
  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(
      HseUserActionForm,
      c => c.responsiblePerson('contains', userId) && c.taskStatus('ne', "DRAFT")
    ): (await FetchDataWithNextToken(listHseUserActionForms, filter, 'listHseUserActionForms'));

    if (result !== undefined) {
      formattedData = await formatHseUserAction(result, isDataStoreOnline)
      return formattedData
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }
}

export async function GetHseUserActionFormByFormId (formId, isDataStoreOnline = false) {
  const filter = {
    isDeleted: {
      eq: false,
    },
    formId : {eq: formId}
  };
  let formattedData = null
  try {
    let result =  (isDataStoreOnline) ? await DataStore.query(HseUserActionForm, c =>
      c.formId ('eq', formId) && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseUserActionForms, filter, 'listHseUserActionForms'));

    if (result !== undefined) {
      formattedData = await formatHseUserAction(result,isDataStoreOnline)
      return formattedData
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }
}

export async function GetHseMyUserActionForm (userId, isDataStoreOnline = false) {
  let formattedData = null
  const filter = {
    reportedBy : {eq: userId},
    isDeleted : {eq: false}
  };
  try {
    let result =(isDataStoreOnline) ?  await DataStore.query(HseUserActionForm, c =>
      c.reportedBy ('eq', userId) && c.isDeleted('eq', false),
      {  sort: s => s.createdAt(SortDirection.DESCENDING)}
    ): (await FetchDataWithNextToken(listHseUserActionForms, filter, 'listHseUserActionForms'));
    if (result !== undefined) {
      formattedData = await formatHseUserAction(result, isDataStoreOnline)
      return formattedData
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing Take5 Assessment:', err)
  }
}

async function formatHseUserAction (data, isDataStoreOnline) {
  if (data !== undefined) {
    let formattedData = Promise.all(
      data.map(async item => {
      let projectDetails = (isDataStoreOnline)  ? await DataStore.query(HseProject, c =>
        c.id('eq', item.projectNumber)
      ) :  ( (item.projectNumber ) ? ( item) : null  )
        let userDetails = await DataStore.query(HseProjectUserDetails, c =>
          c.userId('eq', item.responsiblePerson)
        )
        return {
          ...item,
          projectName:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectName
              : 'N/A',
          projectNumber:
            projectDetails !== undefined && projectDetails.length > 0
              ? projectDetails[0].projectNumber
              : 'N/A',
          isArchived:  projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0]?.isArchived !== null ? projectDetails[0]?.isArchived : false : false,
          isDeleted: false,
          savedToDb: true,
          updateToDB: false
        }
      })
    ).then(values => {
      return values
    })
    return formattedData
  } else {
    return null
  }
}

export async function UpdateHseUserActionForm (actionId, updatedObj, isDataStoreOnline = false, userActionList) {
  try {
    
    if(isDataStoreOnline) {
      const original = await DataStore.query(HseUserActionForm, actionId)

      let result = await DataStore.save(
        HseUserActionForm.copyOf(original, updated => {
          updated.taskTitle = updatedObj.taskTitle
          updated.taskDescription = updatedObj.taskDescription
          updated.dueDate = updatedObj.dueDate
          updated.isDeleted = updatedObj.isDeleted
          updated.taskStatus = updatedObj.taskStatus
        })
      )
      return result;
    } else {
      let obj = { input: {...updatedObj , id: userActionList?.id, _version: userActionList?._version}}
      let result = await API.graphql({ query: updateHseUserActionForm, variables: {...obj}  })
      if(result && result.data && result.data.updateHseUserActionForm) {
        return result.data.updateHseUserActionForm;
      }
      return null;
    }
  } catch (err) {
    console.log('error updating status for Take5Assessment:', err)
  }
}

export async function softDeleteHseUserAction (actionId) {
  try {
    const original = await DataStore.query(HseUserActionForm, actionId)

    let result = await DataStore.save(
      HseUserActionForm.copyOf(original, updated => {
        updated.isDeleted = true
      })
    )

    return result
  } catch (err) {
    console.log('error updating status for Take5Assessment:', err)
  }
}

export async function CreateHseUserActionImages (data) {
  try {
    let result = await DataStore.save(new HseUserActionImages({ ...data }))
    return result
  } catch (err) {
    console.log('error creating  Hse Event Form Images:', err)
  }
  return false
}

export async function GetHseEventImages (eventId) {
  let formattedData = null
  try {
    let result = await DataStore.query(
      HseFormImage,
      c => c.eventId('eq', eventId)
    )
      return result
  } catch (err) {
    console.log('error Listing HseFormImage :', err)
  }
  return formattedData
}

export async function GetHseIncidentImages (incidentId) {
  let formattedData = null
  try {
    let result = await DataStore.query(
      HseFormImage,
      c => c.incidentId('eq', incidentId)
    )
      return result
  } catch (err) {
    console.log('error Listing HseFormImage :', err)
  }
  return formattedData
}

export async function GetHseIncidentFormsIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      let result = await FetchDataWithNextToken(listHseIncidentFormsOnlyId, filter, "listHseIncidentForms")

      if(result) {
        
        if(result.length) {
          formattedData = result.map(item => {      
              return (item?.id !== undefined ? item?.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE project Incident Form Ids:', err);
    }
    return formattedData;
}

export const listHseIncidentFormsOnlyId = /* GraphQL */ `
  query ListHseIncidentForms(
    $filter: ModelHseIncidentFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseIncidentForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id        
      }
      nextToken
      startedAt
    }
  }
`;

export async function GetHseEventFormsIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      let result = await FetchDataWithNextToken(listHseEventFormsOnlyId, filter, "listHseEventForms")

      if(result !== undefined) {   
          
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE project Event Form Ids:', err);
    }
    return formattedData;
}

export const listHseEventFormsOnlyId = /* GraphQL */ `
  query ListHseEventForms(
    $filter: ModelHseEventFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseEventForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;

export async function GetHseUserActionsIdsByUserId(userId) { 
  let formattedData = [];
  try {
      let filter = {
        responsiblePerson: {
            eq:  userId.toString()  
        },
        isDeleted: {
            eq: false
        }
      };
      // let result = await API.graphql({ query: listHseUserActionFormsOnlyId, variables: { filter: filter}});

      let result = await FetchDataWithNextToken(listHseUserActionFormsOnlyId, filter, "listHseUserActionForms")

      if(result !== undefined) {
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE user Actions Ids:', err);
    }
    return formattedData;
}

export const listHseUserActionFormsOnlyId = /* GraphQL */ `
  query ListHseUserActionForms(
    $filter: ModelHseUserActionFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserActionForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;

export async function GetHseJourneyPlannerFormIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      let result = await FetchDataWithNextToken(listHseJourneyPlannerFormsOnlyIds, filter, "listHseJourneyPlannerForms")
      if(result !== undefined) {        
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE Journey Planner Form Ids:', err);
    }
    return formattedData;
}

export const listHseJourneyPlannerFormsOnlyIds = /* GraphQL */ `
  query ListHseJourneyPlannerForms(
    $filter: ModelHseJourneyPlannerFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseJourneyPlannerForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;

export async function GetHseStkyEnagagementFormIdsForProjectIds(projectIds) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        }
      };
      projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
      let result = await FetchDataWithNextToken(listHseStkyEngagementFormsOnlyIds, filter, "listHseStkyEngagementForms")

      if(result !== undefined) {        
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE Stky Engagement Form Ids:', err);
    }
    return formattedData;
}

export const listHseStkyEngagementFormsOnlyIds = /* GraphQL */ `
  query ListHseStkyEngagementForms(
    $filter: ModelHseStkyEngagementFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseStkyEngagementForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;

export async function CreateAttachmentFile (hseFormImage, isDataStoreOnline=false, S3FileName, element) {
    try {
      let result = isDataStoreOnline ? await DataStore.save(new HseFormImage({ ...hseFormImage }))
      : UploadFile(element,S3FileName).then( await API.graphql(
          graphqlOperation(createHseFormImage, { input: hseFormImage })
        ));
    if (result && result.data && result.data.createHseFormImage) {
      return result.data.createHseFormImage;
    }
    return result;

    } catch (err) {
      console.log('error creating  Hse Form attachment at CreateAttachmentFile:', err)
    }
    return false
}

export async function DeleteAttachmentFile(id, isDataStoreOnline = false, imageData) {
  try {
    // let result = await DataStore.delete(HseFormImage, HseFormImage => HseFormImage.id("eq", id));
     let result =(isDataStoreOnline) ? await DataStore.delete(HseFormImage, HseFormImage => HseFormImage.id("eq", id)):
    await API.graphql({ query: updateHseFormImage, variables: { input: { id:id, _version:imageData._version, isDeleted:imageData.isDeleted}} }) 
    return result
   
  } catch (err) {
    console.log('error Deleting  Hse Form attachment at DeleteAttachmentFile:', err)
  }
  return false
}

export async function listAttachmentFileByFormId (id, fromType , isDataStoreOnline = false) {
  const filter = {
    formType: {
      eq: fromType,
    },
    formId : {eq: id},
    isDeleted : {eq: false}
  };
    try {
      let result = (isDataStoreOnline) ? 
      await DataStore.query(HseFormImage, c => (c.formId('eq', id) && c.formType('eq', fromType) && c.isDeleted('eq', false)))
   : (await FetchDataWithNextToken(listHseFormImages, filter, 'listHseFormImages'));
      return result
    } catch (err) {
      console.log('error Listing Take5 listAttachmentFileByFormId:', err)
    }
    return undefined
  }

  export async function emptyHseFormbase64Image (id, modelName) {
    try {

      if(modelName == "HseFormImage") {
        const original = await DataStore.query(
          HseFormImage,
          id
        )
        let result = await DataStore.save(
          HseFormImage.copyOf(original, updated => {
            updated.fileBase64 = ''
          })
        )
      } else if(modelName == "HseUserActionImages") {
        const original = await DataStore.query(
          HseUserActionImages,
          id
        )
        let result = await DataStore.save(
          HseUserActionImages.copyOf(original, updated => {
            updated.fileBase64 = ''
          })
        )
      } else if(modelName == "HseUserInspectionFiles") {
        const original = await DataStore.query(
          HseUserInspectionFiles,
          id
        )
        let result = await DataStore.save(
          HseUserInspectionFiles.copyOf(original, updated => {
            updated.fileBase64 = ''
          })
        )
      }
      
      return true
    } catch (err) {
      console.log('error updating at emptyHseFormbase64Image:', err)
    }
  }

  export async function GetUserListForNotification (projectId, formName, isDataStoreOnline=false) {
    let formattedData = null
    const filter = {
      isDeleted: {
        eq: false,
      },
      projectId : {eq: projectId},
      formName : {eq: formName}
    };
    try {
      let result = (isDataStoreOnline) ? 
      await DataStore.query(
        HseApprovalManagement,
        c => c?.projectId('eq', projectId) && c?.formName('eq', formName) && c?.isDeleted('eq', false)
      )
   : (await FetchDataWithNextToken(listHseApprovalManagements, filter, 'listHseApprovalManagements'));
  if (result !== undefined) {
  formattedData = await formatUserList(result, isDataStoreOnline)
  console.log("2formattedData",formattedData);
  return formattedData
  } else {
    return []
  }
    } catch (err) {
      console.log('error Listing GetUserListForNotification:', err)
    }
  };
  
  async function formatUserList (data, isDataStoreOnline=false) {
    if (data !== undefined) {
      let formattedData = Promise.all(
        data.map(async item => {
          console.log("item3",item);
          // let projectDetails = await DataStore.query(HseProject, c =>
          //   c.id('eq', item.projectId)
            let projectDetails = isDataStoreOnline ? await DataStore.query(HseProject, c =>
              c.id('eq', item.projectId)) : ( (item.project && item.project.items ) ? ( item.project.items) : null )
          // )
          return {
            id: item.id,
            projectId: item.projectId,
            projectName: projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0].projectName : 'N/A',
            projectNumber: projectDetails !== undefined && projectDetails.length > 0 ? projectDetails[0].projectNumber : 'N/A',
            formName: item.formName,
            approver: item.approver,
            peopleToBeNotified: item.peopleToBeNotified,
            isDeleted: item.isDeleted,
            version: item._version
          }
        })
      ).then(values => {
        return values
      })
      return formattedData
    } else {
      return []
    }
  }
  
  export async function GetHazardReportIdsForProjectIds(projectIds) { 
    let formattedData = [];
    try {
        let filter = {
          isDeleted: {
              eq: false
          }
        }
        projectIds?.length && (filter['or'] = listFilter(projectIds, 'projectId'))
        
        let result = await FetchDataWithNextToken(listHseUserHazardReportsCustom, filter, "listHseUserHazardReports")
        if(result) {
          if(result.length) {
            formattedData = result.map(item => {      
                return (item.id !== undefined ? item.id : "");
            });
          }
        }
        else {
          return [];
        }
      } catch (err) {
        console.log('error Listing HSE project UserHazards Ids:', err);
      }
      return formattedData;
  }
  
  export const listHseUserHazardReportsCustom = /* GraphQL */ `
  query ListHseUserHazardReports(
    $filter: ModelHseUserHazardReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserHazardReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id      
      }
      nextToken
      startedAt
    }
  }
  `;


export async function GetHseSwmsActionsIdsForUserId(userId) { 
  let formattedData = [];
  try {
      let filter = {
        isDeleted: {
            eq: false
        },
        responsiblePerson: {
          contains: userId
        }
      }
    
      
      let responsiblePersonResult = await FetchDataWithNextToken(listHseSwmsActionsCustom, filter, "listHseSwmsActions")

      filter = {
        isDeleted: {
            eq: false
        },
        reportedBy: {
          eq: userId
        }
      }

      let reportedByResult = await FetchDataWithNextToken(listHseSwmsActionsCustom, filter, "listHseSwmsActions")

      filter = {
        isDeleted: {
            eq: false
        },
        verifiers: {
          contains: userId
        }
      }

      let verifiersResult = await FetchDataWithNextToken(listHseSwmsActionsCustom, filter, "listHseSwmsActions")

      let result = []
      result.concat(responsiblePersonResult,reportedByResult,verifiersResult)

      if(result) {
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE project HseSwmsActions Ids:', err);
    }
    return formattedData;
}

export async function GetSwmsForOrgId(orgId) { 
  let formattedData = [];
  try {
      let filter = {
        or : [{assessmentOutcome: {eq: 'CONDITIONALLY APPROVED'}}, {assessmentOutcome: {eq: 'APPROVED'}}]
       ,
        isDeleted: {
            eq: false
        }
      }
    orgId && (filter["organisationId"] = { eq: orgId });
      
      let result = await FetchDataWithNextToken(listHseSwmsCustom, filter, "listHseSwms")
      if(result) {
        if(result.length) {
          formattedData = result.map(item => {      
              return (item.id !== undefined ? item.id : "");
          });
        }
      }
      else {
        return [];
      }
    } catch (err) {
      console.log('error Listing HSE project GetSwmsForOrgId Ids:', err);
    }
    return formattedData;
}

export const listHseSwmsActionsCustom = /* GraphQL */ `
  query ListHseSwmsActions(
    $filter: ModelHseSwmsActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseSwmsActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;


export const listHseSwmsCustom = /* GraphQL */ `
  query ListHseSwms(
    $filter: ModelHseSwmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseSwms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
      startedAt
    }
  }
`;