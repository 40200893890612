import * as types from '../../constants'
import { set, get } from 'idb-keyval'


class UserDetails {
  constructor (id, given_name, family_name, phone_number, email, roleDetail, organisationDetail) {
    this.id = id
    this.given_name = given_name
    this.family_name = family_name
    this.phone_number = phone_number
    this.email = email
    this.roleDetail = roleDetail
    this.organisationDetail = organisationDetail
  }
}

const checkUserDetailsInLocalStorage = async () => {
  // let previousSignedInUser = localStorage.getItem('signedInUser')
  // if(previousSignedInUser) {
  //   get('userDetails').then(result => {
  //     return JSON.parse(result)
  //   })
  // } else {
  //   return new UserDetails()
  // }

  get('userDetails').then(result => {
    if(result && result.length > 0) {
      return JSON.parse(result)
    } else {
      return new UserDetails()
    }
  })
}


const initialState = {
  userDetails: checkUserDetailsInLocalStorage(),//new UserDetails(),
  networkOnline : window.navigator.onLine,
  userGroups : [],
  isAdmin: false
}

function setIsAdminFlag(state) {
  if(state?.userGroups?.length) {
    return state.userGroups.includes("HSEAdmin")
  } else {
    return false
  }
}

export default function reducer (state = initialState, actions) {
  state = {
    ...state,
    isAdmin: setIsAdminFlag(state)
  }

  switch (actions.type) {
    case types.SET_USER_DETAILS:        
        const {sub, given_name, family_name, phone_number, email, roleDetail, organisationDetail} = actions.payload;
      return {
        ...state,
        userDetails: new UserDetails(sub, given_name, family_name, phone_number, email, roleDetail, organisationDetail)
      }
      break;
    case types.SET_NETWORK_ONLINE:
        const {networkOnline} = actions.payload;
        return {
          ...state,
          networkOnline: networkOnline
        }
        break;
    case types.SET_USER_GROUPS:
          const {userGroups} = actions.payload;
          return {
            ...state,
            userGroups: userGroups
          }
          break;
    default:
      return state
  }
}
