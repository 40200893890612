
import React, { useEffect, useState, useRef  } from 'react'
import styled from 'styled-components/macro'
import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { useSelector } from 'react-redux'

import {
    Button as MuiButton,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Box,
    CircularProgress,
    Typography
  } from '@material-ui/core'

import QrReader from 'react-qr-reader'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Measure from 'react-measure'

import { GetHseProjectByIdForOnlineUser,  AssignUserToHseProject, GetHseUserProjectDetails } from './../../services/HseProjectService'
import { GetHseProjectSingOnOffDetails, CreateHseUserProjectSingOnOffDetails, UpdateHseSignOff } from './../../services/HseSignInService'
import * as Constants from '../../constants/index'
import { DecryptText } from '../../services/HseCryptoService'
import { getCompletionDeadline, getAWSLocalDateTimeInISOStringFormat } from '../../services/HseCommonService'
import { GetHseUserInductionIds, CreateHseInductionUser } from '../../services/HseInductionService'


const Divider = styled(MuiDivider)(spacing)
const Button = styled(MuiButton)(spacing)
const Spacer = styled.div(spacing)

const useStyles = makeStyles((theme) => ({
    button: {
    //   marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: '100%',
      width: '100px'
    },
    qrCode: {
        border: '1px solid black',
        minWidth: '200px',
        minHeight: '200px'
    },
    dialogPaper: {
        minHeight: '70%',
        maxHeight: '70%',
        minWidth: '98%',
        maxWidth: '98%'
    },
    maxSizeDialogPaper: {
        minHeight: '70%',
        maxHeight: '70%',
        minWidth: '800px',
        maxWidth: '800px',
        textAlign: 'center'
    },
    addMinimumWidth: {
        minWidth: '98% !important'
    },
    addMaxWidth: {
        minWidth: '70% !important'
    },
    dialogAction: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    printElements: {
        overflow: 'hidden',
        height: '0'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
  }));

  function QRScanner({open, setOpen}) {
    const classes = useStyles()
    const userDetails = useSelector(state => state.sessionData.userDetails)
    const networkStatus = useSelector(state => state.sessionData.networkOnline)

    const [screenDimension, setScreenDimension] = useState({
        width: 501,
        height: -1,
      })
    const [scannedData, setScannedData] = useState()
    const [isProcessing, setIsProcessing] = useState(false)
    const [processingMessage, setProcessingMessage] = useState('Processing Login...')

    const handleScan = async( data) => {
        if (data) {
          data = await DecryptText(data)
          setScannedData(data)
          
          let parsedData = JSON.parse(data)
          setIsProcessing(true)
          setProcessingMessage('Processing..')
        if(parsedData != null && parsedData != undefined && parsedData.projectId != undefined ) {
            let projectId = parsedData.projectId            
            GetHseUserProjectDetails(projectId,userDetails.id).then(async (result) => {
                if(result != null && result != undefined) {
                    if(result.length > 0) {
                       await SignInOffUser(projectId)                        
                    } else {
                        if(networkStatus) {
                            setProcessingMessage('User getting assigned to the Project..')
                            setTimeout(function () {
                                AssignUserToTheProject(projectId)
                            }, 2000);
                        } else {
                            setProcessingMessage('User Not assigned to the project. You Cannot sign in when offline.')
                        }                        
                    }                
                }
                else {
                    setProcessingMessage('Error in Sign-On Process')
                }
            })
            }
          } else {
            setProcessingMessage('Project QR Code Not Valid.')
          }
          
      }

      async function AssignUserToTheProject(projectId) {

        let userObj = {
            projectId: projectId,
            roleId: userDetails.roleDetail.value,
            userId: userDetails.id,
            userName: userDetails.given_name + ' ' + userDetails.family_name,
            sendAlert: true,
            isDeleted: false
          }

          AssignUserToHseProject(userObj).then(async (result) => {
            await assignInductionToUser(userDetails.id, projectId)
            await SignInOffUser(projectId)
          })
      }

      const assignInductionToUser = async (userId, projectId) => { 
          let inductionsToCreate = []
        let userInductionList =  await GetHseUserInductionIds(userId)
        let projectDetails = await GetHseProjectByIdForOnlineUser(projectId)
        console.log('user induction ids', userInductionList)
        console.log('project details', projectDetails)

        if(projectDetails && projectDetails .projectInduction && projectDetails.projectInduction) {
            let projectInductionList = projectDetails.projectInduction.split(',')
            console.log('project induction ids', projectInductionList)

            if (projectInductionList.length) {
                await Promise.all(
                  projectInductionList?.length &&
                  projectInductionList.map(async(inductionId) => {
                    if(userInductionList.indexOf(inductionId) === -1) {
                      let deadlineDetails = await getCompletionDeadline(inductionId)
                      let objToCreate = {
                        userId: userId,
                        inductionId: inductionId,
                        enrollDateTime: getAWSLocalDateTimeInISOStringFormat(new Date()),
                        completionDeadline: deadlineDetails.completionDeadline,
                        completionStatus: Constants.COURSE_STATUS.ASSIGNED,
                        isDeleted: false,
                      };
                      return CreateHseInductionUser(objToCreate);
                    }
                  })
                ).finally(() => {
                  return true;
                });
              } else {
                return true;
              }            
        } 
        return true;
      }

    

      async function SignInOffUser(projectId) {
        GetHseProjectSingOnOffDetails(Constants.PROJECT_SIN_ON_OFF_DATA_TYPE.USER, projectId,userDetails.id).then(async (signInResult) => {
            // console.log('GetHseProjectSingOnOffDetails records', signInResult)
            if(signInResult != null && signInResult != undefined)  { 
                let userName = ((userDetails.given_name && userDetails.family_name)) ? (userDetails.given_name + ' ' + userDetails.family_name) : userDetails.email

                let signInActionResult
                if(signInResult.length === 0 || (signInResult?.length && signInResult[0].signOffDateTimeAt)) { 
                    setProcessingMessage('Processing Sign on..')
                    signInActionResult = await CreateHseUserProjectSingOnOffDetails(projectId, userDetails.id, userName, userDetails.id, userName)
                    if(signInActionResult) {
                        setProcessingMessage('Project Sign-On Complete')
                    } else {
                        setProcessingMessage('There was error please try again!')
                    }
                } else if(signInResult.length > 0 && !signInResult[0].signOffDateTimeAt) { 
                    let recordToUpdate = signInResult[0]
                        let objectToUpdate = {
                        id: recordToUpdate.id,
                        signOffDateTimeAt: getAWSLocalDateTimeInISOStringFormat(new Date()),
                        signOffByUserId: userDetails.id,
                        signOffByUserName: userName,
                        _version: recordToUpdate._version
                    }

                    let updateResult = await UpdateHseSignOff(objectToUpdate)

                    if(updateResult) {
                        setProcessingMessage('Project Sign-Off Complete')
                    } else {
                        setProcessingMessage('There was error please try again!')
                    }
                } else {
                    setProcessingMessage('You have already signed off for today.')
                }
            } else {
                setProcessingMessage('Error in Sign-On Process. Please try again.')
            }
        })
      }

    const handleError = err => {
        setIsProcessing(false)
        setProcessingMessage('Error Reading QR code...')
        console.error(err)
    }

    const handleClose = () => {       
        setIsProcessing(false)
        setProcessingMessage('Processing Login...')
        setOpen(false);
      };

    return (
        <Measure
            bounds        
            onResize={contentRect => {
                setScreenDimension(contentRect.bounds)
                // console.log(contentRect.bounds)
            }}
        >
          {({ measureRef }) =>
            <Dialog 
                open={open}
                ref={measureRef}
                onClose={handleClose}
                aria-labelledby="project-qr-scanner"
                classes={{ paper: (screenDimension.width >= 1024) ? classes.maxSizeDialogPaper : classes.dialogPaper }}
                >
                <DialogTitle id="signature-pad" >
                    <Typography variant='h2' gutterBottom >
                        Project Sign-in
                    </Typography>
                </DialogTitle> 
                <DialogContent  className={classes.dialogContent} >
                    {/* <QRCode id="myqr" value={projectId} level='H' size={(screenDimension.width > 500) ? (500) : (screenDimension.width-55)} className={classes.qrCode}/> */}
                    
                    {(isProcessing) ?
                        (
                            <Box display='flex' flexDirection="column" justifyContent='center' my={6}>
                                <Box><img src="/signInScanner.gif" style={{width: '60%', height: '60%'}} /></Box>
                                <Typography variant='h3' gutterBottom >
                                    {processingMessage}
                                </Typography>
                                {(processingMessage == 'Processing Login...' && <CircularProgress />)}
                                
                            </Box>
                        )
                        :
                        (
                        <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        facingMode="environment"
                        style={{ width: (screenDimension.width >= 500) ? '60%' :  '90%'}}
                        showViewFinder={true}
                        />
                        )
                    } 

                    
                </DialogContent> 
                <DialogActions className={classes.dialogAction}>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
            }
        </Measure>
    )
  }

  export default QRScanner