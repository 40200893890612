import CryptoJS from 'crypto-js'

import * as Constants from './../constants/index'

export async function EncryptText(textToEncrypt) {
    if(textToEncrypt) {
        var cipherText = CryptoJS.AES.encrypt(textToEncrypt, Constants.ENCRYPTION_KEY);
        return cipherText.toString();
    }
    return '';
}

export async function DecryptText(textToDecrypt) {
    if(textToDecrypt) {
        var normalText = CryptoJS.AES.decrypt(textToDecrypt.toString(), Constants.ENCRYPTION_KEY);
        return normalText.toString(CryptoJS.enc.Utf8);
    }
    return '';
}

export async function EncryptTextWithKey(textToEncrypt, key) {
    if(textToEncrypt) {
        var cipherText = CryptoJS.AES.encrypt(textToEncrypt, key);
        return cipherText.toString();
    }
    return '';
}

export async function DecryptTextWithKey(textToDecrypt, key) {
    if(textToDecrypt) {
        var normalText = CryptoJS.AES.decrypt(textToDecrypt.toString(), key); 
        return normalText.toString(CryptoJS.enc.Utf8);
    }
    return '';
}