/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHseCertification = /* GraphQL */ `
  mutation CreateHseCertification(
    $input: CreateHseCertificationInput!
    $condition: ModelHseCertificationConditionInput
  ) {
    createHseCertification(input: $input, condition: $condition) {
      id
      name
      type
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseCertification = /* GraphQL */ `
  mutation UpdateHseCertification(
    $input: UpdateHseCertificationInput!
    $condition: ModelHseCertificationConditionInput
  ) {
    updateHseCertification(input: $input, condition: $condition) {
      id
      name
      type
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseCertification = /* GraphQL */ `
  mutation DeleteHseCertification(
    $input: DeleteHseCertificationInput!
    $condition: ModelHseCertificationConditionInput
  ) {
    deleteHseCertification(input: $input, condition: $condition) {
      id
      name
      type
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserCertification = /* GraphQL */ `
  mutation CreateHseUserCertification(
    $input: CreateHseUserCertificationInput!
    $condition: ModelHseUserCertificationConditionInput
  ) {
    createHseUserCertification(input: $input, condition: $condition) {
      id
      userId
      CertificationId
      issueDate
      expiryDate
      documentId
      isDeleted
      certification {
        items {
          id
          name
          type
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserCertification = /* GraphQL */ `
  mutation UpdateHseUserCertification(
    $input: UpdateHseUserCertificationInput!
    $condition: ModelHseUserCertificationConditionInput
  ) {
    updateHseUserCertification(input: $input, condition: $condition) {
      id
      userId
      CertificationId
      issueDate
      expiryDate
      documentId
      isDeleted
      certification {
        items {
          id
          name
          type
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserCertification = /* GraphQL */ `
  mutation DeleteHseUserCertification(
    $input: DeleteHseUserCertificationInput!
    $condition: ModelHseUserCertificationConditionInput
  ) {
    deleteHseUserCertification(input: $input, condition: $condition) {
      id
      userId
      CertificationId
      issueDate
      expiryDate
      documentId
      isDeleted
      certification {
        items {
          id
          name
          type
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserOtherRecords = /* GraphQL */ `
  mutation CreateHseUserOtherRecords(
    $input: CreateHseUserOtherRecordsInput!
    $condition: ModelHseUserOtherRecordsConditionInput
  ) {
    createHseUserOtherRecords(input: $input, condition: $condition) {
      id
      userId
      recordName
      issueDate
      expiryDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserOtherRecords = /* GraphQL */ `
  mutation UpdateHseUserOtherRecords(
    $input: UpdateHseUserOtherRecordsInput!
    $condition: ModelHseUserOtherRecordsConditionInput
  ) {
    updateHseUserOtherRecords(input: $input, condition: $condition) {
      id
      userId
      recordName
      issueDate
      expiryDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserOtherRecords = /* GraphQL */ `
  mutation DeleteHseUserOtherRecords(
    $input: DeleteHseUserOtherRecordsInput!
    $condition: ModelHseUserOtherRecordsConditionInput
  ) {
    deleteHseUserOtherRecords(input: $input, condition: $condition) {
      id
      userId
      recordName
      issueDate
      expiryDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserQualification = /* GraphQL */ `
  mutation CreateHseUserQualification(
    $input: CreateHseUserQualificationInput!
    $condition: ModelHseUserQualificationConditionInput
  ) {
    createHseUserQualification(input: $input, condition: $condition) {
      id
      userId
      name
      issueDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserQualification = /* GraphQL */ `
  mutation UpdateHseUserQualification(
    $input: UpdateHseUserQualificationInput!
    $condition: ModelHseUserQualificationConditionInput
  ) {
    updateHseUserQualification(input: $input, condition: $condition) {
      id
      userId
      name
      issueDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserQualification = /* GraphQL */ `
  mutation DeleteHseUserQualification(
    $input: DeleteHseUserQualificationInput!
    $condition: ModelHseUserQualificationConditionInput
  ) {
    deleteHseUserQualification(input: $input, condition: $condition) {
      id
      userId
      name
      issueDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseLicense = /* GraphQL */ `
  mutation CreateHseLicense(
    $input: CreateHseLicenseInput!
    $condition: ModelHseLicenseConditionInput
  ) {
    createHseLicense(input: $input, condition: $condition) {
      id
      licenseType
      licenseClass
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseLicense = /* GraphQL */ `
  mutation UpdateHseLicense(
    $input: UpdateHseLicenseInput!
    $condition: ModelHseLicenseConditionInput
  ) {
    updateHseLicense(input: $input, condition: $condition) {
      id
      licenseType
      licenseClass
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseLicense = /* GraphQL */ `
  mutation DeleteHseLicense(
    $input: DeleteHseLicenseInput!
    $condition: ModelHseLicenseConditionInput
  ) {
    deleteHseLicense(input: $input, condition: $condition) {
      id
      licenseType
      licenseClass
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseLicenseRestriction = /* GraphQL */ `
  mutation CreateHseLicenseRestriction(
    $input: CreateHseLicenseRestrictionInput!
    $condition: ModelHseLicenseRestrictionConditionInput
  ) {
    createHseLicenseRestriction(input: $input, condition: $condition) {
      id
      licenseType
      restriction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseLicenseRestriction = /* GraphQL */ `
  mutation UpdateHseLicenseRestriction(
    $input: UpdateHseLicenseRestrictionInput!
    $condition: ModelHseLicenseRestrictionConditionInput
  ) {
    updateHseLicenseRestriction(input: $input, condition: $condition) {
      id
      licenseType
      restriction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseLicenseRestriction = /* GraphQL */ `
  mutation DeleteHseLicenseRestriction(
    $input: DeleteHseLicenseRestrictionInput!
    $condition: ModelHseLicenseRestrictionConditionInput
  ) {
    deleteHseLicenseRestriction(input: $input, condition: $condition) {
      id
      licenseType
      restriction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserLicense = /* GraphQL */ `
  mutation CreateHseUserLicense(
    $input: CreateHseUserLicenseInput!
    $condition: ModelHseUserLicenseConditionInput
  ) {
    createHseUserLicense(input: $input, condition: $condition) {
      id
      userId
      licenseId
      issueDate
      expiryDate
      issueState
      restrictions
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserLicense = /* GraphQL */ `
  mutation UpdateHseUserLicense(
    $input: UpdateHseUserLicenseInput!
    $condition: ModelHseUserLicenseConditionInput
  ) {
    updateHseUserLicense(input: $input, condition: $condition) {
      id
      userId
      licenseId
      issueDate
      expiryDate
      issueState
      restrictions
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserLicense = /* GraphQL */ `
  mutation DeleteHseUserLicense(
    $input: DeleteHseUserLicenseInput!
    $condition: ModelHseUserLicenseConditionInput
  ) {
    deleteHseUserLicense(input: $input, condition: $condition) {
      id
      userId
      licenseId
      issueDate
      expiryDate
      issueState
      restrictions
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserProfileImageList = /* GraphQL */ `
  mutation CreateHseUserProfileImageList(
    $input: CreateHseUserProfileImageListInput!
    $condition: ModelHseUserProfileImageListConditionInput
  ) {
    createHseUserProfileImageList(input: $input, condition: $condition) {
      id
      docId
      userId
      docType
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserProfileImageList = /* GraphQL */ `
  mutation UpdateHseUserProfileImageList(
    $input: UpdateHseUserProfileImageListInput!
    $condition: ModelHseUserProfileImageListConditionInput
  ) {
    updateHseUserProfileImageList(input: $input, condition: $condition) {
      id
      docId
      userId
      docType
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserProfileImageList = /* GraphQL */ `
  mutation DeleteHseUserProfileImageList(
    $input: DeleteHseUserProfileImageListInput!
    $condition: ModelHseUserProfileImageListConditionInput
  ) {
    deleteHseUserProfileImageList(input: $input, condition: $condition) {
      id
      docId
      userId
      docType
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInduction = /* GraphQL */ `
  mutation CreateInduction(
    $input: CreateInductionInput!
    $condition: ModelInductionConditionInput
  ) {
    createInduction(input: $input, condition: $condition) {
      id
      name
      description
      inductionImage
      inductionImagePath
      videoFile
      videoFilePath
      Evaluation
      completionDeadline
      courseValidity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInduction = /* GraphQL */ `
  mutation UpdateInduction(
    $input: UpdateInductionInput!
    $condition: ModelInductionConditionInput
  ) {
    updateInduction(input: $input, condition: $condition) {
      id
      name
      description
      inductionImage
      inductionImagePath
      videoFile
      videoFilePath
      Evaluation
      completionDeadline
      courseValidity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInduction = /* GraphQL */ `
  mutation DeleteInduction(
    $input: DeleteInductionInput!
    $condition: ModelInductionConditionInput
  ) {
    deleteInduction(input: $input, condition: $condition) {
      id
      name
      description
      inductionImage
      inductionImagePath
      videoFile
      videoFilePath
      Evaluation
      completionDeadline
      courseValidity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseInductionUser = /* GraphQL */ `
  mutation CreateHseInductionUser(
    $input: CreateHseInductionUserInput!
    $condition: ModelHseInductionUserConditionInput
  ) {
    createHseInductionUser(input: $input, condition: $condition) {
      id
      inductionId
      userId
      completionStatus
      enrollDateTime
      completionDateTime
      completedPercentage
      completionDeadline
      expiryDate
      isDeleted
      Induction {
        items {
          id
          name
          description
          inductionImage
          inductionImagePath
          videoFile
          videoFilePath
          Evaluation
          completionDeadline
          courseValidity
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseInductionUser = /* GraphQL */ `
  mutation UpdateHseInductionUser(
    $input: UpdateHseInductionUserInput!
    $condition: ModelHseInductionUserConditionInput
  ) {
    updateHseInductionUser(input: $input, condition: $condition) {
      id
      inductionId
      userId
      completionStatus
      enrollDateTime
      completionDateTime
      completedPercentage
      completionDeadline
      expiryDate
      isDeleted
      Induction {
        items {
          id
          name
          description
          inductionImage
          inductionImagePath
          videoFile
          videoFilePath
          Evaluation
          completionDeadline
          courseValidity
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseInductionUser = /* GraphQL */ `
  mutation DeleteHseInductionUser(
    $input: DeleteHseInductionUserInput!
    $condition: ModelHseInductionUserConditionInput
  ) {
    deleteHseInductionUser(input: $input, condition: $condition) {
      id
      inductionId
      userId
      completionStatus
      enrollDateTime
      completionDateTime
      completedPercentage
      completionDeadline
      expiryDate
      isDeleted
      Induction {
        items {
          id
          name
          description
          inductionImage
          inductionImagePath
          videoFile
          videoFilePath
          Evaluation
          completionDeadline
          courseValidity
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseProject = /* GraphQL */ `
  mutation CreateHseProject(
    $input: CreateHseProjectInput!
    $condition: ModelHseProjectConditionInput
  ) {
    createHseProject(input: $input, condition: $condition) {
      id
      projectName
      projectNumber
      projectUrl
      isArchived
      State
      projectInduction
      kpiData
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseProject = /* GraphQL */ `
  mutation UpdateHseProject(
    $input: UpdateHseProjectInput!
    $condition: ModelHseProjectConditionInput
  ) {
    updateHseProject(input: $input, condition: $condition) {
      id
      projectName
      projectNumber
      projectUrl
      isArchived
      State
      projectInduction
      kpiData
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseProject = /* GraphQL */ `
  mutation DeleteHseProject(
    $input: DeleteHseProjectInput!
    $condition: ModelHseProjectConditionInput
  ) {
    deleteHseProject(input: $input, condition: $condition) {
      id
      projectName
      projectNumber
      projectUrl
      isArchived
      State
      projectInduction
      kpiData
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserRole = /* GraphQL */ `
  mutation CreateHseUserRole(
    $input: CreateHseUserRoleInput!
    $condition: ModelHseUserRoleConditionInput
  ) {
    createHseUserRole(input: $input, condition: $condition) {
      id
      name
      manageIncident
      manageEvent
      manageStky
      manageTake5
      manageJourneyPlanner
      manageHazardObservation
      manageInspection
      manageEnrollInduction
      manageAddInduction
      manageDashboard
      manageRegister
      manageUser
      manageProject
      manageConfiguration
      manageSWMS
      isAvailableForSubcontractor
      managePlantCheckin
      manageEquipmentCheckin
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserRole = /* GraphQL */ `
  mutation UpdateHseUserRole(
    $input: UpdateHseUserRoleInput!
    $condition: ModelHseUserRoleConditionInput
  ) {
    updateHseUserRole(input: $input, condition: $condition) {
      id
      name
      manageIncident
      manageEvent
      manageStky
      manageTake5
      manageJourneyPlanner
      manageHazardObservation
      manageInspection
      manageEnrollInduction
      manageAddInduction
      manageDashboard
      manageRegister
      manageUser
      manageProject
      manageConfiguration
      manageSWMS
      isAvailableForSubcontractor
      managePlantCheckin
      manageEquipmentCheckin
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserRole = /* GraphQL */ `
  mutation DeleteHseUserRole(
    $input: DeleteHseUserRoleInput!
    $condition: ModelHseUserRoleConditionInput
  ) {
    deleteHseUserRole(input: $input, condition: $condition) {
      id
      name
      manageIncident
      manageEvent
      manageStky
      manageTake5
      manageJourneyPlanner
      manageHazardObservation
      manageInspection
      manageEnrollInduction
      manageAddInduction
      manageDashboard
      manageRegister
      manageUser
      manageProject
      manageConfiguration
      manageSWMS
      isAvailableForSubcontractor
      managePlantCheckin
      manageEquipmentCheckin
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseProjectUserDetails = /* GraphQL */ `
  mutation CreateHseProjectUserDetails(
    $input: CreateHseProjectUserDetailsInput!
    $condition: ModelHseProjectUserDetailsConditionInput
  ) {
    createHseProjectUserDetails(input: $input, condition: $condition) {
      id
      projectId
      userId
      userName
      roleId
      sendAlert
      archivedProject
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userRole {
        items {
          id
          name
          manageIncident
          manageEvent
          manageStky
          manageTake5
          manageJourneyPlanner
          manageHazardObservation
          manageInspection
          manageEnrollInduction
          manageAddInduction
          manageDashboard
          manageRegister
          manageUser
          manageProject
          manageConfiguration
          manageSWMS
          isAvailableForSubcontractor
          managePlantCheckin
          manageEquipmentCheckin
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseProjectUserDetails = /* GraphQL */ `
  mutation UpdateHseProjectUserDetails(
    $input: UpdateHseProjectUserDetailsInput!
    $condition: ModelHseProjectUserDetailsConditionInput
  ) {
    updateHseProjectUserDetails(input: $input, condition: $condition) {
      id
      projectId
      userId
      userName
      roleId
      sendAlert
      archivedProject
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userRole {
        items {
          id
          name
          manageIncident
          manageEvent
          manageStky
          manageTake5
          manageJourneyPlanner
          manageHazardObservation
          manageInspection
          manageEnrollInduction
          manageAddInduction
          manageDashboard
          manageRegister
          manageUser
          manageProject
          manageConfiguration
          manageSWMS
          isAvailableForSubcontractor
          managePlantCheckin
          manageEquipmentCheckin
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseProjectUserDetails = /* GraphQL */ `
  mutation DeleteHseProjectUserDetails(
    $input: DeleteHseProjectUserDetailsInput!
    $condition: ModelHseProjectUserDetailsConditionInput
  ) {
    deleteHseProjectUserDetails(input: $input, condition: $condition) {
      id
      projectId
      userId
      userName
      roleId
      sendAlert
      archivedProject
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userRole {
        items {
          id
          name
          manageIncident
          manageEvent
          manageStky
          manageTake5
          manageJourneyPlanner
          manageHazardObservation
          manageInspection
          manageEnrollInduction
          manageAddInduction
          manageDashboard
          manageRegister
          manageUser
          manageProject
          manageConfiguration
          manageSWMS
          isAvailableForSubcontractor
          managePlantCheckin
          manageEquipmentCheckin
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseProjectSignOnOff = /* GraphQL */ `
  mutation CreateHseProjectSignOnOff(
    $input: CreateHseProjectSignOnOffInput!
    $condition: ModelHseProjectSignOnOffConditionInput
  ) {
    createHseProjectSignOnOff(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      name
      signOnDateTimeAt
      signOffDateTimeAt
      signOnByUserId
      signOnByUserName
      signOffByUserId
      signOffByUserName
      SignedOnInToProjectId
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseProjectSignOnOff = /* GraphQL */ `
  mutation UpdateHseProjectSignOnOff(
    $input: UpdateHseProjectSignOnOffInput!
    $condition: ModelHseProjectSignOnOffConditionInput
  ) {
    updateHseProjectSignOnOff(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      name
      signOnDateTimeAt
      signOffDateTimeAt
      signOnByUserId
      signOnByUserName
      signOffByUserId
      signOffByUserName
      SignedOnInToProjectId
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseProjectSignOnOff = /* GraphQL */ `
  mutation DeleteHseProjectSignOnOff(
    $input: DeleteHseProjectSignOnOffInput!
    $condition: ModelHseProjectSignOnOffConditionInput
  ) {
    deleteHseProjectSignOnOff(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      name
      signOnDateTimeAt
      signOffDateTimeAt
      signOnByUserId
      signOnByUserName
      signOffByUserId
      signOffByUserName
      SignedOnInToProjectId
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseOrganisation = /* GraphQL */ `
  mutation CreateHseOrganisation(
    $input: CreateHseOrganisationInput!
    $condition: ModelHseOrganisationConditionInput
  ) {
    createHseOrganisation(input: $input, condition: $condition) {
      id
      name
      organizationInduction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseOrganisation = /* GraphQL */ `
  mutation UpdateHseOrganisation(
    $input: UpdateHseOrganisationInput!
    $condition: ModelHseOrganisationConditionInput
  ) {
    updateHseOrganisation(input: $input, condition: $condition) {
      id
      name
      organizationInduction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseOrganisation = /* GraphQL */ `
  mutation DeleteHseOrganisation(
    $input: DeleteHseOrganisationInput!
    $condition: ModelHseOrganisationConditionInput
  ) {
    deleteHseOrganisation(input: $input, condition: $condition) {
      id
      name
      organizationInduction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserOrganisation = /* GraphQL */ `
  mutation CreateHseUserOrganisation(
    $input: CreateHseUserOrganisationInput!
    $condition: ModelHseUserOrganisationConditionInput
  ) {
    createHseUserOrganisation(input: $input, condition: $condition) {
      id
      userId
      userName
      organisationId
      roleId
      roleName
      email
      phoneNumber
      status
      isCurrent
      isEnabled
      isApproved
      approvedBy
      approvedDate
      sentForApprovalDate
      transferRequestedBy
      createdBy
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserOrganisation = /* GraphQL */ `
  mutation UpdateHseUserOrganisation(
    $input: UpdateHseUserOrganisationInput!
    $condition: ModelHseUserOrganisationConditionInput
  ) {
    updateHseUserOrganisation(input: $input, condition: $condition) {
      id
      userId
      userName
      organisationId
      roleId
      roleName
      email
      phoneNumber
      status
      isCurrent
      isEnabled
      isApproved
      approvedBy
      approvedDate
      sentForApprovalDate
      transferRequestedBy
      createdBy
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserOrganisation = /* GraphQL */ `
  mutation DeleteHseUserOrganisation(
    $input: DeleteHseUserOrganisationInput!
    $condition: ModelHseUserOrganisationConditionInput
  ) {
    deleteHseUserOrganisation(input: $input, condition: $condition) {
      id
      userId
      userName
      organisationId
      roleId
      roleName
      email
      phoneNumber
      status
      isCurrent
      isEnabled
      isApproved
      approvedBy
      approvedDate
      sentForApprovalDate
      transferRequestedBy
      createdBy
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseConfiguration = /* GraphQL */ `
  mutation CreateHseConfiguration(
    $input: CreateHseConfigurationInput!
    $condition: ModelHseConfigurationConditionInput
  ) {
    createHseConfiguration(input: $input, condition: $condition) {
      id
      type
      value
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseConfiguration = /* GraphQL */ `
  mutation UpdateHseConfiguration(
    $input: UpdateHseConfigurationInput!
    $condition: ModelHseConfigurationConditionInput
  ) {
    updateHseConfiguration(input: $input, condition: $condition) {
      id
      type
      value
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseConfiguration = /* GraphQL */ `
  mutation DeleteHseConfiguration(
    $input: DeleteHseConfigurationInput!
    $condition: ModelHseConfigurationConditionInput
  ) {
    deleteHseConfiguration(input: $input, condition: $condition) {
      id
      type
      value
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserProjectFatigue = /* GraphQL */ `
  mutation CreateHseUserProjectFatigue(
    $input: CreateHseUserProjectFatigueInput!
    $condition: ModelHseUserProjectFatigueConditionInput
  ) {
    createHseUserProjectFatigue(input: $input, condition: $condition) {
      id
      userId
      projectId
      sleepIn24Hours
      sleepInPrevious24Hours
      createdAt
      awokeTodayAt
      FatigueScore
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserProjectFatigue = /* GraphQL */ `
  mutation UpdateHseUserProjectFatigue(
    $input: UpdateHseUserProjectFatigueInput!
    $condition: ModelHseUserProjectFatigueConditionInput
  ) {
    updateHseUserProjectFatigue(input: $input, condition: $condition) {
      id
      userId
      projectId
      sleepIn24Hours
      sleepInPrevious24Hours
      createdAt
      awokeTodayAt
      FatigueScore
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserProjectFatigue = /* GraphQL */ `
  mutation DeleteHseUserProjectFatigue(
    $input: DeleteHseUserProjectFatigueInput!
    $condition: ModelHseUserProjectFatigueConditionInput
  ) {
    deleteHseUserProjectFatigue(input: $input, condition: $condition) {
      id
      userId
      projectId
      sleepIn24Hours
      sleepInPrevious24Hours
      createdAt
      awokeTodayAt
      FatigueScore
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseFormImage = /* GraphQL */ `
  mutation CreateHseFormImage(
    $input: CreateHseFormImageInput!
    $condition: ModelHseFormImageConditionInput
  ) {
    createHseFormImage(input: $input, condition: $condition) {
      id
      formId
      userId
      formType
      formStatus
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseFormImage = /* GraphQL */ `
  mutation UpdateHseFormImage(
    $input: UpdateHseFormImageInput!
    $condition: ModelHseFormImageConditionInput
  ) {
    updateHseFormImage(input: $input, condition: $condition) {
      id
      formId
      userId
      formType
      formStatus
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseFormImage = /* GraphQL */ `
  mutation DeleteHseFormImage(
    $input: DeleteHseFormImageInput!
    $condition: ModelHseFormImageConditionInput
  ) {
    deleteHseFormImage(input: $input, condition: $condition) {
      id
      formId
      userId
      formType
      formStatus
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseTake5EnergySourceAndHazard = /* GraphQL */ `
  mutation CreateHseTake5EnergySourceAndHazard(
    $input: CreateHseTake5EnergySourceAndHazardInput!
    $condition: ModelHseTake5EnergySourceAndHazardConditionInput
  ) {
    createHseTake5EnergySourceAndHazard(input: $input, condition: $condition) {
      id
      energySource
      hazard
      hazardDescription
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseTake5EnergySourceAndHazard = /* GraphQL */ `
  mutation UpdateHseTake5EnergySourceAndHazard(
    $input: UpdateHseTake5EnergySourceAndHazardInput!
    $condition: ModelHseTake5EnergySourceAndHazardConditionInput
  ) {
    updateHseTake5EnergySourceAndHazard(input: $input, condition: $condition) {
      id
      energySource
      hazard
      hazardDescription
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseTake5EnergySourceAndHazard = /* GraphQL */ `
  mutation DeleteHseTake5EnergySourceAndHazard(
    $input: DeleteHseTake5EnergySourceAndHazardInput!
    $condition: ModelHseTake5EnergySourceAndHazardConditionInput
  ) {
    deleteHseTake5EnergySourceAndHazard(input: $input, condition: $condition) {
      id
      energySource
      hazard
      hazardDescription
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseProjectTake5Assessment = /* GraphQL */ `
  mutation CreateHseProjectTake5Assessment(
    $input: CreateHseProjectTake5AssessmentInput!
    $condition: ModelHseProjectTake5AssessmentConditionInput
  ) {
    createHseProjectTake5Assessment(input: $input, condition: $condition) {
      id
      projectId
      userId
      documentId
      Status
      jobName
      location
      locationTag
      isStky
      adequateControls
      additionalControls
      isOtherHazards
      OtherHazards
      isDeleted
      createdAt
      selectedEnergySources
      EnergySourceHazard {
        id
        energySource
        hazard
        isDeleted
        createdAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseProjectTake5Assessment = /* GraphQL */ `
  mutation UpdateHseProjectTake5Assessment(
    $input: UpdateHseProjectTake5AssessmentInput!
    $condition: ModelHseProjectTake5AssessmentConditionInput
  ) {
    updateHseProjectTake5Assessment(input: $input, condition: $condition) {
      id
      projectId
      userId
      documentId
      Status
      jobName
      location
      locationTag
      isStky
      adequateControls
      additionalControls
      isOtherHazards
      OtherHazards
      isDeleted
      createdAt
      selectedEnergySources
      EnergySourceHazard {
        id
        energySource
        hazard
        isDeleted
        createdAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseProjectTake5Assessment = /* GraphQL */ `
  mutation DeleteHseProjectTake5Assessment(
    $input: DeleteHseProjectTake5AssessmentInput!
    $condition: ModelHseProjectTake5AssessmentConditionInput
  ) {
    deleteHseProjectTake5Assessment(input: $input, condition: $condition) {
      id
      projectId
      userId
      documentId
      Status
      jobName
      location
      locationTag
      isStky
      adequateControls
      additionalControls
      isOtherHazards
      OtherHazards
      isDeleted
      createdAt
      selectedEnergySources
      EnergySourceHazard {
        id
        energySource
        hazard
        isDeleted
        createdAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseTake5AssessmentCrewSignature = /* GraphQL */ `
  mutation CreateHseTake5AssessmentCrewSignature(
    $input: CreateHseTake5AssessmentCrewSignatureInput!
    $condition: ModelHseTake5AssessmentCrewSignatureConditionInput
  ) {
    createHseTake5AssessmentCrewSignature(
      input: $input
      condition: $condition
    ) {
      id
      take5id
      userId
      projectId
      signatureInBase64
      isDeleted
      Take5Assessment {
        items {
          id
          projectId
          userId
          documentId
          Status
          jobName
          location
          locationTag
          isStky
          adequateControls
          additionalControls
          isOtherHazards
          OtherHazards
          isDeleted
          createdAt
          selectedEnergySources
          EnergySourceHazard {
            id
            energySource
            hazard
            isDeleted
            createdAt
          }
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseTake5AssessmentCrewSignature = /* GraphQL */ `
  mutation UpdateHseTake5AssessmentCrewSignature(
    $input: UpdateHseTake5AssessmentCrewSignatureInput!
    $condition: ModelHseTake5AssessmentCrewSignatureConditionInput
  ) {
    updateHseTake5AssessmentCrewSignature(
      input: $input
      condition: $condition
    ) {
      id
      take5id
      userId
      projectId
      signatureInBase64
      isDeleted
      Take5Assessment {
        items {
          id
          projectId
          userId
          documentId
          Status
          jobName
          location
          locationTag
          isStky
          adequateControls
          additionalControls
          isOtherHazards
          OtherHazards
          isDeleted
          createdAt
          selectedEnergySources
          EnergySourceHazard {
            id
            energySource
            hazard
            isDeleted
            createdAt
          }
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseTake5AssessmentCrewSignature = /* GraphQL */ `
  mutation DeleteHseTake5AssessmentCrewSignature(
    $input: DeleteHseTake5AssessmentCrewSignatureInput!
    $condition: ModelHseTake5AssessmentCrewSignatureConditionInput
  ) {
    deleteHseTake5AssessmentCrewSignature(
      input: $input
      condition: $condition
    ) {
      id
      take5id
      userId
      projectId
      signatureInBase64
      isDeleted
      Take5Assessment {
        items {
          id
          projectId
          userId
          documentId
          Status
          jobName
          location
          locationTag
          isStky
          adequateControls
          additionalControls
          isOtherHazards
          OtherHazards
          isDeleted
          createdAt
          selectedEnergySources
          EnergySourceHazard {
            id
            energySource
            hazard
            isDeleted
            createdAt
          }
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseIncidentForm = /* GraphQL */ `
  mutation CreateHseIncidentForm(
    $input: CreateHseIncidentFormInput!
    $condition: ModelHseIncidentFormConditionInput
  ) {
    createHseIncidentForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      reportingItems
      incidentTitle
      environmentalImpact
      incidentLocation
      locationTag
      incidentDateTime
      taskDetails
      actualConsequence
      potentialConsequence
      isStky
      stkyCategory
      incidentSource
      incidentDescription
      immediateAction
      rootCause
      mobilePlant
      plantOperator
      selectedEquipment
      equipmentOperator
      cppVehicleRegistrationNumber
      vehicleOtherDetails
      cppOperator
      driverLicenceNumber
      thirdPartyOperatorName
      thirdPartyRegistrationNumber
      otherPropertyDetails
      policeReportDateTime
      policeReportReferenceNo
      formStatus
      nameOfSupervisor
      personOnSiteAtTimeOfIncident
      isWitnessStatementTaken
      isDeleted
      injuryDetails {
        injuredPerson
        firstAider
        describeInjury
        describeFirstAid
        bodySelector
        taskExperience
        shiftCompleted
        dutyStatusAtTimeOfEvent
        refDoctorContactDetails
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseIncidentForm = /* GraphQL */ `
  mutation UpdateHseIncidentForm(
    $input: UpdateHseIncidentFormInput!
    $condition: ModelHseIncidentFormConditionInput
  ) {
    updateHseIncidentForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      reportingItems
      incidentTitle
      environmentalImpact
      incidentLocation
      locationTag
      incidentDateTime
      taskDetails
      actualConsequence
      potentialConsequence
      isStky
      stkyCategory
      incidentSource
      incidentDescription
      immediateAction
      rootCause
      mobilePlant
      plantOperator
      selectedEquipment
      equipmentOperator
      cppVehicleRegistrationNumber
      vehicleOtherDetails
      cppOperator
      driverLicenceNumber
      thirdPartyOperatorName
      thirdPartyRegistrationNumber
      otherPropertyDetails
      policeReportDateTime
      policeReportReferenceNo
      formStatus
      nameOfSupervisor
      personOnSiteAtTimeOfIncident
      isWitnessStatementTaken
      isDeleted
      injuryDetails {
        injuredPerson
        firstAider
        describeInjury
        describeFirstAid
        bodySelector
        taskExperience
        shiftCompleted
        dutyStatusAtTimeOfEvent
        refDoctorContactDetails
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseIncidentForm = /* GraphQL */ `
  mutation DeleteHseIncidentForm(
    $input: DeleteHseIncidentFormInput!
    $condition: ModelHseIncidentFormConditionInput
  ) {
    deleteHseIncidentForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      reportingItems
      incidentTitle
      environmentalImpact
      incidentLocation
      locationTag
      incidentDateTime
      taskDetails
      actualConsequence
      potentialConsequence
      isStky
      stkyCategory
      incidentSource
      incidentDescription
      immediateAction
      rootCause
      mobilePlant
      plantOperator
      selectedEquipment
      equipmentOperator
      cppVehicleRegistrationNumber
      vehicleOtherDetails
      cppOperator
      driverLicenceNumber
      thirdPartyOperatorName
      thirdPartyRegistrationNumber
      otherPropertyDetails
      policeReportDateTime
      policeReportReferenceNo
      formStatus
      nameOfSupervisor
      personOnSiteAtTimeOfIncident
      isWitnessStatementTaken
      isDeleted
      injuryDetails {
        injuredPerson
        firstAider
        describeInjury
        describeFirstAid
        bodySelector
        taskExperience
        shiftCompleted
        dutyStatusAtTimeOfEvent
        refDoctorContactDetails
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseEventForm = /* GraphQL */ `
  mutation CreateHseEventForm(
    $input: CreateHseEventFormInput!
    $condition: ModelHseEventFormConditionInput
  ) {
    createHseEventForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      eventTitle
      reportingItems
      eventLocation
      locationTag
      eventDateTime
      taskDetails
      isDeleted
      eventDescription
      immediateAction
      formStatus
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseEventForm = /* GraphQL */ `
  mutation UpdateHseEventForm(
    $input: UpdateHseEventFormInput!
    $condition: ModelHseEventFormConditionInput
  ) {
    updateHseEventForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      eventTitle
      reportingItems
      eventLocation
      locationTag
      eventDateTime
      taskDetails
      isDeleted
      eventDescription
      immediateAction
      formStatus
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseEventForm = /* GraphQL */ `
  mutation DeleteHseEventForm(
    $input: DeleteHseEventFormInput!
    $condition: ModelHseEventFormConditionInput
  ) {
    deleteHseEventForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      eventTitle
      reportingItems
      eventLocation
      locationTag
      eventDateTime
      taskDetails
      isDeleted
      eventDescription
      immediateAction
      formStatus
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseStkyEngagementForm = /* GraphQL */ `
  mutation CreateHseStkyEngagementForm(
    $input: CreateHseStkyEngagementFormInput!
    $condition: ModelHseStkyEngagementFormConditionInput
  ) {
    createHseStkyEngagementForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      jobName
      participants
      energySourceByLeader
      energySourceByCrew
      unknownHazard
      otherHazards
      control
      discuss
      feedback
      formStatus
      stkyLocation
      locationTag
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseStkyEngagementForm = /* GraphQL */ `
  mutation UpdateHseStkyEngagementForm(
    $input: UpdateHseStkyEngagementFormInput!
    $condition: ModelHseStkyEngagementFormConditionInput
  ) {
    updateHseStkyEngagementForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      jobName
      participants
      energySourceByLeader
      energySourceByCrew
      unknownHazard
      otherHazards
      control
      discuss
      feedback
      formStatus
      stkyLocation
      locationTag
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseStkyEngagementForm = /* GraphQL */ `
  mutation DeleteHseStkyEngagementForm(
    $input: DeleteHseStkyEngagementFormInput!
    $condition: ModelHseStkyEngagementFormConditionInput
  ) {
    deleteHseStkyEngagementForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      jobName
      participants
      energySourceByLeader
      energySourceByCrew
      unknownHazard
      otherHazards
      control
      discuss
      feedback
      formStatus
      stkyLocation
      locationTag
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserActionForm = /* GraphQL */ `
  mutation CreateHseUserActionForm(
    $input: CreateHseUserActionFormInput!
    $condition: ModelHseUserActionFormConditionInput
  ) {
    createHseUserActionForm(input: $input, condition: $condition) {
      id
      formId
      customActionID
      projectNumber
      formName
      taskTitle
      taskDescription
      taskStatus
      dueDate
      responsiblePerson
      completedBy
      reportedBy
      comments
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserActionForm = /* GraphQL */ `
  mutation UpdateHseUserActionForm(
    $input: UpdateHseUserActionFormInput!
    $condition: ModelHseUserActionFormConditionInput
  ) {
    updateHseUserActionForm(input: $input, condition: $condition) {
      id
      formId
      customActionID
      projectNumber
      formName
      taskTitle
      taskDescription
      taskStatus
      dueDate
      responsiblePerson
      completedBy
      reportedBy
      comments
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserActionForm = /* GraphQL */ `
  mutation DeleteHseUserActionForm(
    $input: DeleteHseUserActionFormInput!
    $condition: ModelHseUserActionFormConditionInput
  ) {
    deleteHseUserActionForm(input: $input, condition: $condition) {
      id
      formId
      customActionID
      projectNumber
      formName
      taskTitle
      taskDescription
      taskStatus
      dueDate
      responsiblePerson
      completedBy
      reportedBy
      comments
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserActionImages = /* GraphQL */ `
  mutation CreateHseUserActionImages(
    $input: CreateHseUserActionImagesInput!
    $condition: ModelHseUserActionImagesConditionInput
  ) {
    createHseUserActionImages(input: $input, condition: $condition) {
      id
      actionId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      ActionForm {
        items {
          id
          formId
          customActionID
          projectNumber
          formName
          taskTitle
          taskDescription
          taskStatus
          dueDate
          responsiblePerson
          completedBy
          reportedBy
          comments
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserActionImages = /* GraphQL */ `
  mutation UpdateHseUserActionImages(
    $input: UpdateHseUserActionImagesInput!
    $condition: ModelHseUserActionImagesConditionInput
  ) {
    updateHseUserActionImages(input: $input, condition: $condition) {
      id
      actionId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      ActionForm {
        items {
          id
          formId
          customActionID
          projectNumber
          formName
          taskTitle
          taskDescription
          taskStatus
          dueDate
          responsiblePerson
          completedBy
          reportedBy
          comments
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserActionImages = /* GraphQL */ `
  mutation DeleteHseUserActionImages(
    $input: DeleteHseUserActionImagesInput!
    $condition: ModelHseUserActionImagesConditionInput
  ) {
    deleteHseUserActionImages(input: $input, condition: $condition) {
      id
      actionId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      ActionForm {
        items {
          id
          formId
          customActionID
          projectNumber
          formName
          taskTitle
          taskDescription
          taskStatus
          dueDate
          responsiblePerson
          completedBy
          reportedBy
          comments
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseInspectionTemplate = /* GraphQL */ `
  mutation CreateHseInspectionTemplate(
    $input: CreateHseInspectionTemplateInput!
    $condition: ModelHseInspectionTemplateConditionInput
  ) {
    createHseInspectionTemplate(input: $input, condition: $condition) {
      id
      name
      type
      description
      userId
      occurrence
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseInspectionTemplate = /* GraphQL */ `
  mutation UpdateHseInspectionTemplate(
    $input: UpdateHseInspectionTemplateInput!
    $condition: ModelHseInspectionTemplateConditionInput
  ) {
    updateHseInspectionTemplate(input: $input, condition: $condition) {
      id
      name
      type
      description
      userId
      occurrence
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseInspectionTemplate = /* GraphQL */ `
  mutation DeleteHseInspectionTemplate(
    $input: DeleteHseInspectionTemplateInput!
    $condition: ModelHseInspectionTemplateConditionInput
  ) {
    deleteHseInspectionTemplate(input: $input, condition: $condition) {
      id
      name
      type
      description
      userId
      occurrence
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseInspectionTemplateSection = /* GraphQL */ `
  mutation CreateHseInspectionTemplateSection(
    $input: CreateHseInspectionTemplateSectionInput!
    $condition: ModelHseInspectionTemplateSectionConditionInput
  ) {
    createHseInspectionTemplateSection(input: $input, condition: $condition) {
      id
      name
      sequenceId
      templateId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseInspectionTemplateSection = /* GraphQL */ `
  mutation UpdateHseInspectionTemplateSection(
    $input: UpdateHseInspectionTemplateSectionInput!
    $condition: ModelHseInspectionTemplateSectionConditionInput
  ) {
    updateHseInspectionTemplateSection(input: $input, condition: $condition) {
      id
      name
      sequenceId
      templateId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseInspectionTemplateSection = /* GraphQL */ `
  mutation DeleteHseInspectionTemplateSection(
    $input: DeleteHseInspectionTemplateSectionInput!
    $condition: ModelHseInspectionTemplateSectionConditionInput
  ) {
    deleteHseInspectionTemplateSection(input: $input, condition: $condition) {
      id
      name
      sequenceId
      templateId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseInspectionTemplateQuestion = /* GraphQL */ `
  mutation CreateHseInspectionTemplateQuestion(
    $input: CreateHseInspectionTemplateQuestionInput!
    $condition: ModelHseInspectionTemplateQuestionConditionInput
  ) {
    createHseInspectionTemplateQuestion(input: $input, condition: $condition) {
      id
      question
      sequenceId
      isRequired
      controlType
      controlData
      templateId
      sectionId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateSection {
        items {
          id
          name
          sequenceId
          templateId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseInspectionTemplateQuestion = /* GraphQL */ `
  mutation UpdateHseInspectionTemplateQuestion(
    $input: UpdateHseInspectionTemplateQuestionInput!
    $condition: ModelHseInspectionTemplateQuestionConditionInput
  ) {
    updateHseInspectionTemplateQuestion(input: $input, condition: $condition) {
      id
      question
      sequenceId
      isRequired
      controlType
      controlData
      templateId
      sectionId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateSection {
        items {
          id
          name
          sequenceId
          templateId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseInspectionTemplateQuestion = /* GraphQL */ `
  mutation DeleteHseInspectionTemplateQuestion(
    $input: DeleteHseInspectionTemplateQuestionInput!
    $condition: ModelHseInspectionTemplateQuestionConditionInput
  ) {
    deleteHseInspectionTemplateQuestion(input: $input, condition: $condition) {
      id
      question
      sequenceId
      isRequired
      controlType
      controlData
      templateId
      sectionId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateSection {
        items {
          id
          name
          sequenceId
          templateId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserInspection = /* GraphQL */ `
  mutation CreateHseUserInspection(
    $input: CreateHseUserInspectionInput!
    $condition: ModelHseUserInspectionConditionInput
  ) {
    createHseUserInspection(input: $input, condition: $condition) {
      id
      templateId
      templateName
      templateType
      inspectionId
      projectId
      userId
      createdAt
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserInspection = /* GraphQL */ `
  mutation UpdateHseUserInspection(
    $input: UpdateHseUserInspectionInput!
    $condition: ModelHseUserInspectionConditionInput
  ) {
    updateHseUserInspection(input: $input, condition: $condition) {
      id
      templateId
      templateName
      templateType
      inspectionId
      projectId
      userId
      createdAt
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserInspection = /* GraphQL */ `
  mutation DeleteHseUserInspection(
    $input: DeleteHseUserInspectionInput!
    $condition: ModelHseUserInspectionConditionInput
  ) {
    deleteHseUserInspection(input: $input, condition: $condition) {
      id
      templateId
      templateName
      templateType
      inspectionId
      projectId
      userId
      createdAt
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserInspectionData = /* GraphQL */ `
  mutation CreateHseUserInspectionData(
    $input: CreateHseUserInspectionDataInput!
    $condition: ModelHseUserInspectionDataConditionInput
  ) {
    createHseUserInspectionData(input: $input, condition: $condition) {
      id
      userInspectionId
      templateQuestionId
      controlValue
      comment
      isDeleted
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateQuestion {
        items {
          id
          question
          sequenceId
          isRequired
          controlType
          controlData
          templateId
          sectionId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateSection {
            items {
              id
              name
              sequenceId
              templateId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserInspectionData = /* GraphQL */ `
  mutation UpdateHseUserInspectionData(
    $input: UpdateHseUserInspectionDataInput!
    $condition: ModelHseUserInspectionDataConditionInput
  ) {
    updateHseUserInspectionData(input: $input, condition: $condition) {
      id
      userInspectionId
      templateQuestionId
      controlValue
      comment
      isDeleted
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateQuestion {
        items {
          id
          question
          sequenceId
          isRequired
          controlType
          controlData
          templateId
          sectionId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateSection {
            items {
              id
              name
              sequenceId
              templateId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserInspectionData = /* GraphQL */ `
  mutation DeleteHseUserInspectionData(
    $input: DeleteHseUserInspectionDataInput!
    $condition: ModelHseUserInspectionDataConditionInput
  ) {
    deleteHseUserInspectionData(input: $input, condition: $condition) {
      id
      userInspectionId
      templateQuestionId
      controlValue
      comment
      isDeleted
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateQuestion {
        items {
          id
          question
          sequenceId
          isRequired
          controlType
          controlData
          templateId
          sectionId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateSection {
            items {
              id
              name
              sequenceId
              templateId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserInspectionFiles = /* GraphQL */ `
  mutation CreateHseUserInspectionFiles(
    $input: CreateHseUserInspectionFilesInput!
    $condition: ModelHseUserInspectionFilesConditionInput
  ) {
    createHseUserInspectionFiles(input: $input, condition: $condition) {
      id
      userInspectionId
      userInspectionDataId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseUserInspectionData {
        items {
          id
          userInspectionId
          templateQuestionId
          controlValue
          comment
          isDeleted
          createdAt
          updatedAt
          HseUserInspection {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateQuestion {
            items {
              id
              question
              sequenceId
              isRequired
              controlType
              controlData
              templateId
              sectionId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplateSection {
                items {
                  id
                  name
                  sequenceId
                  templateId
                  isDeleted
                  HseInspectionTemplate {
                    items {
                      id
                      name
                      type
                      description
                      userId
                      occurrence
                      isDeleted
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserInspectionFiles = /* GraphQL */ `
  mutation UpdateHseUserInspectionFiles(
    $input: UpdateHseUserInspectionFilesInput!
    $condition: ModelHseUserInspectionFilesConditionInput
  ) {
    updateHseUserInspectionFiles(input: $input, condition: $condition) {
      id
      userInspectionId
      userInspectionDataId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseUserInspectionData {
        items {
          id
          userInspectionId
          templateQuestionId
          controlValue
          comment
          isDeleted
          createdAt
          updatedAt
          HseUserInspection {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateQuestion {
            items {
              id
              question
              sequenceId
              isRequired
              controlType
              controlData
              templateId
              sectionId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplateSection {
                items {
                  id
                  name
                  sequenceId
                  templateId
                  isDeleted
                  HseInspectionTemplate {
                    items {
                      id
                      name
                      type
                      description
                      userId
                      occurrence
                      isDeleted
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserInspectionFiles = /* GraphQL */ `
  mutation DeleteHseUserInspectionFiles(
    $input: DeleteHseUserInspectionFilesInput!
    $condition: ModelHseUserInspectionFilesConditionInput
  ) {
    deleteHseUserInspectionFiles(input: $input, condition: $condition) {
      id
      userInspectionId
      userInspectionDataId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseUserInspectionData {
        items {
          id
          userInspectionId
          templateQuestionId
          controlValue
          comment
          isDeleted
          createdAt
          updatedAt
          HseUserInspection {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateQuestion {
            items {
              id
              question
              sequenceId
              isRequired
              controlType
              controlData
              templateId
              sectionId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplateSection {
                items {
                  id
                  name
                  sequenceId
                  templateId
                  isDeleted
                  HseInspectionTemplate {
                    items {
                      id
                      name
                      type
                      description
                      userId
                      occurrence
                      isDeleted
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseJourneyPlannerForm = /* GraphQL */ `
  mutation CreateHseJourneyPlannerForm(
    $input: CreateHseJourneyPlannerFormInput!
    $condition: ModelHseJourneyPlannerFormConditionInput
  ) {
    createHseJourneyPlannerForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      journeyStartLocation
      journeyDestinationLocation
      routeDistance
      estimatedTravelTime
      totalTravelTime
      journeyEndTime
      journeyStartTime
      vehicleType
      vehicleMake
      vehicleModel
      vehicleRego
      journeyPurpose
      formStatus
      isDeleted
      createdAt
      journeyBreakPoint {
        placeId
        journeyBreakPointName
        journeyTimeFromBreakPoint
        journeyBreakDuration
        isDeleted
      }
      journeyDriver {
        driverID
        driverFatigueScore
        isDeleted
      }
      journeyPassenger {
        passengerID
        passengerFatigueScore
        isDeleted
      }
      journeyRiskAssessment {
        isLicensed
        isRoutePlanReady
        isFitForWork
        isLoadExceeded
        isPotentialHazards
        potentialHazardsDescription
        isJourneyMoreThan100KM
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseJourneyPlannerForm = /* GraphQL */ `
  mutation UpdateHseJourneyPlannerForm(
    $input: UpdateHseJourneyPlannerFormInput!
    $condition: ModelHseJourneyPlannerFormConditionInput
  ) {
    updateHseJourneyPlannerForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      journeyStartLocation
      journeyDestinationLocation
      routeDistance
      estimatedTravelTime
      totalTravelTime
      journeyEndTime
      journeyStartTime
      vehicleType
      vehicleMake
      vehicleModel
      vehicleRego
      journeyPurpose
      formStatus
      isDeleted
      createdAt
      journeyBreakPoint {
        placeId
        journeyBreakPointName
        journeyTimeFromBreakPoint
        journeyBreakDuration
        isDeleted
      }
      journeyDriver {
        driverID
        driverFatigueScore
        isDeleted
      }
      journeyPassenger {
        passengerID
        passengerFatigueScore
        isDeleted
      }
      journeyRiskAssessment {
        isLicensed
        isRoutePlanReady
        isFitForWork
        isLoadExceeded
        isPotentialHazards
        potentialHazardsDescription
        isJourneyMoreThan100KM
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseJourneyPlannerForm = /* GraphQL */ `
  mutation DeleteHseJourneyPlannerForm(
    $input: DeleteHseJourneyPlannerFormInput!
    $condition: ModelHseJourneyPlannerFormConditionInput
  ) {
    deleteHseJourneyPlannerForm(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      journeyStartLocation
      journeyDestinationLocation
      routeDistance
      estimatedTravelTime
      totalTravelTime
      journeyEndTime
      journeyStartTime
      vehicleType
      vehicleMake
      vehicleModel
      vehicleRego
      journeyPurpose
      formStatus
      isDeleted
      createdAt
      journeyBreakPoint {
        placeId
        journeyBreakPointName
        journeyTimeFromBreakPoint
        journeyBreakDuration
        isDeleted
      }
      journeyDriver {
        driverID
        driverFatigueScore
        isDeleted
      }
      journeyPassenger {
        passengerID
        passengerFatigueScore
        isDeleted
      }
      journeyRiskAssessment {
        isLicensed
        isRoutePlanReady
        isFitForWork
        isLoadExceeded
        isPotentialHazards
        potentialHazardsDescription
        isJourneyMoreThan100KM
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseApprovalManagement = /* GraphQL */ `
  mutation CreateHseApprovalManagement(
    $input: CreateHseApprovalManagementInput!
    $condition: ModelHseApprovalManagementConditionInput
  ) {
    createHseApprovalManagement(input: $input, condition: $condition) {
      id
      projectId
      formName
      approver
      peopleToBeNotified
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseApprovalManagement = /* GraphQL */ `
  mutation UpdateHseApprovalManagement(
    $input: UpdateHseApprovalManagementInput!
    $condition: ModelHseApprovalManagementConditionInput
  ) {
    updateHseApprovalManagement(input: $input, condition: $condition) {
      id
      projectId
      formName
      approver
      peopleToBeNotified
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseApprovalManagement = /* GraphQL */ `
  mutation DeleteHseApprovalManagement(
    $input: DeleteHseApprovalManagementInput!
    $condition: ModelHseApprovalManagementConditionInput
  ) {
    deleteHseApprovalManagement(input: $input, condition: $condition) {
      id
      projectId
      formName
      approver
      peopleToBeNotified
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseUserHazardReports = /* GraphQL */ `
  mutation CreateHseUserHazardReports(
    $input: CreateHseUserHazardReportsInput!
    $condition: ModelHseUserHazardReportsConditionInput
  ) {
    createHseUserHazardReports(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      formName
      reportedDate
      hazardCategory
      isClosed
      observation
      observationInput
      recuiredActionInput
      selectLocation
      locationTag
      takenActionInput
      hazardLocatin
      formStatus
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseUserHazardReports = /* GraphQL */ `
  mutation UpdateHseUserHazardReports(
    $input: UpdateHseUserHazardReportsInput!
    $condition: ModelHseUserHazardReportsConditionInput
  ) {
    updateHseUserHazardReports(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      formName
      reportedDate
      hazardCategory
      isClosed
      observation
      observationInput
      recuiredActionInput
      selectLocation
      locationTag
      takenActionInput
      hazardLocatin
      formStatus
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseUserHazardReports = /* GraphQL */ `
  mutation DeleteHseUserHazardReports(
    $input: DeleteHseUserHazardReportsInput!
    $condition: ModelHseUserHazardReportsConditionInput
  ) {
    deleteHseUserHazardReports(input: $input, condition: $condition) {
      id
      documentId
      projectId
      reportedBy
      formName
      reportedDate
      hazardCategory
      isClosed
      observation
      observationInput
      recuiredActionInput
      selectLocation
      locationTag
      takenActionInput
      hazardLocatin
      formStatus
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHsePlant = /* GraphQL */ `
  mutation CreateHsePlant(
    $input: CreateHsePlantInput!
    $condition: ModelHsePlantConditionInput
  ) {
    createHsePlant(input: $input, condition: $condition) {
      id
      customPlantID
      plantRegistration
      plantType
      plantMake
      organisationId
      status
      operatorsManual
      operatorsManualName
      plantRiskAssessment
      plantRiskAssessmentName
      maintenanceHistory
      maintenanceHistoryName
      plantImage
      attachmentName
      serviceRecords {
        lastServiceMileage
        lastServiceDate
        nextServiceMileage
        nextServiceDate
        createdDate
        serviceRecordImage
        attachmentName
        isDeleted
      }
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      lastInspectionMileage
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHsePlant = /* GraphQL */ `
  mutation UpdateHsePlant(
    $input: UpdateHsePlantInput!
    $condition: ModelHsePlantConditionInput
  ) {
    updateHsePlant(input: $input, condition: $condition) {
      id
      customPlantID
      plantRegistration
      plantType
      plantMake
      organisationId
      status
      operatorsManual
      operatorsManualName
      plantRiskAssessment
      plantRiskAssessmentName
      maintenanceHistory
      maintenanceHistoryName
      plantImage
      attachmentName
      serviceRecords {
        lastServiceMileage
        lastServiceDate
        nextServiceMileage
        nextServiceDate
        createdDate
        serviceRecordImage
        attachmentName
        isDeleted
      }
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      lastInspectionMileage
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHsePlant = /* GraphQL */ `
  mutation DeleteHsePlant(
    $input: DeleteHsePlantInput!
    $condition: ModelHsePlantConditionInput
  ) {
    deleteHsePlant(input: $input, condition: $condition) {
      id
      customPlantID
      plantRegistration
      plantType
      plantMake
      organisationId
      status
      operatorsManual
      operatorsManualName
      plantRiskAssessment
      plantRiskAssessmentName
      maintenanceHistory
      maintenanceHistoryName
      plantImage
      attachmentName
      serviceRecords {
        lastServiceMileage
        lastServiceDate
        nextServiceMileage
        nextServiceDate
        createdDate
        serviceRecordImage
        attachmentName
        isDeleted
      }
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      lastInspectionMileage
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseProjectItemDetail = /* GraphQL */ `
  mutation CreateHseProjectItemDetail(
    $input: CreateHseProjectItemDetailInput!
    $condition: ModelHseProjectItemDetailConditionInput
  ) {
    createHseProjectItemDetail(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      dataName
      assignedByUserId
      assignedByUserName
      sendAlert
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      equipment {
        items {
          id
          customEquipmentID
          equipmentType
          equipmentRegistration
          organisationId
          status
          equipmentImage
          attachmentName
          inspectionFrequency
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      plant {
        items {
          id
          customPlantID
          plantRegistration
          plantType
          plantMake
          organisationId
          status
          operatorsManual
          operatorsManualName
          plantRiskAssessment
          plantRiskAssessmentName
          maintenanceHistory
          maintenanceHistoryName
          plantImage
          attachmentName
          serviceRecords {
            lastServiceMileage
            lastServiceDate
            nextServiceMileage
            nextServiceDate
            createdDate
            serviceRecordImage
            attachmentName
            isDeleted
          }
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          lastInspectionMileage
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseProjectItemDetail = /* GraphQL */ `
  mutation UpdateHseProjectItemDetail(
    $input: UpdateHseProjectItemDetailInput!
    $condition: ModelHseProjectItemDetailConditionInput
  ) {
    updateHseProjectItemDetail(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      dataName
      assignedByUserId
      assignedByUserName
      sendAlert
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      equipment {
        items {
          id
          customEquipmentID
          equipmentType
          equipmentRegistration
          organisationId
          status
          equipmentImage
          attachmentName
          inspectionFrequency
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      plant {
        items {
          id
          customPlantID
          plantRegistration
          plantType
          plantMake
          organisationId
          status
          operatorsManual
          operatorsManualName
          plantRiskAssessment
          plantRiskAssessmentName
          maintenanceHistory
          maintenanceHistoryName
          plantImage
          attachmentName
          serviceRecords {
            lastServiceMileage
            lastServiceDate
            nextServiceMileage
            nextServiceDate
            createdDate
            serviceRecordImage
            attachmentName
            isDeleted
          }
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          lastInspectionMileage
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseProjectItemDetail = /* GraphQL */ `
  mutation DeleteHseProjectItemDetail(
    $input: DeleteHseProjectItemDetailInput!
    $condition: ModelHseProjectItemDetailConditionInput
  ) {
    deleteHseProjectItemDetail(input: $input, condition: $condition) {
      id
      projectId
      dataType
      dataId
      dataName
      assignedByUserId
      assignedByUserName
      sendAlert
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      equipment {
        items {
          id
          customEquipmentID
          equipmentType
          equipmentRegistration
          organisationId
          status
          equipmentImage
          attachmentName
          inspectionFrequency
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      plant {
        items {
          id
          customPlantID
          plantRegistration
          plantType
          plantMake
          organisationId
          status
          operatorsManual
          operatorsManualName
          plantRiskAssessment
          plantRiskAssessmentName
          maintenanceHistory
          maintenanceHistoryName
          plantImage
          attachmentName
          serviceRecords {
            lastServiceMileage
            lastServiceDate
            nextServiceMileage
            nextServiceDate
            createdDate
            serviceRecordImage
            attachmentName
            isDeleted
          }
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          lastInspectionMileage
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseEquipment = /* GraphQL */ `
  mutation CreateHseEquipment(
    $input: CreateHseEquipmentInput!
    $condition: ModelHseEquipmentConditionInput
  ) {
    createHseEquipment(input: $input, condition: $condition) {
      id
      customEquipmentID
      equipmentType
      equipmentRegistration
      organisationId
      status
      equipmentImage
      attachmentName
      inspectionFrequency
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseEquipment = /* GraphQL */ `
  mutation UpdateHseEquipment(
    $input: UpdateHseEquipmentInput!
    $condition: ModelHseEquipmentConditionInput
  ) {
    updateHseEquipment(input: $input, condition: $condition) {
      id
      customEquipmentID
      equipmentType
      equipmentRegistration
      organisationId
      status
      equipmentImage
      attachmentName
      inspectionFrequency
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseEquipment = /* GraphQL */ `
  mutation DeleteHseEquipment(
    $input: DeleteHseEquipmentInput!
    $condition: ModelHseEquipmentConditionInput
  ) {
    deleteHseEquipment(input: $input, condition: $condition) {
      id
      customEquipmentID
      equipmentType
      equipmentRegistration
      organisationId
      status
      equipmentImage
      attachmentName
      inspectionFrequency
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseSwms = /* GraphQL */ `
  mutation CreateHseSwms(
    $input: CreateHseSwmsInput!
    $condition: ModelHseSwmsConditionInput
  ) {
    createHseSwms(input: $input, condition: $condition) {
      id
      organisationId
      customSwmsId
      title
      revisionNumber
      revisionDate
      description
      highRiskWork
      swmsTemplateName
      swmsTemplate
      assessmentOutcome
      activityAssessed {
        contractorDetailsIncluded
        titleRecorded
        siteSpecific
        principalContractorIdentified
        compliancePersonIdentified
        highRiskWorkIdentified
        activityBreakdown
        hazardIdentified
        controlMeasureForHazard
        matchScopeOfWork
        environmentConsidered
        hazardAssessed
        ppeRequirementIdentified
        plantEquipmentRequirementDescribed
        competenciesIdentified
        permitsRequired
        specificSwmsIdentified
        crewSignatureSpaceIdentified
        mobilePlantRiskAssociated
        controlMeasuresIdentified
        legislativeReferenceIncluded
        riskAssessmentScoreIncluded
      }
      contractorScope {
        engagedBy
        hasClearUnderstanding
        clarificationNeeded
      }
      approvers
      assessedBy
      assessedByName
      createdBy
      createdByName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseSwms = /* GraphQL */ `
  mutation UpdateHseSwms(
    $input: UpdateHseSwmsInput!
    $condition: ModelHseSwmsConditionInput
  ) {
    updateHseSwms(input: $input, condition: $condition) {
      id
      organisationId
      customSwmsId
      title
      revisionNumber
      revisionDate
      description
      highRiskWork
      swmsTemplateName
      swmsTemplate
      assessmentOutcome
      activityAssessed {
        contractorDetailsIncluded
        titleRecorded
        siteSpecific
        principalContractorIdentified
        compliancePersonIdentified
        highRiskWorkIdentified
        activityBreakdown
        hazardIdentified
        controlMeasureForHazard
        matchScopeOfWork
        environmentConsidered
        hazardAssessed
        ppeRequirementIdentified
        plantEquipmentRequirementDescribed
        competenciesIdentified
        permitsRequired
        specificSwmsIdentified
        crewSignatureSpaceIdentified
        mobilePlantRiskAssociated
        controlMeasuresIdentified
        legislativeReferenceIncluded
        riskAssessmentScoreIncluded
      }
      contractorScope {
        engagedBy
        hasClearUnderstanding
        clarificationNeeded
      }
      approvers
      assessedBy
      assessedByName
      createdBy
      createdByName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseSwms = /* GraphQL */ `
  mutation DeleteHseSwms(
    $input: DeleteHseSwmsInput!
    $condition: ModelHseSwmsConditionInput
  ) {
    deleteHseSwms(input: $input, condition: $condition) {
      id
      organisationId
      customSwmsId
      title
      revisionNumber
      revisionDate
      description
      highRiskWork
      swmsTemplateName
      swmsTemplate
      assessmentOutcome
      activityAssessed {
        contractorDetailsIncluded
        titleRecorded
        siteSpecific
        principalContractorIdentified
        compliancePersonIdentified
        highRiskWorkIdentified
        activityBreakdown
        hazardIdentified
        controlMeasureForHazard
        matchScopeOfWork
        environmentConsidered
        hazardAssessed
        ppeRequirementIdentified
        plantEquipmentRequirementDescribed
        competenciesIdentified
        permitsRequired
        specificSwmsIdentified
        crewSignatureSpaceIdentified
        mobilePlantRiskAssociated
        controlMeasuresIdentified
        legislativeReferenceIncluded
        riskAssessmentScoreIncluded
      }
      contractorScope {
        engagedBy
        hasClearUnderstanding
        clarificationNeeded
      }
      approvers
      assessedBy
      assessedByName
      createdBy
      createdByName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseSwmsAction = /* GraphQL */ `
  mutation CreateHseSwmsAction(
    $input: CreateHseSwmsActionInput!
    $condition: ModelHseSwmsActionConditionInput
  ) {
    createHseSwmsAction(input: $input, condition: $condition) {
      id
      swmId
      organisationId
      actionSummary
      responsiblePerson
      dueDate
      actionStatus
      completedBy
      completedByName
      completedDate
      reportedBy
      reportedByName
      reportedDate
      verifiers
      verifiedBy
      verifiedByName
      verifiedDate
      comments
      isDeleted
      hseSwms {
        items {
          id
          organisationId
          customSwmsId
          title
          revisionNumber
          revisionDate
          description
          highRiskWork
          swmsTemplateName
          swmsTemplate
          assessmentOutcome
          activityAssessed {
            contractorDetailsIncluded
            titleRecorded
            siteSpecific
            principalContractorIdentified
            compliancePersonIdentified
            highRiskWorkIdentified
            activityBreakdown
            hazardIdentified
            controlMeasureForHazard
            matchScopeOfWork
            environmentConsidered
            hazardAssessed
            ppeRequirementIdentified
            plantEquipmentRequirementDescribed
            competenciesIdentified
            permitsRequired
            specificSwmsIdentified
            crewSignatureSpaceIdentified
            mobilePlantRiskAssociated
            controlMeasuresIdentified
            legislativeReferenceIncluded
            riskAssessmentScoreIncluded
          }
          contractorScope {
            engagedBy
            hasClearUnderstanding
            clarificationNeeded
          }
          approvers
          assessedBy
          assessedByName
          createdBy
          createdByName
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseSwmsAction = /* GraphQL */ `
  mutation UpdateHseSwmsAction(
    $input: UpdateHseSwmsActionInput!
    $condition: ModelHseSwmsActionConditionInput
  ) {
    updateHseSwmsAction(input: $input, condition: $condition) {
      id
      swmId
      organisationId
      actionSummary
      responsiblePerson
      dueDate
      actionStatus
      completedBy
      completedByName
      completedDate
      reportedBy
      reportedByName
      reportedDate
      verifiers
      verifiedBy
      verifiedByName
      verifiedDate
      comments
      isDeleted
      hseSwms {
        items {
          id
          organisationId
          customSwmsId
          title
          revisionNumber
          revisionDate
          description
          highRiskWork
          swmsTemplateName
          swmsTemplate
          assessmentOutcome
          activityAssessed {
            contractorDetailsIncluded
            titleRecorded
            siteSpecific
            principalContractorIdentified
            compliancePersonIdentified
            highRiskWorkIdentified
            activityBreakdown
            hazardIdentified
            controlMeasureForHazard
            matchScopeOfWork
            environmentConsidered
            hazardAssessed
            ppeRequirementIdentified
            plantEquipmentRequirementDescribed
            competenciesIdentified
            permitsRequired
            specificSwmsIdentified
            crewSignatureSpaceIdentified
            mobilePlantRiskAssociated
            controlMeasuresIdentified
            legislativeReferenceIncluded
            riskAssessmentScoreIncluded
          }
          contractorScope {
            engagedBy
            hasClearUnderstanding
            clarificationNeeded
          }
          approvers
          assessedBy
          assessedByName
          createdBy
          createdByName
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseSwmsAction = /* GraphQL */ `
  mutation DeleteHseSwmsAction(
    $input: DeleteHseSwmsActionInput!
    $condition: ModelHseSwmsActionConditionInput
  ) {
    deleteHseSwmsAction(input: $input, condition: $condition) {
      id
      swmId
      organisationId
      actionSummary
      responsiblePerson
      dueDate
      actionStatus
      completedBy
      completedByName
      completedDate
      reportedBy
      reportedByName
      reportedDate
      verifiers
      verifiedBy
      verifiedByName
      verifiedDate
      comments
      isDeleted
      hseSwms {
        items {
          id
          organisationId
          customSwmsId
          title
          revisionNumber
          revisionDate
          description
          highRiskWork
          swmsTemplateName
          swmsTemplate
          assessmentOutcome
          activityAssessed {
            contractorDetailsIncluded
            titleRecorded
            siteSpecific
            principalContractorIdentified
            compliancePersonIdentified
            highRiskWorkIdentified
            activityBreakdown
            hazardIdentified
            controlMeasureForHazard
            matchScopeOfWork
            environmentConsidered
            hazardAssessed
            ppeRequirementIdentified
            plantEquipmentRequirementDescribed
            competenciesIdentified
            permitsRequired
            specificSwmsIdentified
            crewSignatureSpaceIdentified
            mobilePlantRiskAssociated
            controlMeasuresIdentified
            legislativeReferenceIncluded
            riskAssessmentScoreIncluded
          }
          contractorScope {
            engagedBy
            hasClearUnderstanding
            clarificationNeeded
          }
          approvers
          assessedBy
          assessedByName
          createdBy
          createdByName
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseSwmsWorkflow = /* GraphQL */ `
  mutation CreateHseSwmsWorkflow(
    $input: CreateHseSwmsWorkflowInput!
    $condition: ModelHseSwmsWorkflowConditionInput
  ) {
    createHseSwmsWorkflow(input: $input, condition: $condition) {
      id
      organisationId
      verifierId
      verifierName
      approverId
      approverName
      actioneeId
      actioneeName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseSwmsWorkflow = /* GraphQL */ `
  mutation UpdateHseSwmsWorkflow(
    $input: UpdateHseSwmsWorkflowInput!
    $condition: ModelHseSwmsWorkflowConditionInput
  ) {
    updateHseSwmsWorkflow(input: $input, condition: $condition) {
      id
      organisationId
      verifierId
      verifierName
      approverId
      approverName
      actioneeId
      actioneeName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseSwmsWorkflow = /* GraphQL */ `
  mutation DeleteHseSwmsWorkflow(
    $input: DeleteHseSwmsWorkflowInput!
    $condition: ModelHseSwmsWorkflowConditionInput
  ) {
    deleteHseSwmsWorkflow(input: $input, condition: $condition) {
      id
      organisationId
      verifierId
      verifierName
      approverId
      approverName
      actioneeId
      actioneeName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHseReportConfiguration = /* GraphQL */ `
  mutation CreateHseReportConfiguration(
    $input: CreateHseReportConfigurationInput!
    $condition: ModelHseReportConfigurationConditionInput
  ) {
    createHseReportConfiguration(input: $input, condition: $condition) {
      id
      projectId
      reportName
      certificateId
      certificateName
      certificateDateType
      licenseId
      licenseName
      licenseDateType
      otherRecordName
      otherRecordDateType
      qualificationName
      qualificationDateType
      inductionId
      inductionName
      inductionDateType
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHseReportConfiguration = /* GraphQL */ `
  mutation UpdateHseReportConfiguration(
    $input: UpdateHseReportConfigurationInput!
    $condition: ModelHseReportConfigurationConditionInput
  ) {
    updateHseReportConfiguration(input: $input, condition: $condition) {
      id
      projectId
      reportName
      certificateId
      certificateName
      certificateDateType
      licenseId
      licenseName
      licenseDateType
      otherRecordName
      otherRecordDateType
      qualificationName
      qualificationDateType
      inductionId
      inductionName
      inductionDateType
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHseReportConfiguration = /* GraphQL */ `
  mutation DeleteHseReportConfiguration(
    $input: DeleteHseReportConfigurationInput!
    $condition: ModelHseReportConfigurationConditionInput
  ) {
    deleteHseReportConfiguration(input: $input, condition: $condition) {
      id
      projectId
      reportName
      certificateId
      certificateName
      certificateDateType
      licenseId
      licenseName
      licenseDateType
      otherRecordName
      otherRecordDateType
      qualificationName
      qualificationDateType
      inductionId
      inductionName
      inductionDateType
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
