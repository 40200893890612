import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import sessionDataReducer from "./sessionDataReducer";
import snackbarReducer from "./snackbar";
import dataStoreReducer from "./dataStoreReducer"

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  sessionData: sessionDataReducer,
  snackbar: snackbarReducer,
  dataStoreReducer
});
