/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHseCertification = /* GraphQL */ `
  query GetHseCertification($id: ID!) {
    getHseCertification(id: $id) {
      id
      name
      type
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseCertifications = /* GraphQL */ `
  query ListHseCertifications(
    $filter: ModelHseCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseCertifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseCertifications = /* GraphQL */ `
  query SyncHseCertifications(
    $filter: ModelHseCertificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseCertifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserCertification = /* GraphQL */ `
  query GetHseUserCertification($id: ID!) {
    getHseUserCertification(id: $id) {
      id
      userId
      CertificationId
      issueDate
      expiryDate
      documentId
      isDeleted
      certification {
        items {
          id
          name
          type
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserCertifications = /* GraphQL */ `
  query ListHseUserCertifications(
    $filter: ModelHseUserCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserCertifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        CertificationId
        issueDate
        expiryDate
        documentId
        isDeleted
        certification {
          items {
            id
            name
            type
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserCertifications = /* GraphQL */ `
  query SyncHseUserCertifications(
    $filter: ModelHseUserCertificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserCertifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        CertificationId
        issueDate
        expiryDate
        documentId
        isDeleted
        certification {
          items {
            id
            name
            type
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserOtherRecords = /* GraphQL */ `
  query GetHseUserOtherRecords($id: ID!) {
    getHseUserOtherRecords(id: $id) {
      id
      userId
      recordName
      issueDate
      expiryDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserOtherRecords = /* GraphQL */ `
  query ListHseUserOtherRecords(
    $filter: ModelHseUserOtherRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserOtherRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        recordName
        issueDate
        expiryDate
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserOtherRecords = /* GraphQL */ `
  query SyncHseUserOtherRecords(
    $filter: ModelHseUserOtherRecordsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserOtherRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        recordName
        issueDate
        expiryDate
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserQualification = /* GraphQL */ `
  query GetHseUserQualification($id: ID!) {
    getHseUserQualification(id: $id) {
      id
      userId
      name
      issueDate
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserQualifications = /* GraphQL */ `
  query ListHseUserQualifications(
    $filter: ModelHseUserQualificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserQualifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        issueDate
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserQualifications = /* GraphQL */ `
  query SyncHseUserQualifications(
    $filter: ModelHseUserQualificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserQualifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        name
        issueDate
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseLicense = /* GraphQL */ `
  query GetHseLicense($id: ID!) {
    getHseLicense(id: $id) {
      id
      licenseType
      licenseClass
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseLicenses = /* GraphQL */ `
  query ListHseLicenses(
    $filter: ModelHseLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        licenseType
        licenseClass
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseLicenses = /* GraphQL */ `
  query SyncHseLicenses(
    $filter: ModelHseLicenseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseLicenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        licenseType
        licenseClass
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseLicenseRestriction = /* GraphQL */ `
  query GetHseLicenseRestriction($id: ID!) {
    getHseLicenseRestriction(id: $id) {
      id
      licenseType
      restriction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseLicenseRestrictions = /* GraphQL */ `
  query ListHseLicenseRestrictions(
    $filter: ModelHseLicenseRestrictionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseLicenseRestrictions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        licenseType
        restriction
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseLicenseRestrictions = /* GraphQL */ `
  query SyncHseLicenseRestrictions(
    $filter: ModelHseLicenseRestrictionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseLicenseRestrictions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        licenseType
        restriction
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserLicense = /* GraphQL */ `
  query GetHseUserLicense($id: ID!) {
    getHseUserLicense(id: $id) {
      id
      userId
      licenseId
      issueDate
      expiryDate
      issueState
      restrictions
      documentId
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserLicenses = /* GraphQL */ `
  query ListHseUserLicenses(
    $filter: ModelHseUserLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        licenseId
        issueDate
        expiryDate
        issueState
        restrictions
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserLicenses = /* GraphQL */ `
  query SyncHseUserLicenses(
    $filter: ModelHseUserLicenseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserLicenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        licenseId
        issueDate
        expiryDate
        issueState
        restrictions
        documentId
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserProfileImageList = /* GraphQL */ `
  query GetHseUserProfileImageList($id: ID!) {
    getHseUserProfileImageList(id: $id) {
      id
      docId
      userId
      docType
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserProfileImageLists = /* GraphQL */ `
  query ListHseUserProfileImageLists(
    $filter: ModelHseUserProfileImageListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserProfileImageLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        docId
        userId
        docType
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserProfileImageLists = /* GraphQL */ `
  query SyncHseUserProfileImageLists(
    $filter: ModelHseUserProfileImageListFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserProfileImageLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        docId
        userId
        docType
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInduction = /* GraphQL */ `
  query GetInduction($id: ID!) {
    getInduction(id: $id) {
      id
      name
      description
      inductionImage
      inductionImagePath
      videoFile
      videoFilePath
      Evaluation
      completionDeadline
      courseValidity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInductions = /* GraphQL */ `
  query ListInductions(
    $filter: ModelInductionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInductions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        inductionImage
        inductionImagePath
        videoFile
        videoFilePath
        Evaluation
        completionDeadline
        courseValidity
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInductions = /* GraphQL */ `
  query SyncInductions(
    $filter: ModelInductionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInductions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        inductionImage
        inductionImagePath
        videoFile
        videoFilePath
        Evaluation
        completionDeadline
        courseValidity
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseInductionUser = /* GraphQL */ `
  query GetHseInductionUser($id: ID!) {
    getHseInductionUser(id: $id) {
      id
      inductionId
      userId
      completionStatus
      enrollDateTime
      completionDateTime
      completedPercentage
      completionDeadline
      expiryDate
      isDeleted
      Induction {
        items {
          id
          name
          description
          inductionImage
          inductionImagePath
          videoFile
          videoFilePath
          Evaluation
          completionDeadline
          courseValidity
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseInductionUsers = /* GraphQL */ `
  query ListHseInductionUsers(
    $filter: ModelHseInductionUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseInductionUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inductionId
        userId
        completionStatus
        enrollDateTime
        completionDateTime
        completedPercentage
        completionDeadline
        expiryDate
        isDeleted
        Induction {
          items {
            id
            name
            description
            inductionImage
            inductionImagePath
            videoFile
            videoFilePath
            Evaluation
            completionDeadline
            courseValidity
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseInductionUsers = /* GraphQL */ `
  query SyncHseInductionUsers(
    $filter: ModelHseInductionUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseInductionUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inductionId
        userId
        completionStatus
        enrollDateTime
        completionDateTime
        completedPercentage
        completionDeadline
        expiryDate
        isDeleted
        Induction {
          items {
            id
            name
            description
            inductionImage
            inductionImagePath
            videoFile
            videoFilePath
            Evaluation
            completionDeadline
            courseValidity
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseProject = /* GraphQL */ `
  query GetHseProject($id: ID!) {
    getHseProject(id: $id) {
      id
      projectName
      projectNumber
      projectUrl
      isArchived
      State
      projectInduction
      kpiData
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseProjects = /* GraphQL */ `
  query ListHseProjects(
    $filter: ModelHseProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectName
        projectNumber
        projectUrl
        isArchived
        State
        projectInduction
        kpiData
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseProjects = /* GraphQL */ `
  query SyncHseProjects(
    $filter: ModelHseProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectName
        projectNumber
        projectUrl
        isArchived
        State
        projectInduction
        kpiData
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserRole = /* GraphQL */ `
  query GetHseUserRole($id: ID!) {
    getHseUserRole(id: $id) {
      id
      name
      manageIncident
      manageEvent
      manageStky
      manageTake5
      manageJourneyPlanner
      manageHazardObservation
      manageInspection
      manageEnrollInduction
      manageAddInduction
      manageDashboard
      manageRegister
      manageUser
      manageProject
      manageConfiguration
      manageSWMS
      isAvailableForSubcontractor
      managePlantCheckin
      manageEquipmentCheckin
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserRoles = /* GraphQL */ `
  query ListHseUserRoles(
    $filter: ModelHseUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        manageIncident
        manageEvent
        manageStky
        manageTake5
        manageJourneyPlanner
        manageHazardObservation
        manageInspection
        manageEnrollInduction
        manageAddInduction
        manageDashboard
        manageRegister
        manageUser
        manageProject
        manageConfiguration
        manageSWMS
        isAvailableForSubcontractor
        managePlantCheckin
        manageEquipmentCheckin
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserRoles = /* GraphQL */ `
  query SyncHseUserRoles(
    $filter: ModelHseUserRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        manageIncident
        manageEvent
        manageStky
        manageTake5
        manageJourneyPlanner
        manageHazardObservation
        manageInspection
        manageEnrollInduction
        manageAddInduction
        manageDashboard
        manageRegister
        manageUser
        manageProject
        manageConfiguration
        manageSWMS
        isAvailableForSubcontractor
        managePlantCheckin
        manageEquipmentCheckin
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseProjectUserDetails = /* GraphQL */ `
  query GetHseProjectUserDetails($id: ID!) {
    getHseProjectUserDetails(id: $id) {
      id
      projectId
      userId
      userName
      roleId
      sendAlert
      archivedProject
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userRole {
        items {
          id
          name
          manageIncident
          manageEvent
          manageStky
          manageTake5
          manageJourneyPlanner
          manageHazardObservation
          manageInspection
          manageEnrollInduction
          manageAddInduction
          manageDashboard
          manageRegister
          manageUser
          manageProject
          manageConfiguration
          manageSWMS
          isAvailableForSubcontractor
          managePlantCheckin
          manageEquipmentCheckin
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseProjectUserDetails = /* GraphQL */ `
  query ListHseProjectUserDetails(
    $filter: ModelHseProjectUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjectUserDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        userId
        userName
        roleId
        sendAlert
        archivedProject
        isDeleted
        createdAt
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        userRole {
          items {
            id
            name
            manageIncident
            manageEvent
            manageStky
            manageTake5
            manageJourneyPlanner
            manageHazardObservation
            manageInspection
            manageEnrollInduction
            manageAddInduction
            manageDashboard
            manageRegister
            manageUser
            manageProject
            manageConfiguration
            manageSWMS
            isAvailableForSubcontractor
            managePlantCheckin
            manageEquipmentCheckin
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseProjectUserDetails = /* GraphQL */ `
  query SyncHseProjectUserDetails(
    $filter: ModelHseProjectUserDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseProjectUserDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        userId
        userName
        roleId
        sendAlert
        archivedProject
        isDeleted
        createdAt
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        userRole {
          items {
            id
            name
            manageIncident
            manageEvent
            manageStky
            manageTake5
            manageJourneyPlanner
            manageHazardObservation
            manageInspection
            manageEnrollInduction
            manageAddInduction
            manageDashboard
            manageRegister
            manageUser
            manageProject
            manageConfiguration
            manageSWMS
            isAvailableForSubcontractor
            managePlantCheckin
            manageEquipmentCheckin
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseProjectSignOnOff = /* GraphQL */ `
  query GetHseProjectSignOnOff($id: ID!) {
    getHseProjectSignOnOff(id: $id) {
      id
      projectId
      dataType
      dataId
      name
      signOnDateTimeAt
      signOffDateTimeAt
      signOnByUserId
      signOnByUserName
      signOffByUserId
      signOffByUserName
      SignedOnInToProjectId
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseProjectSignOnOffs = /* GraphQL */ `
  query ListHseProjectSignOnOffs(
    $filter: ModelHseProjectSignOnOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjectSignOnOffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        dataType
        dataId
        name
        signOnDateTimeAt
        signOffDateTimeAt
        signOnByUserId
        signOnByUserName
        signOffByUserId
        signOffByUserName
        SignedOnInToProjectId
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseProjectSignOnOffs = /* GraphQL */ `
  query SyncHseProjectSignOnOffs(
    $filter: ModelHseProjectSignOnOffFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseProjectSignOnOffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        dataType
        dataId
        name
        signOnDateTimeAt
        signOffDateTimeAt
        signOnByUserId
        signOnByUserName
        signOffByUserId
        signOffByUserName
        SignedOnInToProjectId
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseProjectSignOnOffByProjectId = /* GraphQL */ `
  query HseProjectSignOnOffByProjectId(
    $projectId: ID!
    $dataId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHseProjectSignOnOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseProjectSignOnOffByProjectId(
      projectId: $projectId
      dataId: $dataId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        dataType
        dataId
        name
        signOnDateTimeAt
        signOffDateTimeAt
        signOnByUserId
        signOnByUserName
        signOffByUserId
        signOffByUserName
        SignedOnInToProjectId
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseProjectSignOnOffByDataId = /* GraphQL */ `
  query HseProjectSignOnOffByDataId(
    $dataId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHseProjectSignOnOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseProjectSignOnOffByDataId(
      dataId: $dataId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        dataType
        dataId
        name
        signOnDateTimeAt
        signOffDateTimeAt
        signOnByUserId
        signOnByUserName
        signOffByUserId
        signOffByUserName
        SignedOnInToProjectId
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseOrganisation = /* GraphQL */ `
  query GetHseOrganisation($id: ID!) {
    getHseOrganisation(id: $id) {
      id
      name
      organizationInduction
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseOrganisations = /* GraphQL */ `
  query ListHseOrganisations(
    $filter: ModelHseOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organizationInduction
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseOrganisations = /* GraphQL */ `
  query SyncHseOrganisations(
    $filter: ModelHseOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        organizationInduction
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserOrganisation = /* GraphQL */ `
  query GetHseUserOrganisation($id: ID!) {
    getHseUserOrganisation(id: $id) {
      id
      userId
      userName
      organisationId
      roleId
      roleName
      email
      phoneNumber
      status
      isCurrent
      isEnabled
      isApproved
      approvedBy
      approvedDate
      sentForApprovalDate
      transferRequestedBy
      createdBy
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserOrganisations = /* GraphQL */ `
  query ListHseUserOrganisations(
    $filter: ModelHseUserOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userName
        organisationId
        roleId
        roleName
        email
        phoneNumber
        status
        isCurrent
        isEnabled
        isApproved
        approvedBy
        approvedDate
        sentForApprovalDate
        transferRequestedBy
        createdBy
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserOrganisations = /* GraphQL */ `
  query SyncHseUserOrganisations(
    $filter: ModelHseUserOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userName
        organisationId
        roleId
        roleName
        email
        phoneNumber
        status
        isCurrent
        isEnabled
        isApproved
        approvedBy
        approvedDate
        sentForApprovalDate
        transferRequestedBy
        createdBy
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseConfiguration = /* GraphQL */ `
  query GetHseConfiguration($id: ID!) {
    getHseConfiguration(id: $id) {
      id
      type
      value
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseConfigurations = /* GraphQL */ `
  query ListHseConfigurations(
    $filter: ModelHseConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseConfigurations = /* GraphQL */ `
  query SyncHseConfigurations(
    $filter: ModelHseConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        value
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserProjectFatigue = /* GraphQL */ `
  query GetHseUserProjectFatigue($id: ID!) {
    getHseUserProjectFatigue(id: $id) {
      id
      userId
      projectId
      sleepIn24Hours
      sleepInPrevious24Hours
      createdAt
      awokeTodayAt
      FatigueScore
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserProjectFatigues = /* GraphQL */ `
  query ListHseUserProjectFatigues(
    $filter: ModelHseUserProjectFatigueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserProjectFatigues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectId
        sleepIn24Hours
        sleepInPrevious24Hours
        createdAt
        awokeTodayAt
        FatigueScore
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserProjectFatigues = /* GraphQL */ `
  query SyncHseUserProjectFatigues(
    $filter: ModelHseUserProjectFatigueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserProjectFatigues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        projectId
        sleepIn24Hours
        sleepInPrevious24Hours
        createdAt
        awokeTodayAt
        FatigueScore
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseFormImage = /* GraphQL */ `
  query GetHseFormImage($id: ID!) {
    getHseFormImage(id: $id) {
      id
      formId
      userId
      formType
      formStatus
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseFormImages = /* GraphQL */ `
  query ListHseFormImages(
    $filter: ModelHseFormImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseFormImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formId
        userId
        formType
        formStatus
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseFormImages = /* GraphQL */ `
  query SyncHseFormImages(
    $filter: ModelHseFormImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseFormImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        formId
        userId
        formType
        formStatus
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseTake5EnergySourceAndHazard = /* GraphQL */ `
  query GetHseTake5EnergySourceAndHazard($id: ID!) {
    getHseTake5EnergySourceAndHazard(id: $id) {
      id
      energySource
      hazard
      hazardDescription
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseTake5EnergySourceAndHazards = /* GraphQL */ `
  query ListHseTake5EnergySourceAndHazards(
    $filter: ModelHseTake5EnergySourceAndHazardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseTake5EnergySourceAndHazards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        energySource
        hazard
        hazardDescription
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseTake5EnergySourceAndHazards = /* GraphQL */ `
  query SyncHseTake5EnergySourceAndHazards(
    $filter: ModelHseTake5EnergySourceAndHazardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseTake5EnergySourceAndHazards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        energySource
        hazard
        hazardDescription
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseProjectTake5Assessment = /* GraphQL */ `
  query GetHseProjectTake5Assessment($id: ID!) {
    getHseProjectTake5Assessment(id: $id) {
      id
      projectId
      userId
      documentId
      Status
      jobName
      location
      locationTag
      isStky
      adequateControls
      additionalControls
      isOtherHazards
      OtherHazards
      isDeleted
      createdAt
      selectedEnergySources
      EnergySourceHazard {
        id
        energySource
        hazard
        isDeleted
        createdAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseProjectTake5Assessments = /* GraphQL */ `
  query ListHseProjectTake5Assessments(
    $filter: ModelHseProjectTake5AssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjectTake5Assessments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        userId
        documentId
        Status
        jobName
        location
        locationTag
        isStky
        adequateControls
        additionalControls
        isOtherHazards
        OtherHazards
        isDeleted
        createdAt
        selectedEnergySources
        EnergySourceHazard {
          id
          energySource
          hazard
          isDeleted
          createdAt
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseProjectTake5Assessments = /* GraphQL */ `
  query SyncHseProjectTake5Assessments(
    $filter: ModelHseProjectTake5AssessmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseProjectTake5Assessments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        userId
        documentId
        Status
        jobName
        location
        locationTag
        isStky
        adequateControls
        additionalControls
        isOtherHazards
        OtherHazards
        isDeleted
        createdAt
        selectedEnergySources
        EnergySourceHazard {
          id
          energySource
          hazard
          isDeleted
          createdAt
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseTake5AssessmentCrewSignature = /* GraphQL */ `
  query GetHseTake5AssessmentCrewSignature($id: ID!) {
    getHseTake5AssessmentCrewSignature(id: $id) {
      id
      take5id
      userId
      projectId
      signatureInBase64
      isDeleted
      Take5Assessment {
        items {
          id
          projectId
          userId
          documentId
          Status
          jobName
          location
          locationTag
          isStky
          adequateControls
          additionalControls
          isOtherHazards
          OtherHazards
          isDeleted
          createdAt
          selectedEnergySources
          EnergySourceHazard {
            id
            energySource
            hazard
            isDeleted
            createdAt
          }
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseTake5AssessmentCrewSignatures = /* GraphQL */ `
  query ListHseTake5AssessmentCrewSignatures(
    $filter: ModelHseTake5AssessmentCrewSignatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseTake5AssessmentCrewSignatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        take5id
        userId
        projectId
        signatureInBase64
        isDeleted
        Take5Assessment {
          items {
            id
            projectId
            userId
            documentId
            Status
            jobName
            location
            locationTag
            isStky
            adequateControls
            additionalControls
            isOtherHazards
            OtherHazards
            isDeleted
            createdAt
            selectedEnergySources
            EnergySourceHazard {
              id
              energySource
              hazard
              isDeleted
              createdAt
            }
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseTake5AssessmentCrewSignatures = /* GraphQL */ `
  query SyncHseTake5AssessmentCrewSignatures(
    $filter: ModelHseTake5AssessmentCrewSignatureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseTake5AssessmentCrewSignatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        take5id
        userId
        projectId
        signatureInBase64
        isDeleted
        Take5Assessment {
          items {
            id
            projectId
            userId
            documentId
            Status
            jobName
            location
            locationTag
            isStky
            adequateControls
            additionalControls
            isOtherHazards
            OtherHazards
            isDeleted
            createdAt
            selectedEnergySources
            EnergySourceHazard {
              id
              energySource
              hazard
              isDeleted
              createdAt
            }
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseTake5SignaturesByTake5Id = /* GraphQL */ `
  query HseTake5SignaturesByTake5Id(
    $take5id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHseTake5AssessmentCrewSignatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseTake5SignaturesByTake5Id(
      take5id: $take5id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        take5id
        userId
        projectId
        signatureInBase64
        isDeleted
        Take5Assessment {
          items {
            id
            projectId
            userId
            documentId
            Status
            jobName
            location
            locationTag
            isStky
            adequateControls
            additionalControls
            isOtherHazards
            OtherHazards
            isDeleted
            createdAt
            selectedEnergySources
            EnergySourceHazard {
              id
              energySource
              hazard
              isDeleted
              createdAt
            }
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseIncidentForm = /* GraphQL */ `
  query GetHseIncidentForm($id: ID!) {
    getHseIncidentForm(id: $id) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      reportingItems
      incidentTitle
      environmentalImpact
      incidentLocation
      locationTag
      incidentDateTime
      taskDetails
      actualConsequence
      potentialConsequence
      isStky
      stkyCategory
      incidentSource
      incidentDescription
      immediateAction
      rootCause
      mobilePlant
      plantOperator
      selectedEquipment
      equipmentOperator
      cppVehicleRegistrationNumber
      vehicleOtherDetails
      cppOperator
      driverLicenceNumber
      thirdPartyOperatorName
      thirdPartyRegistrationNumber
      otherPropertyDetails
      policeReportDateTime
      policeReportReferenceNo
      formStatus
      nameOfSupervisor
      personOnSiteAtTimeOfIncident
      isWitnessStatementTaken
      isDeleted
      injuryDetails {
        injuredPerson
        firstAider
        describeInjury
        describeFirstAid
        bodySelector
        taskExperience
        shiftCompleted
        dutyStatusAtTimeOfEvent
        refDoctorContactDetails
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseIncidentForms = /* GraphQL */ `
  query ListHseIncidentForms(
    $filter: ModelHseIncidentFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseIncidentForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        reportingItems
        incidentTitle
        environmentalImpact
        incidentLocation
        locationTag
        incidentDateTime
        taskDetails
        actualConsequence
        potentialConsequence
        isStky
        stkyCategory
        incidentSource
        incidentDescription
        immediateAction
        rootCause
        mobilePlant
        plantOperator
        selectedEquipment
        equipmentOperator
        cppVehicleRegistrationNumber
        vehicleOtherDetails
        cppOperator
        driverLicenceNumber
        thirdPartyOperatorName
        thirdPartyRegistrationNumber
        otherPropertyDetails
        policeReportDateTime
        policeReportReferenceNo
        formStatus
        nameOfSupervisor
        personOnSiteAtTimeOfIncident
        isWitnessStatementTaken
        isDeleted
        injuryDetails {
          injuredPerson
          firstAider
          describeInjury
          describeFirstAid
          bodySelector
          taskExperience
          shiftCompleted
          dutyStatusAtTimeOfEvent
          refDoctorContactDetails
          isDeleted
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseIncidentForms = /* GraphQL */ `
  query SyncHseIncidentForms(
    $filter: ModelHseIncidentFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseIncidentForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        reportingItems
        incidentTitle
        environmentalImpact
        incidentLocation
        locationTag
        incidentDateTime
        taskDetails
        actualConsequence
        potentialConsequence
        isStky
        stkyCategory
        incidentSource
        incidentDescription
        immediateAction
        rootCause
        mobilePlant
        plantOperator
        selectedEquipment
        equipmentOperator
        cppVehicleRegistrationNumber
        vehicleOtherDetails
        cppOperator
        driverLicenceNumber
        thirdPartyOperatorName
        thirdPartyRegistrationNumber
        otherPropertyDetails
        policeReportDateTime
        policeReportReferenceNo
        formStatus
        nameOfSupervisor
        personOnSiteAtTimeOfIncident
        isWitnessStatementTaken
        isDeleted
        injuryDetails {
          injuredPerson
          firstAider
          describeInjury
          describeFirstAid
          bodySelector
          taskExperience
          shiftCompleted
          dutyStatusAtTimeOfEvent
          refDoctorContactDetails
          isDeleted
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseEventForm = /* GraphQL */ `
  query GetHseEventForm($id: ID!) {
    getHseEventForm(id: $id) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      eventTitle
      reportingItems
      eventLocation
      locationTag
      eventDateTime
      taskDetails
      isDeleted
      eventDescription
      immediateAction
      formStatus
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseEventForms = /* GraphQL */ `
  query ListHseEventForms(
    $filter: ModelHseEventFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseEventForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        eventTitle
        reportingItems
        eventLocation
        locationTag
        eventDateTime
        taskDetails
        isDeleted
        eventDescription
        immediateAction
        formStatus
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseEventForms = /* GraphQL */ `
  query SyncHseEventForms(
    $filter: ModelHseEventFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseEventForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        eventTitle
        reportingItems
        eventLocation
        locationTag
        eventDateTime
        taskDetails
        isDeleted
        eventDescription
        immediateAction
        formStatus
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseStkyEngagementForm = /* GraphQL */ `
  query GetHseStkyEngagementForm($id: ID!) {
    getHseStkyEngagementForm(id: $id) {
      id
      documentId
      projectId
      reportedBy
      reportedDate
      jobName
      participants
      energySourceByLeader
      energySourceByCrew
      unknownHazard
      otherHazards
      control
      discuss
      feedback
      formStatus
      stkyLocation
      locationTag
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseStkyEngagementForms = /* GraphQL */ `
  query ListHseStkyEngagementForms(
    $filter: ModelHseStkyEngagementFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseStkyEngagementForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        jobName
        participants
        energySourceByLeader
        energySourceByCrew
        unknownHazard
        otherHazards
        control
        discuss
        feedback
        formStatus
        stkyLocation
        locationTag
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseStkyEngagementForms = /* GraphQL */ `
  query SyncHseStkyEngagementForms(
    $filter: ModelHseStkyEngagementFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseStkyEngagementForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        reportedDate
        jobName
        participants
        energySourceByLeader
        energySourceByCrew
        unknownHazard
        otherHazards
        control
        discuss
        feedback
        formStatus
        stkyLocation
        locationTag
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserActionForm = /* GraphQL */ `
  query GetHseUserActionForm($id: ID!) {
    getHseUserActionForm(id: $id) {
      id
      formId
      customActionID
      projectNumber
      formName
      taskTitle
      taskDescription
      taskStatus
      dueDate
      responsiblePerson
      completedBy
      reportedBy
      comments
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserActionForms = /* GraphQL */ `
  query ListHseUserActionForms(
    $filter: ModelHseUserActionFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserActionForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formId
        customActionID
        projectNumber
        formName
        taskTitle
        taskDescription
        taskStatus
        dueDate
        responsiblePerson
        completedBy
        reportedBy
        comments
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserActionForms = /* GraphQL */ `
  query SyncHseUserActionForms(
    $filter: ModelHseUserActionFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserActionForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        formId
        customActionID
        projectNumber
        formName
        taskTitle
        taskDescription
        taskStatus
        dueDate
        responsiblePerson
        completedBy
        reportedBy
        comments
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserActionImages = /* GraphQL */ `
  query GetHseUserActionImages($id: ID!) {
    getHseUserActionImages(id: $id) {
      id
      actionId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      ActionForm {
        items {
          id
          formId
          customActionID
          projectNumber
          formName
          taskTitle
          taskDescription
          taskStatus
          dueDate
          responsiblePerson
          completedBy
          reportedBy
          comments
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserActionImages = /* GraphQL */ `
  query ListHseUserActionImages(
    $filter: ModelHseUserActionImagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserActionImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionId
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        ActionForm {
          items {
            id
            formId
            customActionID
            projectNumber
            formName
            taskTitle
            taskDescription
            taskStatus
            dueDate
            responsiblePerson
            completedBy
            reportedBy
            comments
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserActionImages = /* GraphQL */ `
  query SyncHseUserActionImages(
    $filter: ModelHseUserActionImagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserActionImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionId
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        ActionForm {
          items {
            id
            formId
            customActionID
            projectNumber
            formName
            taskTitle
            taskDescription
            taskStatus
            dueDate
            responsiblePerson
            completedBy
            reportedBy
            comments
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseInspectionTemplate = /* GraphQL */ `
  query GetHseInspectionTemplate($id: ID!) {
    getHseInspectionTemplate(id: $id) {
      id
      name
      type
      description
      userId
      occurrence
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseInspectionTemplates = /* GraphQL */ `
  query ListHseInspectionTemplates(
    $filter: ModelHseInspectionTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseInspectionTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        description
        userId
        occurrence
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseInspectionTemplates = /* GraphQL */ `
  query SyncHseInspectionTemplates(
    $filter: ModelHseInspectionTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseInspectionTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        description
        userId
        occurrence
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseInspectionTemplateSection = /* GraphQL */ `
  query GetHseInspectionTemplateSection($id: ID!) {
    getHseInspectionTemplateSection(id: $id) {
      id
      name
      sequenceId
      templateId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseInspectionTemplateSections = /* GraphQL */ `
  query ListHseInspectionTemplateSections(
    $filter: ModelHseInspectionTemplateSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseInspectionTemplateSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sequenceId
        templateId
        isDeleted
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseInspectionTemplateSections = /* GraphQL */ `
  query SyncHseInspectionTemplateSections(
    $filter: ModelHseInspectionTemplateSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseInspectionTemplateSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        sequenceId
        templateId
        isDeleted
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseInspectionTemplateQuestion = /* GraphQL */ `
  query GetHseInspectionTemplateQuestion($id: ID!) {
    getHseInspectionTemplateQuestion(id: $id) {
      id
      question
      sequenceId
      isRequired
      controlType
      controlData
      templateId
      sectionId
      isDeleted
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateSection {
        items {
          id
          name
          sequenceId
          templateId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseInspectionTemplateQuestions = /* GraphQL */ `
  query ListHseInspectionTemplateQuestions(
    $filter: ModelHseInspectionTemplateQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseInspectionTemplateQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        sequenceId
        isRequired
        controlType
        controlData
        templateId
        sectionId
        isDeleted
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateSection {
          items {
            id
            name
            sequenceId
            templateId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseInspectionTemplateQuestions = /* GraphQL */ `
  query SyncHseInspectionTemplateQuestions(
    $filter: ModelHseInspectionTemplateQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseInspectionTemplateQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question
        sequenceId
        isRequired
        controlType
        controlData
        templateId
        sectionId
        isDeleted
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateSection {
          items {
            id
            name
            sequenceId
            templateId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseInspectionQuestionByTemplateId = /* GraphQL */ `
  query HseInspectionQuestionByTemplateId(
    $templateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHseInspectionTemplateQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseInspectionQuestionByTemplateId(
      templateId: $templateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        sequenceId
        isRequired
        controlType
        controlData
        templateId
        sectionId
        isDeleted
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateSection {
          items {
            id
            name
            sequenceId
            templateId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserInspection = /* GraphQL */ `
  query GetHseUserInspection($id: ID!) {
    getHseUserInspection(id: $id) {
      id
      templateId
      templateName
      templateType
      inspectionId
      projectId
      userId
      createdAt
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplate {
        items {
          id
          name
          type
          description
          userId
          occurrence
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserInspections = /* GraphQL */ `
  query ListHseUserInspections(
    $filter: ModelHseUserInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateId
        templateName
        templateType
        inspectionId
        projectId
        userId
        createdAt
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserInspections = /* GraphQL */ `
  query SyncHseUserInspections(
    $filter: ModelHseUserInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        templateId
        templateName
        templateType
        inspectionId
        projectId
        userId
        createdAt
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplate {
          items {
            id
            name
            type
            description
            userId
            occurrence
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserInspectionData = /* GraphQL */ `
  query GetHseUserInspectionData($id: ID!) {
    getHseUserInspectionData(id: $id) {
      id
      userInspectionId
      templateQuestionId
      controlValue
      comment
      isDeleted
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseInspectionTemplateQuestion {
        items {
          id
          question
          sequenceId
          isRequired
          controlType
          controlData
          templateId
          sectionId
          isDeleted
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateSection {
            items {
              id
              name
              sequenceId
              templateId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserInspectionData = /* GraphQL */ `
  query ListHseUserInspectionData(
    $filter: ModelHseUserInspectionDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserInspectionData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userInspectionId
        templateQuestionId
        controlValue
        comment
        isDeleted
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateQuestion {
          items {
            id
            question
            sequenceId
            isRequired
            controlType
            controlData
            templateId
            sectionId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateSection {
              items {
                id
                name
                sequenceId
                templateId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserInspectionData = /* GraphQL */ `
  query SyncHseUserInspectionData(
    $filter: ModelHseUserInspectionDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserInspectionData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userInspectionId
        templateQuestionId
        controlValue
        comment
        isDeleted
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateQuestion {
          items {
            id
            question
            sequenceId
            isRequired
            controlType
            controlData
            templateId
            sectionId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateSection {
              items {
                id
                name
                sequenceId
                templateId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseUserInspectionDataByInspectionId = /* GraphQL */ `
  query HseUserInspectionDataByInspectionId(
    $userInspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHseUserInspectionDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseUserInspectionDataByInspectionId(
      userInspectionId: $userInspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userInspectionId
        templateQuestionId
        controlValue
        comment
        isDeleted
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateQuestion {
          items {
            id
            question
            sequenceId
            isRequired
            controlType
            controlData
            templateId
            sectionId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateSection {
              items {
                id
                name
                sequenceId
                templateId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hseInspectionUserDataByControlId = /* GraphQL */ `
  query HseInspectionUserDataByControlId(
    $controlValue: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHseUserInspectionDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hseInspectionUserDataByControlId(
      controlValue: $controlValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userInspectionId
        templateQuestionId
        controlValue
        comment
        isDeleted
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseInspectionTemplateQuestion {
          items {
            id
            question
            sequenceId
            isRequired
            controlType
            controlData
            templateId
            sectionId
            isDeleted
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateSection {
              items {
                id
                name
                sequenceId
                templateId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserInspectionFiles = /* GraphQL */ `
  query GetHseUserInspectionFiles($id: ID!) {
    getHseUserInspectionFiles(id: $id) {
      id
      userInspectionId
      userInspectionDataId
      attachedImage
      imagePath
      isDeleted
      fileBase64
      createdAt
      updatedAt
      HseUserInspection {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      HseUserInspectionData {
        items {
          id
          userInspectionId
          templateQuestionId
          controlValue
          comment
          isDeleted
          createdAt
          updatedAt
          HseUserInspection {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplateQuestion {
            items {
              id
              question
              sequenceId
              isRequired
              controlType
              controlData
              templateId
              sectionId
              isDeleted
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplateSection {
                items {
                  id
                  name
                  sequenceId
                  templateId
                  isDeleted
                  HseInspectionTemplate {
                    items {
                      id
                      name
                      type
                      description
                      userId
                      occurrence
                      isDeleted
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserInspectionFiles = /* GraphQL */ `
  query ListHseUserInspectionFiles(
    $filter: ModelHseUserInspectionFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserInspectionFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userInspectionId
        userInspectionDataId
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseUserInspectionData {
          items {
            id
            userInspectionId
            templateQuestionId
            controlValue
            comment
            isDeleted
            createdAt
            updatedAt
            HseUserInspection {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateQuestion {
              items {
                id
                question
                sequenceId
                isRequired
                controlType
                controlData
                templateId
                sectionId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplateSection {
                  items {
                    id
                    name
                    sequenceId
                    templateId
                    isDeleted
                    HseInspectionTemplate {
                      items {
                        id
                        name
                        type
                        description
                        userId
                        occurrence
                        isDeleted
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserInspectionFiles = /* GraphQL */ `
  query SyncHseUserInspectionFiles(
    $filter: ModelHseUserInspectionFilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserInspectionFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userInspectionId
        userInspectionDataId
        attachedImage
        imagePath
        isDeleted
        fileBase64
        createdAt
        updatedAt
        HseUserInspection {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        HseUserInspectionData {
          items {
            id
            userInspectionId
            templateQuestionId
            controlValue
            comment
            isDeleted
            createdAt
            updatedAt
            HseUserInspection {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplateQuestion {
              items {
                id
                question
                sequenceId
                isRequired
                controlType
                controlData
                templateId
                sectionId
                isDeleted
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplateSection {
                  items {
                    id
                    name
                    sequenceId
                    templateId
                    isDeleted
                    HseInspectionTemplate {
                      items {
                        id
                        name
                        type
                        description
                        userId
                        occurrence
                        isDeleted
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseJourneyPlannerForm = /* GraphQL */ `
  query GetHseJourneyPlannerForm($id: ID!) {
    getHseJourneyPlannerForm(id: $id) {
      id
      documentId
      projectId
      reportedBy
      journeyStartLocation
      journeyDestinationLocation
      routeDistance
      estimatedTravelTime
      totalTravelTime
      journeyEndTime
      journeyStartTime
      vehicleType
      vehicleMake
      vehicleModel
      vehicleRego
      journeyPurpose
      formStatus
      isDeleted
      createdAt
      journeyBreakPoint {
        placeId
        journeyBreakPointName
        journeyTimeFromBreakPoint
        journeyBreakDuration
        isDeleted
      }
      journeyDriver {
        driverID
        driverFatigueScore
        isDeleted
      }
      journeyPassenger {
        passengerID
        passengerFatigueScore
        isDeleted
      }
      journeyRiskAssessment {
        isLicensed
        isRoutePlanReady
        isFitForWork
        isLoadExceeded
        isPotentialHazards
        potentialHazardsDescription
        isJourneyMoreThan100KM
        isDeleted
      }
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseJourneyPlannerForms = /* GraphQL */ `
  query ListHseJourneyPlannerForms(
    $filter: ModelHseJourneyPlannerFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseJourneyPlannerForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        journeyStartLocation
        journeyDestinationLocation
        routeDistance
        estimatedTravelTime
        totalTravelTime
        journeyEndTime
        journeyStartTime
        vehicleType
        vehicleMake
        vehicleModel
        vehicleRego
        journeyPurpose
        formStatus
        isDeleted
        createdAt
        journeyBreakPoint {
          placeId
          journeyBreakPointName
          journeyTimeFromBreakPoint
          journeyBreakDuration
          isDeleted
        }
        journeyDriver {
          driverID
          driverFatigueScore
          isDeleted
        }
        journeyPassenger {
          passengerID
          passengerFatigueScore
          isDeleted
        }
        journeyRiskAssessment {
          isLicensed
          isRoutePlanReady
          isFitForWork
          isLoadExceeded
          isPotentialHazards
          potentialHazardsDescription
          isJourneyMoreThan100KM
          isDeleted
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseJourneyPlannerForms = /* GraphQL */ `
  query SyncHseJourneyPlannerForms(
    $filter: ModelHseJourneyPlannerFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseJourneyPlannerForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        journeyStartLocation
        journeyDestinationLocation
        routeDistance
        estimatedTravelTime
        totalTravelTime
        journeyEndTime
        journeyStartTime
        vehicleType
        vehicleMake
        vehicleModel
        vehicleRego
        journeyPurpose
        formStatus
        isDeleted
        createdAt
        journeyBreakPoint {
          placeId
          journeyBreakPointName
          journeyTimeFromBreakPoint
          journeyBreakDuration
          isDeleted
        }
        journeyDriver {
          driverID
          driverFatigueScore
          isDeleted
        }
        journeyPassenger {
          passengerID
          passengerFatigueScore
          isDeleted
        }
        journeyRiskAssessment {
          isLicensed
          isRoutePlanReady
          isFitForWork
          isLoadExceeded
          isPotentialHazards
          potentialHazardsDescription
          isJourneyMoreThan100KM
          isDeleted
        }
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseApprovalManagement = /* GraphQL */ `
  query GetHseApprovalManagement($id: ID!) {
    getHseApprovalManagement(id: $id) {
      id
      projectId
      formName
      approver
      peopleToBeNotified
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseApprovalManagements = /* GraphQL */ `
  query ListHseApprovalManagements(
    $filter: ModelHseApprovalManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseApprovalManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        formName
        approver
        peopleToBeNotified
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseApprovalManagements = /* GraphQL */ `
  query SyncHseApprovalManagements(
    $filter: ModelHseApprovalManagementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseApprovalManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        formName
        approver
        peopleToBeNotified
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseUserHazardReports = /* GraphQL */ `
  query GetHseUserHazardReports($id: ID!) {
    getHseUserHazardReports(id: $id) {
      id
      documentId
      projectId
      reportedBy
      formName
      reportedDate
      hazardCategory
      isClosed
      observation
      observationInput
      recuiredActionInput
      selectLocation
      locationTag
      takenActionInput
      hazardLocatin
      formStatus
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseUserHazardReports = /* GraphQL */ `
  query ListHseUserHazardReports(
    $filter: ModelHseUserHazardReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseUserHazardReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        formName
        reportedDate
        hazardCategory
        isClosed
        observation
        observationInput
        recuiredActionInput
        selectLocation
        locationTag
        takenActionInput
        hazardLocatin
        formStatus
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseUserHazardReports = /* GraphQL */ `
  query SyncHseUserHazardReports(
    $filter: ModelHseUserHazardReportsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseUserHazardReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentId
        projectId
        reportedBy
        formName
        reportedDate
        hazardCategory
        isClosed
        observation
        observationInput
        recuiredActionInput
        selectLocation
        locationTag
        takenActionInput
        hazardLocatin
        formStatus
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHsePlant = /* GraphQL */ `
  query GetHsePlant($id: ID!) {
    getHsePlant(id: $id) {
      id
      customPlantID
      plantRegistration
      plantType
      plantMake
      organisationId
      status
      operatorsManual
      operatorsManualName
      plantRiskAssessment
      plantRiskAssessmentName
      maintenanceHistory
      maintenanceHistoryName
      plantImage
      attachmentName
      serviceRecords {
        lastServiceMileage
        lastServiceDate
        nextServiceMileage
        nextServiceDate
        createdDate
        serviceRecordImage
        attachmentName
        isDeleted
      }
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      lastInspectionMileage
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHsePlants = /* GraphQL */ `
  query ListHsePlants(
    $filter: ModelHsePlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHsePlants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customPlantID
        plantRegistration
        plantType
        plantMake
        organisationId
        status
        operatorsManual
        operatorsManualName
        plantRiskAssessment
        plantRiskAssessmentName
        maintenanceHistory
        maintenanceHistoryName
        plantImage
        attachmentName
        serviceRecords {
          lastServiceMileage
          lastServiceDate
          nextServiceMileage
          nextServiceDate
          createdDate
          serviceRecordImage
          attachmentName
          isDeleted
        }
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        lastInspectionMileage
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHsePlants = /* GraphQL */ `
  query SyncHsePlants(
    $filter: ModelHsePlantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHsePlants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customPlantID
        plantRegistration
        plantType
        plantMake
        organisationId
        status
        operatorsManual
        operatorsManualName
        plantRiskAssessment
        plantRiskAssessmentName
        maintenanceHistory
        maintenanceHistoryName
        plantImage
        attachmentName
        serviceRecords {
          lastServiceMileage
          lastServiceDate
          nextServiceMileage
          nextServiceDate
          createdDate
          serviceRecordImage
          attachmentName
          isDeleted
        }
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        lastInspectionMileage
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hsePlantbyPlantId = /* GraphQL */ `
  query HsePlantbyPlantId(
    $customPlantID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHsePlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hsePlantbyPlantId(
      customPlantID: $customPlantID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPlantID
        plantRegistration
        plantType
        plantMake
        organisationId
        status
        operatorsManual
        operatorsManualName
        plantRiskAssessment
        plantRiskAssessmentName
        maintenanceHistory
        maintenanceHistoryName
        plantImage
        attachmentName
        serviceRecords {
          lastServiceMileage
          lastServiceDate
          nextServiceMileage
          nextServiceDate
          createdDate
          serviceRecordImage
          attachmentName
          isDeleted
        }
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        lastInspectionMileage
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchHsePlants = /* GraphQL */ `
  query SearchHsePlants(
    $filter: SearchableHsePlantFilterInput
    $sort: [SearchableHsePlantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHsePlantAggregationInput]
  ) {
    searchHsePlants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customPlantID
        plantRegistration
        plantType
        plantMake
        organisationId
        status
        operatorsManual
        operatorsManualName
        plantRiskAssessment
        plantRiskAssessmentName
        maintenanceHistory
        maintenanceHistoryName
        plantImage
        attachmentName
        serviceRecords {
          lastServiceMileage
          lastServiceDate
          nextServiceMileage
          nextServiceDate
          createdDate
          serviceRecordImage
          attachmentName
          isDeleted
        }
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        lastInspectionMileage
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHseProjectItemDetail = /* GraphQL */ `
  query GetHseProjectItemDetail($id: ID!) {
    getHseProjectItemDetail(id: $id) {
      id
      projectId
      dataType
      dataId
      dataName
      assignedByUserId
      assignedByUserName
      sendAlert
      isDeleted
      createdAt
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      equipment {
        items {
          id
          customEquipmentID
          equipmentType
          equipmentRegistration
          organisationId
          status
          equipmentImage
          attachmentName
          inspectionFrequency
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      plant {
        items {
          id
          customPlantID
          plantRegistration
          plantType
          plantMake
          organisationId
          status
          operatorsManual
          operatorsManualName
          plantRiskAssessment
          plantRiskAssessmentName
          maintenanceHistory
          maintenanceHistoryName
          plantImage
          attachmentName
          serviceRecords {
            lastServiceMileage
            lastServiceDate
            nextServiceMileage
            nextServiceDate
            createdDate
            serviceRecordImage
            attachmentName
            isDeleted
          }
          lastInspectionDate
          inspectedByUserId
          InspectedByUserName
          lastInspectionId
          lastInspectionMileage
          createdAt
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastInspectionRecord {
            items {
              id
              templateId
              templateName
              templateType
              inspectionId
              projectId
              userId
              createdAt
              isDeleted
              project {
                items {
                  id
                  projectName
                  projectNumber
                  projectUrl
                  isArchived
                  State
                  projectInduction
                  kpiData
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              HseInspectionTemplate {
                items {
                  id
                  name
                  type
                  description
                  userId
                  occurrence
                  isDeleted
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseProjectItemDetails = /* GraphQL */ `
  query ListHseProjectItemDetails(
    $filter: ModelHseProjectItemDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseProjectItemDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        dataType
        dataId
        dataName
        assignedByUserId
        assignedByUserName
        sendAlert
        isDeleted
        createdAt
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        equipment {
          items {
            id
            customEquipmentID
            equipmentType
            equipmentRegistration
            organisationId
            status
            equipmentImage
            attachmentName
            inspectionFrequency
            lastInspectionDate
            inspectedByUserId
            InspectedByUserName
            lastInspectionId
            createdAt
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastInspectionRecord {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        plant {
          items {
            id
            customPlantID
            plantRegistration
            plantType
            plantMake
            organisationId
            status
            operatorsManual
            operatorsManualName
            plantRiskAssessment
            plantRiskAssessmentName
            maintenanceHistory
            maintenanceHistoryName
            plantImage
            attachmentName
            serviceRecords {
              lastServiceMileage
              lastServiceDate
              nextServiceMileage
              nextServiceDate
              createdDate
              serviceRecordImage
              attachmentName
              isDeleted
            }
            lastInspectionDate
            inspectedByUserId
            InspectedByUserName
            lastInspectionId
            lastInspectionMileage
            createdAt
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastInspectionRecord {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseProjectItemDetails = /* GraphQL */ `
  query SyncHseProjectItemDetails(
    $filter: ModelHseProjectItemDetailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseProjectItemDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        dataType
        dataId
        dataName
        assignedByUserId
        assignedByUserName
        sendAlert
        isDeleted
        createdAt
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        equipment {
          items {
            id
            customEquipmentID
            equipmentType
            equipmentRegistration
            organisationId
            status
            equipmentImage
            attachmentName
            inspectionFrequency
            lastInspectionDate
            inspectedByUserId
            InspectedByUserName
            lastInspectionId
            createdAt
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastInspectionRecord {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        plant {
          items {
            id
            customPlantID
            plantRegistration
            plantType
            plantMake
            organisationId
            status
            operatorsManual
            operatorsManualName
            plantRiskAssessment
            plantRiskAssessmentName
            maintenanceHistory
            maintenanceHistoryName
            plantImage
            attachmentName
            serviceRecords {
              lastServiceMileage
              lastServiceDate
              nextServiceMileage
              nextServiceDate
              createdDate
              serviceRecordImage
              attachmentName
              isDeleted
            }
            lastInspectionDate
            inspectedByUserId
            InspectedByUserName
            lastInspectionId
            lastInspectionMileage
            createdAt
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastInspectionRecord {
              items {
                id
                templateId
                templateName
                templateType
                inspectionId
                projectId
                userId
                createdAt
                isDeleted
                project {
                  items {
                    id
                    projectName
                    projectNumber
                    projectUrl
                    isArchived
                    State
                    projectInduction
                    kpiData
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                HseInspectionTemplate {
                  items {
                    id
                    name
                    type
                    description
                    userId
                    occurrence
                    isDeleted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseEquipment = /* GraphQL */ `
  query GetHseEquipment($id: ID!) {
    getHseEquipment(id: $id) {
      id
      customEquipmentID
      equipmentType
      equipmentRegistration
      organisationId
      status
      equipmentImage
      attachmentName
      inspectionFrequency
      lastInspectionDate
      inspectedByUserId
      InspectedByUserName
      lastInspectionId
      createdAt
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      lastInspectionRecord {
        items {
          id
          templateId
          templateName
          templateType
          inspectionId
          projectId
          userId
          createdAt
          isDeleted
          project {
            items {
              id
              projectName
              projectNumber
              projectUrl
              isArchived
              State
              projectInduction
              kpiData
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          HseInspectionTemplate {
            items {
              id
              name
              type
              description
              userId
              occurrence
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseEquipments = /* GraphQL */ `
  query ListHseEquipments(
    $filter: ModelHseEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customEquipmentID
        equipmentType
        equipmentRegistration
        organisationId
        status
        equipmentImage
        attachmentName
        inspectionFrequency
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseEquipments = /* GraphQL */ `
  query SyncHseEquipments(
    $filter: ModelHseEquipmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseEquipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customEquipmentID
        equipmentType
        equipmentRegistration
        organisationId
        status
        equipmentImage
        attachmentName
        inspectionFrequency
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchHseEquipments = /* GraphQL */ `
  query SearchHseEquipments(
    $filter: SearchableHseEquipmentFilterInput
    $sort: [SearchableHseEquipmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHseEquipmentAggregationInput]
  ) {
    searchHseEquipments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customEquipmentID
        equipmentType
        equipmentRegistration
        organisationId
        status
        equipmentImage
        attachmentName
        inspectionFrequency
        lastInspectionDate
        inspectedByUserId
        InspectedByUserName
        lastInspectionId
        createdAt
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        lastInspectionRecord {
          items {
            id
            templateId
            templateName
            templateType
            inspectionId
            projectId
            userId
            createdAt
            isDeleted
            project {
              items {
                id
                projectName
                projectNumber
                projectUrl
                isArchived
                State
                projectInduction
                kpiData
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            HseInspectionTemplate {
              items {
                id
                name
                type
                description
                userId
                occurrence
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHseSwms = /* GraphQL */ `
  query GetHseSwms($id: ID!) {
    getHseSwms(id: $id) {
      id
      organisationId
      customSwmsId
      title
      revisionNumber
      revisionDate
      description
      highRiskWork
      swmsTemplateName
      swmsTemplate
      assessmentOutcome
      activityAssessed {
        contractorDetailsIncluded
        titleRecorded
        siteSpecific
        principalContractorIdentified
        compliancePersonIdentified
        highRiskWorkIdentified
        activityBreakdown
        hazardIdentified
        controlMeasureForHazard
        matchScopeOfWork
        environmentConsidered
        hazardAssessed
        ppeRequirementIdentified
        plantEquipmentRequirementDescribed
        competenciesIdentified
        permitsRequired
        specificSwmsIdentified
        crewSignatureSpaceIdentified
        mobilePlantRiskAssociated
        controlMeasuresIdentified
        legislativeReferenceIncluded
        riskAssessmentScoreIncluded
      }
      contractorScope {
        engagedBy
        hasClearUnderstanding
        clarificationNeeded
      }
      approvers
      assessedBy
      assessedByName
      createdBy
      createdByName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseSwms = /* GraphQL */ `
  query ListHseSwms(
    $filter: ModelHseSwmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseSwms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationId
        customSwmsId
        title
        revisionNumber
        revisionDate
        description
        highRiskWork
        swmsTemplateName
        swmsTemplate
        assessmentOutcome
        activityAssessed {
          contractorDetailsIncluded
          titleRecorded
          siteSpecific
          principalContractorIdentified
          compliancePersonIdentified
          highRiskWorkIdentified
          activityBreakdown
          hazardIdentified
          controlMeasureForHazard
          matchScopeOfWork
          environmentConsidered
          hazardAssessed
          ppeRequirementIdentified
          plantEquipmentRequirementDescribed
          competenciesIdentified
          permitsRequired
          specificSwmsIdentified
          crewSignatureSpaceIdentified
          mobilePlantRiskAssociated
          controlMeasuresIdentified
          legislativeReferenceIncluded
          riskAssessmentScoreIncluded
        }
        contractorScope {
          engagedBy
          hasClearUnderstanding
          clarificationNeeded
        }
        approvers
        assessedBy
        assessedByName
        createdBy
        createdByName
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseSwms = /* GraphQL */ `
  query SyncHseSwms(
    $filter: ModelHseSwmsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseSwms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organisationId
        customSwmsId
        title
        revisionNumber
        revisionDate
        description
        highRiskWork
        swmsTemplateName
        swmsTemplate
        assessmentOutcome
        activityAssessed {
          contractorDetailsIncluded
          titleRecorded
          siteSpecific
          principalContractorIdentified
          compliancePersonIdentified
          highRiskWorkIdentified
          activityBreakdown
          hazardIdentified
          controlMeasureForHazard
          matchScopeOfWork
          environmentConsidered
          hazardAssessed
          ppeRequirementIdentified
          plantEquipmentRequirementDescribed
          competenciesIdentified
          permitsRequired
          specificSwmsIdentified
          crewSignatureSpaceIdentified
          mobilePlantRiskAssociated
          controlMeasuresIdentified
          legislativeReferenceIncluded
          riskAssessmentScoreIncluded
        }
        contractorScope {
          engagedBy
          hasClearUnderstanding
          clarificationNeeded
        }
        approvers
        assessedBy
        assessedByName
        createdBy
        createdByName
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchHseSwms = /* GraphQL */ `
  query SearchHseSwms(
    $filter: SearchableHseSwmsFilterInput
    $sort: [SearchableHseSwmsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHseSwmsAggregationInput]
  ) {
    searchHseSwms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organisationId
        customSwmsId
        title
        revisionNumber
        revisionDate
        description
        highRiskWork
        swmsTemplateName
        swmsTemplate
        assessmentOutcome
        activityAssessed {
          contractorDetailsIncluded
          titleRecorded
          siteSpecific
          principalContractorIdentified
          compliancePersonIdentified
          highRiskWorkIdentified
          activityBreakdown
          hazardIdentified
          controlMeasureForHazard
          matchScopeOfWork
          environmentConsidered
          hazardAssessed
          ppeRequirementIdentified
          plantEquipmentRequirementDescribed
          competenciesIdentified
          permitsRequired
          specificSwmsIdentified
          crewSignatureSpaceIdentified
          mobilePlantRiskAssociated
          controlMeasuresIdentified
          legislativeReferenceIncluded
          riskAssessmentScoreIncluded
        }
        contractorScope {
          engagedBy
          hasClearUnderstanding
          clarificationNeeded
        }
        approvers
        assessedBy
        assessedByName
        createdBy
        createdByName
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHseSwmsAction = /* GraphQL */ `
  query GetHseSwmsAction($id: ID!) {
    getHseSwmsAction(id: $id) {
      id
      swmId
      organisationId
      actionSummary
      responsiblePerson
      dueDate
      actionStatus
      completedBy
      completedByName
      completedDate
      reportedBy
      reportedByName
      reportedDate
      verifiers
      verifiedBy
      verifiedByName
      verifiedDate
      comments
      isDeleted
      hseSwms {
        items {
          id
          organisationId
          customSwmsId
          title
          revisionNumber
          revisionDate
          description
          highRiskWork
          swmsTemplateName
          swmsTemplate
          assessmentOutcome
          activityAssessed {
            contractorDetailsIncluded
            titleRecorded
            siteSpecific
            principalContractorIdentified
            compliancePersonIdentified
            highRiskWorkIdentified
            activityBreakdown
            hazardIdentified
            controlMeasureForHazard
            matchScopeOfWork
            environmentConsidered
            hazardAssessed
            ppeRequirementIdentified
            plantEquipmentRequirementDescribed
            competenciesIdentified
            permitsRequired
            specificSwmsIdentified
            crewSignatureSpaceIdentified
            mobilePlantRiskAssociated
            controlMeasuresIdentified
            legislativeReferenceIncluded
            riskAssessmentScoreIncluded
          }
          contractorScope {
            engagedBy
            hasClearUnderstanding
            clarificationNeeded
          }
          approvers
          assessedBy
          assessedByName
          createdBy
          createdByName
          isDeleted
          organisation {
            items {
              id
              name
              organizationInduction
              isDeleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseSwmsActions = /* GraphQL */ `
  query ListHseSwmsActions(
    $filter: ModelHseSwmsActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseSwmsActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        swmId
        organisationId
        actionSummary
        responsiblePerson
        dueDate
        actionStatus
        completedBy
        completedByName
        completedDate
        reportedBy
        reportedByName
        reportedDate
        verifiers
        verifiedBy
        verifiedByName
        verifiedDate
        comments
        isDeleted
        hseSwms {
          items {
            id
            organisationId
            customSwmsId
            title
            revisionNumber
            revisionDate
            description
            highRiskWork
            swmsTemplateName
            swmsTemplate
            assessmentOutcome
            activityAssessed {
              contractorDetailsIncluded
              titleRecorded
              siteSpecific
              principalContractorIdentified
              compliancePersonIdentified
              highRiskWorkIdentified
              activityBreakdown
              hazardIdentified
              controlMeasureForHazard
              matchScopeOfWork
              environmentConsidered
              hazardAssessed
              ppeRequirementIdentified
              plantEquipmentRequirementDescribed
              competenciesIdentified
              permitsRequired
              specificSwmsIdentified
              crewSignatureSpaceIdentified
              mobilePlantRiskAssociated
              controlMeasuresIdentified
              legislativeReferenceIncluded
              riskAssessmentScoreIncluded
            }
            contractorScope {
              engagedBy
              hasClearUnderstanding
              clarificationNeeded
            }
            approvers
            assessedBy
            assessedByName
            createdBy
            createdByName
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseSwmsActions = /* GraphQL */ `
  query SyncHseSwmsActions(
    $filter: ModelHseSwmsActionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseSwmsActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        swmId
        organisationId
        actionSummary
        responsiblePerson
        dueDate
        actionStatus
        completedBy
        completedByName
        completedDate
        reportedBy
        reportedByName
        reportedDate
        verifiers
        verifiedBy
        verifiedByName
        verifiedDate
        comments
        isDeleted
        hseSwms {
          items {
            id
            organisationId
            customSwmsId
            title
            revisionNumber
            revisionDate
            description
            highRiskWork
            swmsTemplateName
            swmsTemplate
            assessmentOutcome
            activityAssessed {
              contractorDetailsIncluded
              titleRecorded
              siteSpecific
              principalContractorIdentified
              compliancePersonIdentified
              highRiskWorkIdentified
              activityBreakdown
              hazardIdentified
              controlMeasureForHazard
              matchScopeOfWork
              environmentConsidered
              hazardAssessed
              ppeRequirementIdentified
              plantEquipmentRequirementDescribed
              competenciesIdentified
              permitsRequired
              specificSwmsIdentified
              crewSignatureSpaceIdentified
              mobilePlantRiskAssociated
              controlMeasuresIdentified
              legislativeReferenceIncluded
              riskAssessmentScoreIncluded
            }
            contractorScope {
              engagedBy
              hasClearUnderstanding
              clarificationNeeded
            }
            approvers
            assessedBy
            assessedByName
            createdBy
            createdByName
            isDeleted
            organisation {
              items {
                id
                name
                organizationInduction
                isDeleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseSwmsWorkflow = /* GraphQL */ `
  query GetHseSwmsWorkflow($id: ID!) {
    getHseSwmsWorkflow(id: $id) {
      id
      organisationId
      verifierId
      verifierName
      approverId
      approverName
      actioneeId
      actioneeName
      isDeleted
      organisation {
        items {
          id
          name
          organizationInduction
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseSwmsWorkflows = /* GraphQL */ `
  query ListHseSwmsWorkflows(
    $filter: ModelHseSwmsWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseSwmsWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationId
        verifierId
        verifierName
        approverId
        approverName
        actioneeId
        actioneeName
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseSwmsWorkflows = /* GraphQL */ `
  query SyncHseSwmsWorkflows(
    $filter: ModelHseSwmsWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseSwmsWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organisationId
        verifierId
        verifierName
        approverId
        approverName
        actioneeId
        actioneeName
        isDeleted
        organisation {
          items {
            id
            name
            organizationInduction
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHseReportConfiguration = /* GraphQL */ `
  query GetHseReportConfiguration($id: ID!) {
    getHseReportConfiguration(id: $id) {
      id
      projectId
      reportName
      certificateId
      certificateName
      certificateDateType
      licenseId
      licenseName
      licenseDateType
      otherRecordName
      otherRecordDateType
      qualificationName
      qualificationDateType
      inductionId
      inductionName
      inductionDateType
      isDeleted
      project {
        items {
          id
          projectName
          projectNumber
          projectUrl
          isArchived
          State
          projectInduction
          kpiData
          isDeleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHseReportConfigurations = /* GraphQL */ `
  query ListHseReportConfigurations(
    $filter: ModelHseReportConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHseReportConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        reportName
        certificateId
        certificateName
        certificateDateType
        licenseId
        licenseName
        licenseDateType
        otherRecordName
        otherRecordDateType
        qualificationName
        qualificationDateType
        inductionId
        inductionName
        inductionDateType
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHseReportConfigurations = /* GraphQL */ `
  query SyncHseReportConfigurations(
    $filter: ModelHseReportConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHseReportConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        reportName
        certificateId
        certificateName
        certificateDateType
        licenseId
        licenseName
        licenseDateType
        otherRecordName
        otherRecordDateType
        qualificationName
        qualificationDateType
        inductionId
        inductionName
        inductionDateType
        isDeleted
        project {
          items {
            id
            projectName
            projectNumber
            projectUrl
            isArchived
            State
            projectInduction
            kpiData
            isDeleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
