import { DataStore, API } from 'aws-amplify'
import {SortDirection}  from "aws-amplify";
import { HseProjectSignOnOff } from './../models'
import { getAWSLocalDateTimeInISOStringFormat } from './HseCommonService'
import {
  updateHseProjectSignOnOff
} from './../graphql/mutations'

import * as Constants from './../constants/index'

export async function CreateHseUserProjectSingOnOffDetails (projectId, userId, username, singedInByUserId, signedInByUserName) {
    let hseSignOnOffDetails = {
      projectId: projectId, 
      dataType: Constants.PROJECT_SIN_ON_OFF_DATA_TYPE.USER,
      dataId: userId, 
      name: username, 
      signOnDateTimeAt: getAWSLocalDateTimeInISOStringFormat(new Date()),
      signOffDateTimeAt: null,
      signOnByUserId: singedInByUserId,
      signOnByUserName: signedInByUserName,
      signOffByUserId: "",
      signOffByUserName: "",
      isDeleted: false
    }

    try {
      let result = await DataStore.save(
        new HseProjectSignOnOff({ ...hseSignOnOffDetails })
      )
  
      // console.log(result)
      return true
    } catch (err) {
      console.log('error creating HSE User Sign On / Off Details:', err)
    }
    return false
  }

  export async function UpdateHseSignOff (updateObject) {
    try {
      let toUpdate = {
            ...updateObject
      }
      const updatedHseProjectSignOnOffData = await API.graphql({ query: updateHseProjectSignOnOff, variables: {input: toUpdate}});
      return updatedHseProjectSignOnOffData;
    } catch (err) {
        console.log('error updating User UpdateHseSignOff:', err)
    }
  }

  export async function CreateHsePlantProjectSingOnOffDetails (projectId, plantId, plantName, singedInByUserId, signedInByUserName) {
    let hseSignOnOffDetails = {
      projectId: projectId, 
      dataType: Constants.PROJECT_SIN_ON_OFF_DATA_TYPE.PLANT,
      dataId: plantId, 
      name: plantName, 
      signOnDateTimeAt: getAWSLocalDateTimeInISOStringFormat(new Date()),
      signOffDateTimeAt: null,
      signOnByUserId: singedInByUserId,
      signOnByUserName: signedInByUserName,
      signOffByUserId: "",
      signOffByUserName: "",
      isDeleted: false,
      SignedOnInToProjectId: ""
    }

    try {
      let result = await DataStore.save(
        new HseProjectSignOnOff({ ...hseSignOnOffDetails })
      )
  
      // console.log(result)
      return true
    } catch (err) {
      console.log('error creating HSE Plant Sign On / Off Details:', err)
    }
    return false
  }

export async function GetHseProjectSingOnOffDetails (dataType, projectId, dataId) {
  let formattedData = null
  try {
    let result = await DataStore.query(
      HseProjectSignOnOff,
      c =>
        c.dataType('eq', dataType) &&
        c.dataId('eq', dataId) &&
        c.projectId('eq', projectId) &&
        c.isDeleted('eq', false) &&
        c.signOffDateTimeAt('eq', null) ,
        {  sort: s => s.signOnDateTimeAt(SortDirection.DESCENDING)}
    )

    // console.log(result)

    if (result !== undefined) {
      formattedData = result 
      // console.log('GetHseUserProjectSingOnOffDetails',formattedData);
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing HSE User Project Sign On off details:', err)
  }
  return formattedData
}

export async function GetHseProjectItemNotSignedOffDetails (dataType, projectId, dataId) {
  let formattedData = null
  try {
    let result = await DataStore.query(
      HseProjectSignOnOff,
      c =>
        c.dataType('eq', dataType) &&
        c.dataId('eq', dataId) &&
        c.projectId('ne', projectId) &&
        c.isDeleted('eq', false) &&
        c.signOffDateTimeAt('eq', null) ,
        {  sort: s => s.signOnDateTimeAt(SortDirection.DESCENDING)}
    )

    // console.log(result)

    if (result !== undefined) {
      formattedData = result 
      // console.log('GetHseUserProjectSingOnOffDetails',formattedData);
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing HSE User Project Item Not signed off details:', err)
  }
  return formattedData
}


export async function GetHsePlantProjectSingOnOffDetails (projectId, plantId) {
  let formattedData = null

  try {
    let result = await DataStore.query(
      HseProjectSignOnOff,
      c =>
        c.dataType('eq', Constants.PROJECT_SIN_ON_OFF_DATA_TYPE.PLANT) &&
        c.dataId('eq', plantId) &&
        c.projectId('eq', projectId) &&
        c.isDeleted('eq', false) &&
        c.signOffDateTimeAt('eq', null) ,
        {  sort: s => s.signOnDateTimeAt(SortDirection.DESCENDING)}
    )

    //console.log(result)

    if (result !== undefined) {
      formattedData = result 
      // console.log('GetHsePlantProjectSingOnOffDetails',formattedData);
    } else {
      return null
    }
  } catch (err) {
    console.log('error Listing HSE Plant Project Sign On off details:', err)
  }
  return formattedData
}
