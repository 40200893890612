import {API, Storage } from 'aws-amplify'
import moment from 'moment'
import Resizer from "react-image-file-resizer";
import { GetInductionByIDForOnlineUse } from './HseInductionService';

export async function FetchDataWithNextToken(
  queryName, 
  filterObj, 
  listObjName,
  extraVariables = {}
  ) {
  let finalResult = []
  let finalToken = null

  while(true) {
    let result = await API.graphql({
      query: queryName,
      variables: { filter: filterObj, nextToken : finalToken, limit: 20000, ...extraVariables }
    })

    // console.log(result)
    if(result && result.data && result.data[listObjName] && result.data[listObjName].nextToken ) {
      finalResult = finalResult.concat(result.data[listObjName].items) 
      finalToken = result.data[listObjName].nextToken
    } else {      
      finalResult = finalResult.concat(result.data[listObjName].items) 
      break
    }
  }

  return finalResult
}

export async function GenerateAuthenticatedLink (imagePath, s3Folder) {
  return await new Promise(resolve => {
    let key =
      imagePath && imagePath.length > 0
        ? imagePath.substring(imagePath.indexOf(s3Folder))
        : ''
    const result = Storage.get(key)
    resolve(result)
  })
}

export function GetDisplayName (userId, projectUserList) {
  let tempArr = userId?.split(',')
  let userName = []
  tempArr?.forEach(element => {
    let result = projectUserList?.filter(item => item.value === element?.trim());
    userName.push(result && result.length && result[0].label || "")  
  });
  return userName.toString()
}

export const resizeFile = async (file) => {
  let quality = 100;

  if(file.size <= 380000) {
    quality = 95;
  }
  else if (file.size > 380000 &&  file.size <= 1000000) {
    quality = 91;
  }
  else if (file.size > 1000000 &&  file.size <= 2000000) {
    quality = 85;
  }
  else if (file.size > 2000000 &&  file.size <= 4000000) {
    quality = 75;
  } else {
    quality = 70;
  }

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      800,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  }).catch((err) => {
    return err
  });
}
  
export const listFilter = (list, field) => {
  var objPosition = {}, ttl = field;
  return list.map(i => {
         return { ...objPosition, [ttl]: { 'eq': i}
        }
 })
};

export const getCurrentLocation = () => {
  if(navigator.geolocation) {
    return captureCurrentLocation();
  } else {
    return {
      isCurrentLocationCaptured: false,
      errmessage: 'Geo Location not supported by browser !'
    }
  }
};

function captureCurrentLocation() {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var locationObj = {
        isCurrentLocationCaptured: true,
        longitude: position.coords.longitude,
        latitude: position.coords.latitude
      }
      resolve(locationObj);
    },
    function (error) {
      let locationObj = {
        isCurrentLocationCaptured: false
      };
      let locationErrObj = {
        1: 'PERMISSION_DENIED: User denied the request for Geolocation !',
        2: 'POSITION_UNAVAILABLE: Location information is unavailable !',
        3: 'TIMEOUT: The request to get user location timed out !'
      };
      locationObj['errmessage'] = locationErrObj[error.code] ? locationErrObj[error.code] : 'UNKNOWN_ERROR: An unknown error occurred !';
      reject(locationObj)
    },{
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });
  });
}

export const isToday = (inputDate) => {
  // 0 = today, value < 0 = date is future date, value > 0  date is past date
  // const formatedInputDate = moment(inputDate).parseZone().format("MM-DD-YYYY");
  const formatedInputDate = moment(inputDate).parseZone().format("YYYY/MM/DD");
  return new Date().setHours(0, 0, 0, 0) - new Date(formatedInputDate).setHours(0,0,0,0) 
};

const millisToMinutesAndSeconds = (millis) => {
  return Math.round(Math.floor(millis/3600000))
};

export const calculatedFatigueScore = (sleepPriorWork24, sleepPrior24, awakeHours) => {
  let getSleepPriorWorkIn24 = Number(sleepPriorWork24);
  let getSleepPrior24 = Number(sleepPrior24);
  let sleepIn48 = Number(getSleepPriorWorkIn24 + getSleepPrior24);
  let getAwakeHours = millisToMinutesAndSeconds(new Date().getTime() - new Date(awakeHours).getTime());
  var points = 0
  if (getSleepPriorWorkIn24 < 5) {
    points += 4 * (5 - getSleepPriorWorkIn24)
  }
  if (sleepIn48 < 12) {
    points += 2 * (12 - sleepIn48)
  }
  if(getAwakeHours > sleepIn48){
    points += getAwakeHours - sleepIn48
  }
  return points < 0 ? 0 : points;
};

async function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const generateUniqueId = async (prefixText) => {
  let uniqueId = prefixText+Date.now().toString() + await getRandomInt(10,99)
  return uniqueId;
}

export const getExpiryDate = async (courseValidity) => {
  const d = new Date();
  let expiryDate = d.setDate(d.getDate() + parseInt(courseValidity));
  return getAWSLocalDateInISOStringFormat(expiryDate)
}


export const getCompletionDeadline = async (inductionId) => {
  let inductionDetail = await GetInductionByIDForOnlineUse(inductionId)
  const d = new Date();
  let completionDeadline = d.setDate(d.getDate() + parseInt(inductionDetail[0].completionDeadline));
  return ({
    completionDeadline: getAWSLocalDateInISOStringFormat(completionDeadline),
  })
}

/* Methods to display date in UI  */

export function getLocaleDateString(tDate) {
  if(tDate) {
    return moment(tDate).parseZone().format("DD-MM-YYYY")
  } 
  return ''
}

export function getLocaleDateTimeString(tDate) {
  if(tDate) {
    return moment(tDate).parseZone().format('DD-MM-yyyy hh:mm:ss A')
  } 
  return ''
}

/* Methods to save date in DB  */

export function getAWSLocalDateInISOStringFormat(tDate) {
  if(tDate){
    return moment(tDate).parseZone().format("YYYY-MM-DD")
  }
  return ''
}

export function getAWSLocalDateTimeInISOStringFormat(tDate) {
  if(tDate){
    return moment(tDate).parseZone().format('YYYY-MM-DDTHH:mm:ss[Z]')
  }
  return ''
}

// new methods to convert date to local time zone
export function getFromUtcToLocaleDateString(tDate) {
  if(tDate) {
    return moment(tDate).local().format("DD-MM-YYYY")
  } 
  return ''
}

export function getFromUtcToLocaleDateTimeString(tDate) {
  if(tDate) {
    return moment(tDate).local().format('DD-MM-yyyy hh:mm:ss A')
  } 
  return ''
}


export function formatDateYYYYMMDD(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


export function formatDateDDMMYYYY(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}