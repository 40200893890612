import { API, graphqlOperation, DataStore} from 'aws-amplify'
import { getHsePlant, listHsePlants } from './../graphql/queries'
import { GetHseOrganisationById } from './OrganisationService'
import { HsePlant, HseProjectItemDetail } from './../models'
import * as Constants from './../constants'
import { FetchDataWithNextToken } from './HseCommonService'
import { updateHsePlant } from '../graphql/mutations'


export async function ListAllPlants (isDataStoreOnline = false) {
  const filter = {
    isDeleted : {eq: false}
  };

    try {    
      let result =(isDataStoreOnline) ?  await DataStore.query(HsePlant, c => c.isDeleted('eq', false)): (await FetchDataWithNextToken(listHsePlants, filter, 'listHsePlants'));

      return result
    } catch (err) {
      console.log('error Listing all HsePlants:', err)
    }
}

export async function getPlantListForDropDown(isDataStoreOnline = false) {
    let result = await ListAllPlants(isDataStoreOnline)
    let allPlants = []

    if (result !== undefined ) {
        allPlants = result.map(item => {
        return {
          label: (item['plantRegistration'] || '') + ' - ' + item['plantType'],
          value: item['id'],           
        }
      })   
    }

    // console.log(allPlants)
    return allPlants
}

export async function getPlantTypeListForDropDown() {
  let result = await ListAllPlants();
  let allPlants = [];

  if (result !== undefined) {
    allPlants = result.map((item) => {
      return {
        label: item["plantType"],
        value: item["id"],
      };
    });
  }
  const key = "label";
  const arrayUniqueByLabel = [
    ...new Map(allPlants.map((item) => [item[key], item])).values(),
  ];

  return arrayUniqueByLabel;
}

export async function GetHsePlantById(plantId, isDataStoreOnline=false) {
    let formattedData = null;
    const filter = {
      id : {eq: plantId},
      isDeleted : {eq: false}
    };
    try {
      let result = null

      if(plantId) {
        
         result =(isDataStoreOnline) ?  await DataStore.query(
          HsePlant,
        c => c.id('eq', plantId) && c.isDeleted('eq', false)
        ): (await FetchDataWithNextToken(listHsePlants, filter, 'listHsePlants'));

      } 
      
      if (result !== undefined) {        
        formattedData = result;
      } else {
        return null;
      }
    } catch (err) {
      console.log("error Getting HSE Plant:", err);
    }
    return formattedData;
}

export async function GetHsePlantWithAdditionalDetailsById(plantId, isDataStoreOnline = false) {
  let formattedData = null;
  const filter = {
    id : {eq: plantId},
    isDeleted : {eq: false}
  }
  try {
    let result = null

    if(plantId) {
     result =(isDataStoreOnline) ?  await DataStore.query(
      HsePlant,
    c => c.id('eq', plantId) && c.isDeleted('eq', false)
  ): (await FetchDataWithNextToken(listHsePlants, filter, 'listHsePlants'));

    } 
    
    if (result !== undefined) {        
      formattedData = await GetFormattedPlantDetailsList(result, isDataStoreOnline);
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HSE Plant:", err);
  }
  return formattedData;
}

async function GetFormattedPlantDetailsList(data, isDataStoreOnline = false) {
  // console.log(data)
  if (data !== undefined) {
    let formattedData = await Promise.all(
      data.map(async item => {
        let templateObj = await GetHseOrganisationById(item.organisationId, isDataStoreOnline)
        
        if(templateObj != null && templateObj != undefined && templateObj.length) {
          return {
            ...item,
            organisationName: templateObj[0].name
          }
        }
      })
    ).then(values => {
      // console.log(values)
      return values
    })

    // console.log(formattedData)

    return formattedData
  }
  return []
}

export async function GetHsePlantByCustomId(plantId) {
  let formattedData = null;
  try {
    let result = null

    if(plantId) {
      result = await DataStore.query(
          HsePlant,
        c => c.customPlantID('eq', plantId) && c.isDeleted('eq', false)
      )
    } 
    
    if (result && result.length) {        
      formattedData = result[0];
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HSE Plant by customId:", err);
  }
  return formattedData;
}

export async function GetHsePlantByRegistration(plantId) {
  let formattedData = null;
  try {
    let result = null

    if(plantId) {
      result = await DataStore.query(
          HsePlant,
        c => c.plantRegistration('eq', plantId) && c.isDeleted('eq', false)
      )
    } 
    
    if (result && result.length) {        
      formattedData = result[0];
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HSE Plant by customId:", err);
  }
  return formattedData;
}


export async function GetHsePlantsByProjectId(projectId) {
  let formattedData = null;
  try {
    let result = null

    if(projectId) {
      result = await DataStore.query(
        HseProjectItemDetail,
        c => c.projectId('eq', projectId) && c.dataType('eq',Constants.PROJECT_ITEM_TYPES.PLANT) && c.isDeleted('eq', false)
      )
    } 
    
    if (result && result.length) {        
      formattedData = result;
      console.log(result)
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HSE Plant by projectId:", err);
  }
  return formattedData;
}


export async function getProjectPlantListForDropDown(projectId) {
  let result = await GetHsePlantsByProjectId(projectId)
  let allPlants = []

  if (result !== undefined ) {
      allPlants = result?.map(item => {
      return {
        label: item['dataName'] ,
        value: item['dataId'],    
        version : item['_version']       
      }
    })   
  }

  // console.log(allPlants)
  return allPlants
}

export const updatePlantInspectionDetails = async (data, isThereNewServiceRecordForAsset, plantNewServiceRecord, isDataStoreOnline=false) => {
  // console.log("data",data)
  try {

    if(isDataStoreOnline) {
      
    const original = await DataStore.query(HsePlant, data?.id);

    let exitingServiceRecords = []
    if(isThereNewServiceRecordForAsset) {
      exitingServiceRecords = original.serviceRecords?.length ? [...original.serviceRecords] : []
      exitingServiceRecords = [...exitingServiceRecords, plantNewServiceRecord]
    }

    const result = await DataStore.save(
      HsePlant.copyOf(original, updated => {
        updated.lastInspectionDate = data.lastInspectionDate
        updated.inspectedByUserId = data.inspectedByUserId
        updated.InspectedByUserName = data.InspectedByUserName 
        updated.lastInspectionId = data.lastInspectionId
        updated.lastInspectionMileage = data.lastInspectionMileage
        updated.serviceRecords = exitingServiceRecords
      })
    )
    return result;
    } else {
      GetHsePlantById(data?.id,isDataStoreOnline).then(async (response) => {
        if(response && isThereNewServiceRecordForAsset) {
          let exitingServiceRecords = []
          if(isThereNewServiceRecordForAsset) {
            exitingServiceRecords = response.serviceRecords?.length ? [...response.serviceRecords] : []
            data.serviceRecords = [...exitingServiceRecords, plantNewServiceRecord]
          }
        }
        let result = await API.graphql({ query: updateHsePlant, variables: { input: {...data , _version:response && response.length ? response[0]?._version : '' }} })
        if(result && result.data && result.data.updateHsePlant) {
          return result.data.updateHsePlant;
        }
        return null;
      });
      
    }

  } catch (err) {
    const { errors } = err;
    throw errors || 'Something went wrong';
  }
};


export async function GetHsePlantGraphql(plantId) {
  try {
    let result = await API.graphql({
      query: getHsePlant,
      variables: { id: plantId },
    });
    if(result && result.data && result.data.getHsePlant){
      return result.data.getHsePlant
    }else{
      return null
    }
  } catch (err) {
    console.log(`error Getting HSE Plants:`, err);
  }
}

export async function CreateHsePlantRecord (data) {
  try {
    let result = await DataStore.save(new HsePlant({ ...data }));
    return result;
  } catch (err) {
    console.log('error creating  HsePlant:', err);
  }
  return false;
};