import * as types from '../../constants'
import { GetCurrentUserInfo, GetUser } from '../../services/UserService'
import { set, get } from 'idb-keyval'
import { GetHseUserRolesByRoleId } from '../../services/UserService'

export function setCurrentUserDetails (online) {
  return async dispatch => {
    if (online) {
      return GetCurrentUserInfo(online).then(async (response) => {
        if (response !== undefined) {
          let result = await GetUser(response.username)
          if(result && result['custom:organisationId'] && result['custom:organisationId']) {
            let organisationDetail = {
              id: result['custom:organisationId'] || "",
              name: result['custom:organisationName'] || ""
            }
            response.attributes['organisationDetail'] = organisationDetail
          }
          if(result && result['custom:Roles']) {
            let roleId = result['custom:Roles']
            let getUserRoleData = await GetHseUserRolesByRoleId(roleId);
            response.attributes['roleDetail'] = getUserRoleData && getUserRoleData?.length ? getUserRoleData[0] : [];
          }else {
            response.attributes['roleDetail'] = {};
          }

          set('userDetails', JSON.stringify(response.attributes))
        }
        // console.log('setting user in idb', response)
        // console.log(response.attributes);
        // localStorage.setItem('signedInUser', JSON.stringify(response.attributes) )
        dispatch({
          type: types.SET_USER_DETAILS,
          payload: response.attributes
        })
      })
    } else {
      get('userDetails').then(result => {
        //  console.log('got user from idb', result)

        return dispatch({
          type: types.SET_USER_DETAILS,
          payload: JSON.parse(result)
        })
      })
    }
  }
}

export function setNetworkOnlineStatus(status) {
    return async dispatch => {
        return dispatch({
            type: types.SET_NETWORK_ONLINE,
            payload: {networkOnline: status}
          })
    }
}

export function setUserAuthGroups(groups) {
    return async dispatch => {
        return dispatch({
            type: types.SET_USER_GROUPS,
            payload: {userGroups: groups}
          })
    }
}