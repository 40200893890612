import React, {useState} from "react";
import styled from "styled-components/macro";
import { Power, Database } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch , useSelector} from "react-redux";
import { Auth } from "aws-amplify"
import WifiOffIcon from '@material-ui/icons/WifiOff';
import CropFreeIcon  from '@material-ui/icons/CropFreeRounded';
import QRScanner from '../pages/projects/QRScanner'

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import { signOut } from "../redux/actions/authActions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isDatastoreReady = useSelector((state) => state.dataStoreReducer.isDatastoreReady);
  const networkStatus = useSelector(state => state.sessionData.networkOnline)

  // const toggleMenu = (event) => {
  //   setAnchorMenu(event.currentTarget);
  // };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await Auth.signOut().then(()=>{
      history.push('/')
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      { (!networkStatus) ? (
      <Tooltip title="Offline" >
        <IconButton>
          <WifiOffIcon/>
        </IconButton>        
      </Tooltip>) : ''
      }

      {!isDatastoreReady ? (<>Preparing Offline Data  <Database /> </>): ''}

      {isDatastoreReady ? (
          <Tooltip title="SignIn" >
          <IconButton onClick={() => { setDialogOpen(true)}}>
            <CropFreeIcon/>
          </IconButton>        
        </Tooltip>
      ) : ''}
      
      <QRScanner open={dialogOpen} setOpen={setDialogOpen} />
      <Tooltip title="Sign out">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
          disabled={!networkStatus}
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
