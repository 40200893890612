import { API, graphqlOperation, DataStore} from 'aws-amplify'
import { listHseEquipments, listHseOrganisations } from './../graphql/queries'
import { createHseOrganisation, updateHseEquipment } from './../graphql/mutations'

import { GetHseOrganisationById } from './OrganisationService'
import { HseEquipment, HseProjectItemDetail } from './../models'
import * as Constants from './../constants'
import { FetchDataWithNextToken } from './HseCommonService'

export async function ListAllEquipments (isDataStoreOnline = false) {
  const filter = {
    isDeleted : {eq: false}
  };

    try { 
      
  let result =(isDataStoreOnline) ?  await DataStore.query(HseEquipment, c => c.isDeleted('eq', false)):
   (await FetchDataWithNextToken(listHseEquipments, filter, 'listHseEquipments'));

      return result
    } catch (err) {
      console.log('error Listing all HseEquipment:', err)
    }
}

export async function getEquipmentListForDropDown(isDataStoreOnline = false) {
    let result = await ListAllEquipments(isDataStoreOnline)
    let allEquipments = []

    if (result !== undefined ) {
        allEquipments = result.map(item => {
        return {
          label: (item['equipmentRegistration'] || '') + ' - ' + item['equipmentType'],
          value: item['id'],           
        }
      })   
    }

    // console.log(allPlants)
    return allEquipments
}

export async function GetAllHseEquipment(equipmentId) {
    let formattedData = null;
    try {
      let result = null

      if(equipmentId) {
        result = await DataStore.query(
            HseEquipment,
          c => c.id('eq', equipmentId) && c.isDeleted('eq', false)
        )
      } 
      
      if (result !== undefined) {        
        formattedData = result;
      } else {
        return null;
      }
    } catch (err) {
      console.log("error Getting HseEquipment:", err);
    }
    return formattedData;
}

export async function GetHseEquipmentWithDetailsById(equipmentId , isDataStoreOnline = false) {
  let formattedData = null;
  const filter = {
    id : {eq: equipmentId},
    isDeleted : {eq: false}
  };
  try {
    let result = null

    if(equipmentId) {
       result =(isDataStoreOnline) ?  await DataStore.query(
        HseEquipment,
      c => c.id('eq', equipmentId) && c.isDeleted('eq', false)
    ): (await FetchDataWithNextToken(listHseEquipments, filter, 'listHseEquipments'));

    } 
    if (result !== undefined) {        
      formattedData = GetFormattedEquipmentDetailsList(result,isDataStoreOnline);
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HseEquipment:", err);
  }
  return formattedData;
}

async function GetFormattedEquipmentDetailsList(data, isDataStoreOnline = false) {
  // console.log(data)
  if (data !== undefined) {
    let formattedData = await Promise.all(
      data.map(async item => {
        let templateObj = await GetHseOrganisationById(item.organisationId, isDataStoreOnline)
        
        if(templateObj != null && templateObj != undefined && templateObj.length) {
          return {
            ...item,
            organisationName: templateObj[0].name
          }
        }
      })
    ).then(values => {
      // console.log(values)
      return values
    })

    // console.log(formattedData)

    return formattedData
  }
  return []
}


export async function GetHseEquipmentByProjectId(projectId) {
  let formattedData = null;
  try {
    let result = null

    if(projectId) {
      result = await DataStore.query(
        HseProjectItemDetail,
        c => c.projectId('eq', projectId) && c.dataType('eq',Constants.PROJECT_ITEM_TYPES.EQUIPMENT) && c.isDeleted('eq', false)
      )
    } 
    
    if (result && result.length) {        
      formattedData = result;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error Getting HSE Plant by projectId:", err);
  }
  return formattedData;
}




export async function getProjectEquipmentListForDropDown(projectId) {
  let result = await GetHseEquipmentByProjectId(projectId)
  let allPlants = []

  if (result !== undefined ) {
      allPlants = result?.map(item => {
      return {
        label: item['dataName'] ,
        value: item['dataId'],           
      }
    })   
  }

  // console.log(allPlants)
  return allPlants
}

export const updateHseEquipmentInspectionDetails = async (data, isDataStoreOnline = false) => {
  try {

    if(isDataStoreOnline) {
      const original = await DataStore.query(HseEquipment, data?.id);
      const result = await DataStore.save(
        HseEquipment.copyOf(original, updated => {
          updated.lastInspectionDate = data.lastInspectionDate
          updated.inspectedByUserId = data.inspectedByUserId
          updated.InspectedByUserName = data.InspectedByUserName 
          updated.lastInspectionId = data.lastInspectionId
        })
      )
      return result;
    } else {
      GetHseEquipmentWithDetailsById(data?.id,isDataStoreOnline).then(async (response) => {
        let result = await API.graphql({ query: updateHseEquipment, variables: { input: {...data , _version:response && response.length ? response[0]?._version : '' }} })
        if(result && result.data && result.data.updateHseEquipment) {
          return result.data.updateHseEquipment;
        }
        return null;
      });
      
    }
  } catch (err) {
    const { errors } = err;
    throw errors || 'Something went wrong';
  }
};